import React from 'react';
import LoginNavBar from '../../components/loginNavBar/LoginNavBar';
import { Link } from 'react-router-dom';
import './Error.Module.css';
import corazon from '../../assets/CORAZON_TRISTE.png';

function Error() {
	return (
		<div className='fondoError'>
			<header>
				<LoginNavBar />
			</header>
			<main className='containerError '>
				<div className='containerCorazon mt-5'>
					<img src={corazon} alt='corazon triste' />
				</div>
				<h2>Ups, página no encontrada!</h2>
				<Link to='/' className='link'>
					<h4>Volver al inicio</h4>
				</Link>
			</main>
		</div>
	);
}

export default Error;
