import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { isAuthenticated } from '../../utils/usuario/usuario';

// import './DialogSellos.Module.css';
// import imagen from "../../assets/imgCarousel/img1.jpg"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

export default function DialogSellos({imagen, reloadData, setReloadData}) {


  

  // console.log("imagen en item", imagen)
	const [isInView, setIsInView] = React.useState(true);
  const navigate = useNavigate()
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
	 const [image, setImage] = React.useState(imagen);
  const user = useSelector((state) => state.user.userInfo);
  const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';

  const urlCatalog  = `/market/categoria/64ef4986d269731b208134bf?nombre=MARKET&subcategoria=${imagen?.subcategoria}`

  const numero = "+543416242464";
	const mensajeCompra = image?.nombre?.toLowerCase() === "giftcards"
  ? "Hola! Quiero recibir info sobre las gift cards!!!"
  : image.nombre.toLowerCase() === "pet friendly"
  ? "Hola! Quiero ir con mi mascota!!!"
  : image.nombre.toLowerCase() === "beautimix"
  ? "Hola! Quiero recibir info sobre beauti mix!!!"
  : "Hola! Quiero recibir más información."; // Mensaje por defecto si no se cumple ninguna de las condiciones anteriores

	const encodedMensaje = encodeURIComponent(mensajeCompra);
	const whatsappLink = `https://api.whatsapp.com/send?phone=${numero}&text=${encodedMensaje}`;

	const handleWhatsapp = () => {
		window.open(whatsappLink, "_blank");
	};



	return (
		<div style={{width:isMobileScreen?"100%":"400px",backgroundColor:"white", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
			

					<img style={{width:isMobileScreen?"100%":"400px",overflow:"hidden",height:"400px", objectFit:"cover", maxHeight:"50vh"}}src={image?.url} alt='beauty-team'  />

          

        <Typography  style={{fontFamily:"Panorama", fontSize:"60px", fontWeight:550}}>
        {image?.nombre}
        </Typography>
				

						{/* <p style={{fontFamily:"Montserrat", fontSize:"14px"}}>{image?.descripcion}</p> */}
						{image?.descripcion?.split('\/\n').map((paragraph, index) => (
							<>
							<Typography key={index} style={{ fontFamily: "Montserrat", textAlign: "left" }} gutterBottom>
								{paragraph.split(/(\*.*?:)/).map((text, index) => {
									if (text.startsWith('*')) {
										return <strong key={index}>{text}</strong>;
									} else {
										return text;
									}
								})}
							</Typography>
							<br></br>
							</>
						))}
		

				
						<button onClick={(image.nombre.toLowerCase() === 'giftcards' || image.nombre.toLowerCase() === 'pet friendly' || image.nombre.toLowerCase() === 'beautimix') ? handleWhatsapp : () => navigate(urlCatalog)} className='btn btn-sm btn-outline-secondary conoceMas'>
  CONOCÉ MÁS
</button>


			
			</div>
		
	);
}
