import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './CarouselGoInfo.Module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Loader from '../../components/loader/Loader';
import CarouselGoInfoItem from './CarouselGoInfoItem';
import { useMediaQuery } from 'react-responsive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog
} from "@mui/material";
const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

export default function CarouselGoInfo() {
	const widgetRef = React.useRef(null);
	const isMobile = useMediaQuery({ maxWidth: 700 });
	const [isWidgetOpened, setWidgetOpened] = React.useState(false);
	const [imageChangeKey, setImageChangeKey] = React.useState(0); // Nuevo estado para forzar la recarga

	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const [loader, setLoader] = React.useState(true);
	const [images, setImages] = React.useState([]);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';

	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=GO_LANDING_INFO`
			);
			setImages(data.imagenes);
			setLoader(false);
		};
		cargarImagenes();
	}, [imageChangeKey]);

	React.useEffect(() => {
		const handleDoneButtonClick = (event) => {
			if (isWidgetOpened) {
				event.preventDefault(); // Prevenir la acción predeterminada solo si el widget se ha abierto manualmente
				setWidgetOpened(false);
			}
		};

		document.addEventListener('click', handleDoneButtonClick);
		return () => {
			document.removeEventListener('click', handleDoneButtonClick);
		};
	}, [isWidgetOpened]);

	const handleImageChange = async (index) => {
		const widgetOptions = {
			cloudName: 'dlblszxyo', // Reemplaza con el nombre de tu cuenta en Cloudinary
			uploadPreset: 'laBeautiqueria', // Reemplaza con el nombre del preset de carga en Cloudinary
			cropping: false, // Deshabilitar la opción de recorte
			sources: ['local', 'url', 'camera'], // Permitir cargar desde archivos locales, URL o cámara
			multiple: false, // Permitir solo una imagen a la vez
			resourceType: 'image', // Permitir solo la carga de imágenes
			clientAllowedFormats: ['jpg', 'png', 'jpeg'], // Formatos permitidos (puedes agregar otros si lo deseas)
			maxFileSize: 5000000, // Tamaño máximo del archivo (en bytes), aquí son 5 MB
		};

		try {
			if (widgetRef.current) {
				setWidgetOpened(true);
				widgetRef.current.open();
			} else {
				const widget = window.cloudinary.createUploadWidget(
					widgetOptions,
					async (error, result) => {
						if (!error && result && result.event === 'success' && result.info.secure_url) {
							const url = result.info.secure_url; //se guarda la url nueva de la img que se subio

							// Actualizar la imagen en el estado local
							setImages((prevImages) =>
								prevImages.map((image, i) => (i === index ? { ...image, url } : image))
							);

							// Actualizar la imagen en el backend
							await axios.put(
								`${process.env.REACT_APP_BACK_URL}/api/imagenes/${images[index]._id}?url=${url}`,
								'',
								config
							);

							// Incrementar el estado imageChangeKey para forzar la recarga del componente
							setImageChangeKey((prevKey) => prevKey + 1);
						} else if (error) {
							console.error('Error al cargar la imagen:', error);
						}
					}
				);

				widgetRef.current = widget;
				widget.open();
			}
		} catch (error) {
			console.error('Error al eliminar la imagen:', error);
		}
	};
	const navigate= useNavigate()
	return (
    <div className='testimonios'>
        {!images.length ? (
            <Loader />
        ) : (
            <Box sx={{position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <Carousel
                    draggable={true}
                    swipeable={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
										arrows={true}
                    keyBoardControl={true}
                    autoPlaySpeed={5000}
                    containerClass='carousel-containerInfo'
										pauseOnHover={false}
          
                >
                    {images.map((image, index) => (
                        <CarouselGoInfoItem
                            key={index}
                            imageUrl={image.url}
                            isSuperAdmin={isSuperAdmin}
                            onChangeImage={() => handleImageChange(index)}
                        />
                    ))}
                </Carousel>
								<Box onClick={() => navigate("/")} style={{position:"absolute", cursor:"pointer"}} sx={{zIndex:1, width:!isMobile?"30%":"100%", height:"60px", backgroundColor:"#e5c4c9ab", borderRadius:"5px", display:"flex", alignItems:"center", justifyContent:"center" , transition: "background-color 0.3s, transform 0.3s",
              '&:hover': {
                backgroundColor: "#e5c4c9",
                transform: "scale(1.05)",
              }}}>
								<Typography sx={{color:"#fff", fontFamily:"Montserrat", fontWeight:600}}>

									#BEAUTISERVICIOS
								</Typography>
								</Box>

					
		    {/* {!isMobile && (
		    
		    
                <Box className="scroll-indicator-carousel" sx={{zIndex:1, left:"50%",top:"75vh",display: "flex", flexDirection: "column", alignItems: "center" ,justifyContent:"center",position:"absolute"}}>
								<KeyboardArrowDownIcon sx={{fontSize:"45px", color:"#5c5c5c"
				}}/>
                </Box>
											)} 	 */}


            </Box>
        )}
    </div>
);
}
