import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./app/store";
import App from "./App";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import router from "./Router";

import YustineFont from "./fonts/Yustine.otf"
import PanoramaFont from "./fonts/Panorama.otf"
import MontserratFont from "./fonts/Montserrat.ttf";
import NexaFont from "./fonts/Nexa.otf";
import GeorgiaFont from "./fonts/Georgia.ttf";
import DarlestonFont from "./fonts/Darleston.otf";
import Beauty1 from "./fonts/Beauty.otf"
import Beauty2 from "./fonts/BeautyDemo.otf"
import LoisBeauty from "./fonts/Loisbeauty.otf"
import BeautyQueen from "./fonts/BeautyQueen.otf"
import NightBeauty from "./fonts/NightBeauty.otf"
import DancingScript from "./fonts/DancingScript.ttf"
import Kaikoura from "./fonts/Kaikoura.otf"
import MarketDeco from "./fonts/MarketDeco.ttf"
import Camellia from "./fonts/Camellia.otf.otf"
import Romeria from "./fonts/Romeria.otf.otf"
import SecretFont from "./fonts/SecretCode.ttf"
import Letters from "./fonts/Letters.ttf"

const fontStyles = `
  @font-face {
    font-family: 'Nexa';
    src: url(${NexaFont}) format('truetype');
  }

  @font-face {
    font-family: 'Dancing';
    src: url(${DancingScript}) format('truetype');
  }
  
  @font-face {
    font-family: 'Letters';
    src: url(${Letters}) format('truetype');
  }
  
  @font-face {
    font-family: 'SecretFont';
    src: url(${SecretFont}) format('truetype');
  }

  @font-face {
    font-family: 'Camellia';
    src: url(${Camellia}) format('truetype');
  }
  @font-face {
    font-family: 'Romeria';
    src: url(${Romeria}) format('truetype');
  }
  @font-face {
    font-family: 'Kaikoura';
    src: url(${Kaikoura}) format('truetype');
  }
  @font-face {
    font-family: 'MarketDeco';
    src: url(${MarketDeco}) format('truetype');
  }
  @font-face {
    font-family: 'LoisBeauty';
    src: url(${LoisBeauty}) format('truetype');
  }
  @font-face {
    font-family: 'BeautyQueen';
    src: url(${BeautyQueen}) format('truetype');
  }
  @font-face {
    font-family: 'NightBeauty';
    src: url(${NightBeauty}) format('truetype');
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratFont}) format('truetype');
  }

@font-face {
  font-family: 'Yustine';
  src: url(${YustineFont}) format('truetype');
}

@font-face {
  font-family: 'Panorama';
  src: url(${PanoramaFont}) format('truetype');
}
@font-face {
  font-family: 'Beauty1';
  src: url(${Beauty1}) format('truetype');
}
@font-face {
  font-family: 'Beauty2';
  src: url(${Beauty2}) format('truetype');
}

  @font-face {
    font-family: 'Georgia2';
    src: url(${GeorgiaFont}) format('truetype');
  }

  @font-face {
    font-family: 'Darleston';
    src: url(${DarlestonFont}) format('truetype');
  }
`;

const styleTag = document.createElement("style");
styleTag.innerHTML = fontStyles;
document.head.appendChild(styleTag);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<RouterProvider router={router}>
				<App />
			</RouterProvider>
		</Provider>
	</React.StrictMode>
);
