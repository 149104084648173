import React from 'react';
import Stamp from './Stamp';
import vegan from '../../assets/Sellos/VEGAN.png';
import crueltyFree from '../../assets/Sellos/CRUELTY_FREE.png';
import atencionPersonalizada from '../../assets/Sellos/ATENCION_PERSONALIZADA.png';
import petFriendly from '../../assets/Sellos/PET_FRIENDLY.png';
import './Stamp.Module.css';

const imagenesTop = [
	{ image: vegan, name: 'Productos veganos', link: '', text:"Dentro de nuestro market pueden encontrar productos con el sello “Vegano”; este sello certifica que durante los procesos de fabricación y/o desarrollo del producto y sus ingredientes, NO se emplearon productos animales, subproductos o derivados." },
	{ image: crueltyFree, name: 'Cruelty free', link: '',  text:"La mayoría de nuestros productos cuentan con el sello CRUELTY FREE, certificando de esta manera que no están testeando sus productos en seres vivos."  },
	{ image: atencionPersonalizada, name: 'Atención personalizada', link: '', text:"En Nuestro Market creemos que tu experiencia debe ser única! Por eso te vamos a presentar a MARKETINA quien te va a acompañar en tu recorrido por nuestro shop, despejando dudas y curiosidades." },
	{ image: petFriendly, name: '#Pet Friendly', link: '', text: "En la Beauti los momentos se comparten con todos los integrantes de nuestra familia, Vení a elegir tus productos con la mejor compañía"
},
];

function StampTop() {
	return (
		<div style={{border:"none"}} className='container'>
			<div style={{border:"none"}} className='row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 py-5 px-5'>
				{imagenesTop?.map((imgStamp, index) => {
					return (
						<div key={index}>
							<Stamp imgStamp={imgStamp} />
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default StampTop;
