// Importar las librerías necesarias
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import './ModalEditGiftcard.Module.css';
import toast from 'react-hot-toast';
import ModalConfirmation from '../modalConfirmation/ModalConfirmation';
import axios from 'axios';
import { formatDate } from '../../../utils/validate/validate';
import { currencyDisplay } from '../../../utils/cart/cart';

// Definir el componente EditModal
const ModalEditGiftcard = ({ show, handleClose, giftcard }) => {
	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';

	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	// Manejar el estado del formulario en el modal
	const [formData, setFormData] = React.useState({
		estado: giftcard.estado,
		saldoConsumido: giftcard.saldoConsumido,
		fechaVencimiento: giftcard.vencimiento,
	});

	const [modifiedFechaVencimiento, setModifiedFechaVencimiento] = React.useState(
		formData.fechaVencimiento
	);
	const [modifiedSaldoConsumido, setModifiedSaldoConsumido] = React.useState(
		formData.saldoConsumido
	);

	const estadosGift = ['DISPONIBLE', 'UTILIZADA', 'VENCIDA'];

	const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'saldoConsumido') {
        const parsedValue = value === '' ? 0 : parseFloat(value);
        const saldoDisponibleFloat = parseFloat(giftcard.saldoDisponible);
        const precioFloat = parseFloat(giftcard.precio);

        // Verificar que la suma de saldoConsumido y saldoDisponible no exceda el precio de la giftcard
        if (!isNaN(parsedValue) && parsedValue <= precioFloat) {
            setModifiedSaldoConsumido(parsedValue);
            setFormData((prevData) => ({
                ...prevData,
                [name]: parsedValue,
            }));
        } else {
            toast.error('El saldo consumido no puede exceder el valor de la giftcard.');
        }
    } else {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'fechaVencimiento') {
            setModifiedFechaVencimiento(value);
        }
    }
};


	const [showConfirmation, setShowConfirmation] = React.useState(false);

	const handleShowConfirmation = () => {
		setShowConfirmation(true);
	};

	const handleDeleteGiftcard = async () => {
		try {
			await axios.delete(
				`${process.env.REACT_APP_BACK_URL}/api/giftcards/${giftcard?._id}`,
				config
			);
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event('actualizar-giftcard');
			window.dispatchEvent(event);
			toast.success('Giftcard eliminada con éxito');
			setShowConfirmation(false);
			handleClose();
		} catch (error) {
			toast.error('Error al eliminar la giftcard');
		}

		setShowConfirmation(false); // Cierra el modal de confirmación
	};
	const handleCancelDeleteGiftcard = () => {
		setShowConfirmation(false); // Cierra el modal de confirmación en caso de cancelación
	};

	// Manejar el evento de guardar los cambios en el modal
	const handleModalSave = async () => {
		try {
			const data = {
				estado: formData.estado,
				saldoConsumido: formData.saldoConsumido,
			};

			if (isSuperAdmin) {
				data.fechaVencimiento = formData.fechaVencimiento;
			}

			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/giftcards/${giftcard?.codigo}`,
				data,
				config
			);
			const event = new Event('actualizar-giftcard');
			window.dispatchEvent(event);
			toast.success('Giftcard actualizada con éxito');
			setFormData({
				estado: '',
			});
			handleClose();
		} catch (error) {
			toast.error('Ocurrió un error al actualizar la giftcard');
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Editar giftcard</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}
				<ModalConfirmation
					className='modaaaaal'
					show={showConfirmation}
					title='Eliminar giftcard'
					message='¿Estás seguro de que deseas eliminar la Giftcard?'
					onConfirm={handleDeleteGiftcard}
					onCancel={handleCancelDeleteGiftcard}
				/>
				<div>
					<label htmlFor='nombre'>Cliente:</label>
					<h5>
						{giftcard.cliente?.nombre} {giftcard.cliente?.apellido}
					</h5>
				</div>

				<div>
					<label htmlFor='nombre'>Código:</label>
					<h5>{giftcard?.codigo}</h5>
				</div>

				<div>
					<label htmlFor='nombre'>Fecha de emisión:</label>
					<h5>{formatDate(giftcard?.fecha)}</h5>
				</div>

				<div>
					<label htmlFor='nombre'>Fecha de vencimiento:</label>
					<h5>
						{formatDate(
							modifiedFechaVencimiento ? modifiedFechaVencimiento : giftcard?.vencimiento
						)}
					</h5>
				</div>

				<div>
					<label htmlFor='nombre'>Saldo Disponible:</label>
					<h5>{currencyDisplay(giftcard.saldoDisponible)}</h5>
				</div>
				<div>
					<label htmlFor='nombre'>Saldo Consumido:</label>
					<h5>{currencyDisplay(modifiedSaldoConsumido)}</h5>
				</div>

				{isSuperAdmin && (
					<div className='giftcardInput'>
						<label htmlFor='fechaVencimiento'>Modificar Fecha de vencimiento:</label>
						<input
							type='date'
							id='fechaVencimiento'
							name='fechaVencimiento'
							value={modifiedFechaVencimiento}
							onChange={handleChange}
						/>
					</div>
				)}

				{/* <div className='giftcardInput'>
					<label htmlFor='saldoConsumido'>Saldo Consumido:</label>
					<input
						type='number'
						id='saldoConsumido'
						name='saldoConsumido'
						value={modifiedSaldoConsumido}
						onChange={handleChange}
					/>
				</div> */}

				<div className='giftcardInput'>
    <label htmlFor='saldoConsumido'>Saldo Consumido:</label>
    <input
        type='number'
        id='saldoConsumido'
        name='saldoConsumido'
        value={modifiedSaldoConsumido}
        onChange={handleChange}
        min="0"
    />
</div>

				<div className='giftcardInput'>
					<label htmlFor='estado'>Estado:</label>
					<select id='estado' name='estado' value={formData.estado} onChange={handleChange}>
						{estadosGift.map((estado) => (
							<option key={estado} value={estado}>
								{estado}
							</option>
						))}
					</select>
				</div>

				<Button className='danger-edit' variant='danger' onClick={handleShowConfirmation}>
					Eliminar giftcard
				</Button>

				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button className='botonModalConfirmacion' variant='primary' onClick={handleModalSave}>
						Guardar Cambios
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditGiftcard;
