import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Definir una operación asincrónica para cargar la información del usuario
export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async (userId, thunkAPI) => {
	try {
		// Realiza una solicitud al servidor para obtener la información del usuario
		const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/usuarios/${userId}`);
		const data = await response.json();
		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error.message);
	}
});

const initialState = {
	userId: '',
	userInfo: {},
	allUsers: [],
	searchUsers: [],
	query: '',
	status: 'idle', // Agregar un estado para la operación asincrónica
	error: null, // Agregar una propiedad de error
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		login: (state, action) => {
			state.userId = action.payload;
		},
		setUserInfo: (state, action) => {
			state.userInfo = action.payload;
		},
		searchUser: (state, action) => {
			state.searchUsers = action.payload.data;
			state.query = action.payload.query;
		},
		allUsers: (state, action) => {
			state.allUsers = action.payload;
		},

		clearInfo: (state) => {
			state.userId = '';
			state.userInfo = {};
			state.allUsers = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserInfo.pending, (state) => {
				state.status = 'loading'; // Actualiza el estado a "cargando"
				state.error = null; // Limpia cualquier error anterior
			})
			.addCase(fetchUserInfo.fulfilled, (state, action) => {
				state.status = 'succeeded'; // Actualiza el estado a "completado"
				state.userInfo = action.payload; // Actualiza la información del usuario
			})
			.addCase(fetchUserInfo.rejected, (state, action) => {
				state.status = 'failed'; // Actualiza el estado a "fallido"
				state.error = action.error.message; // Almacena el mensaje de error
			});
	},
});

export const { login, searchUser, setUserInfo, allUsers, clearInfo } = userSlice.actions;

export default userSlice.reducer;
