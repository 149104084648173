import React from 'react';
import { Table } from 'react-bootstrap';
import { currencyDisplay } from '../../../utils/cart/cart';
import bolsa from "../../../assets/images/CORAZON-VERDE.png"

const ProductsTable = ({ products, onProductClick }) => {
	const [selected, setSelected] = React.useState(null);

	const handleProductClick = (user) => {
		setSelected(user);
		onProductClick(user);
	};
	const procesarCadena = (cadena) => {
		if (cadena && cadena[0].match(/\d/)) {
			return cadena.slice(1);
		} else {
			return cadena;
		}
	};

	const getColor = (number) => {
		if(!number || number <= 0 ){
			return "#9c000095"
		}else if ( number === 1){
			return "#a19f04c6"
		}else return "#05c205bb"
	}
	return (
		<Table responsive="sm" striped bordered hover>
			<thead>
				<tr>
					<th>Imagen</th>
					<th>Nombre</th>
					<th>Marca</th>
					<th>Precio Lista</th>
					<th>Precio Promo</th>
					<th>Stock</th>
					<th>Categoria</th>
				</tr>
			</thead>
			<tbody>
				{products?.map((product) => (
					<tr key={product._id} onClick={() => handleProductClick(product)}>
					<td className={selected?._id === product._id ? 'selected' : ''}>
        <img
          src={product?.img || bolsa}
          alt={product?.nombre || "Product Image"}
          style={{ maxWidth: "50px", maxHeight: "50px" }}
        />
      </td>
						<td className={selected?._id === product._id ? 'selected' : ''}>{procesarCadena(product?.nombre)}</td>
						<td className={selected?._id === product._id ? 'selected' : ''}>{product?.marca}</td>
						<td className={selected?._id === product._id ? 'selected' : ''}>
							{currencyDisplay(product?.precioTarjeta)}
						</td>
						<td className={selected?._id === product._id ? 'selected' : ''}>
							{currencyDisplay(product?.precioEfectivo)}
						</td>
						<td
  style={{
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: product?.variantes?.length === 0 ? getColor(product.disponibles) : 'initial',
    color: 'white',
  }}
>
  {product?.disponibles > 0 ? (
    <span style={{ fontWeight: 600, color: 'white' }}>{product.disponibles}</span>
  ) : (
    product?.variantes?.length > 0 ? (
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {product.variantes.map((variante, index) => (
          <li
            key={index}
            style={{
              backgroundColor: getColor(variante.disponibles),
              color: 'white',
              width: '100%',
              boxSizing: 'border-box',
              marginBottom: '5px',
            }}
          >
            <span style={{ fontWeight: 600, color: 'white' }}>{variante.color}</span> -{' '}
            <span style={{ fontWeight: 600, color: 'white' }}>{variante.disponibles || 0}</span>
          </li>
        ))}
      </ul>
    ) : (
      <span style={{ fontWeight: 600, color: 'white' }}>{product.disponibles || 0}</span>
    )
  )}
</td>
						<td className={selected?._id === product._id ? 'selected' : ''}>
							{product?.categoria?.nombre}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default ProductsTable;
