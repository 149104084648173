// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import toast from 'react-hot-toast';

import axios from 'axios';

// Definir el componente EditModal
const ModalNewMedioPago = ({ show, handleClose }) => {
	
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	// Manejar el estado del formulario en el modal
	const [formData, setFormData] = React.useState({
		nombre: '',
		estado: true,
    descripcion:"",
		color: '#ffffff',
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};



	const handleSaveDataMedio = async (formData) => {
		try {
			await axios.post(`${process.env.REACT_APP_BACK_URL}/api/mediospago`, formData, config);
			// Disparar un evento personalizado 'actualizar-alianza' cuando se haya actualizado el producto
			const event = new Event('actualizar-categoria');
			window.dispatchEvent(event);

			toast.success('Medio de pago creado/actualizado con éxito');
			setFormData({
				nombre: '',
				estado: '',
				descripcion: '',
        color:""
			});
			handleClose();
		} catch (error) {
			toast.error('Error al crear/actualizar el medio de pago');
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Crear medio de pago</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}

				<div>
					<label htmlFor='nombre'>Nombre:</label>

					<input
						className='form-control'
						type='text'
						name='nombre'
						value={formData?.nombre}
						onChange={handleChange}
						placeholder='Ej: VENTAS TD GK'
					/>
				</div>
        <div>
					<label htmlFor='nombre'>Descripción:</label>

					<input
						className='form-control'
						type='text'
						name='descripcion'
						value={formData?.descripcion}
						onChange={handleChange}
						placeholder='Ej: Ventas tarjeta debito Gabi'
					/>
				</div>
				<div>
					<label htmlFor='nombre'>Color:</label>

					<input
						className='form-control'
						type='color' // Cambiar el tipo de input a 'color'
						name='color'
						value={formData?.color}
						onChange={handleChange}
					/>
				</div>

				<div>
					<label htmlFor='estado'>Estado:</label>
					<select
						className='form-control'
						name='estado'
						value={formData?.estado.toString()}
						onChange={handleChange}
					>
						<option value='true'>Activo</option>
						<option value='false'>Inactivo</option>
					</select>
				</div>
			
				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveDataMedio(formData)}
					>
						Guardar Cambios
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalNewMedioPago;