import React from "react";
import { Link } from "react-router-dom";
import "./Alianza.Module.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import ModalEditAliance from "../modals/modalEditAliance/ModalEditAliance";
import { useMediaQuery } from 'react-responsive';
export default function Alianza({ alianza }) {
	const user = useSelector((state) => state.user.userInfo);
	const token = localStorage.getItem("Token");
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const isMobile = useMediaQuery({ maxWidth: 700 });
	const [showModal, setShowModal] = React.useState(false);
	const isSuperAdmin = user.role === "SUPERADMIN_ROLE";

	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleSaveData = async (formData) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/alianzas/${alianza?._id}`,
				formData,
				config
			);
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event("actualizar-alianza");
			window.dispatchEvent(event);
			toast.success("Producto actualizado con éxito");
		} catch (error) {
			toast.error("Error al actualizar producto");
		}
	};

	const numero = "+543416242464";

	
	const mensajeCompra = `Hola! soy ${user.nombre} ${user.apellido} y quiero mi beneficio para ${alianza?.nombre} `;
	const encodedMensaje = encodeURIComponent(mensajeCompra);
	const whatsappLink = `https://api.whatsapp.com/send?phone=${numero}&text=${encodedMensaje}`;

	const handleWhatsapp = () => {
		window.open(whatsappLink, "_blank");
	};

	return (
		<div className="alianzaContainer container containAbout verdecito">
			<div className="imgAlianza">
				<img src={alianza?.img} alt="beauty-team" />
			</div>
			<div style={{display:"flex", flexDirection:"column",gap:!isMobile&&"24px",justifyContent:"center", minHeight:"100% !important", alignItems:"center", paddingTop:"25px", width:"50%"}}>
				<h5>{alianza?.nombre}</h5>
				<p style={{width:isMobile && "80vw"}}>{alianza?.descripcion}</p>
				{isSuperAdmin && ( // Renderiza el ícono de lápiz solo si es un superadministrador
					<div className="edit-icon-aliance" onClick={handleOpenModal}>
						<i className="bi bi-pencil"></i>
					</div>
				)}
				<div className="botonesAlianza" style={{width:isMobile?"100vw":"100%", paddingBottom:"25px",justifyContent:"space-around"}}>
					<Link style={{textDecoration: "none"}} to={alianza?.link} target="_blank" rel="noopener noreferrer">
						<button style={{display:"flex", justifyContent:"center", fontFamily:"Montserrat", fontSize:"12px"}} className="alianceButton">VER #BEAUTIALIADO</button>
					</Link>
{user?.nombre && (
	<button style={{display:"flex", justifyContent:"center", fontFamily:"Montserrat", fontSize:"12px"}}  className="alianceButtonBene" onClick={() => handleWhatsapp()}>
	QUIERO MI BENEFICIO
</button>
)}
					
				</div>
			</div>

			<ModalEditAliance
				show={showModal}
				handleClose={handleCloseModal}
				aliance={alianza}
				handleSave={handleSaveData}
			/>
		</div>
	);
}
