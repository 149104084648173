import axios from 'axios';

const uri = process.env.REACT_APP_BACK_URL;

export const getGiftcards = async (desde) => {
	const { data } = await axios.get(`${uri}/api/giftcards?desde=${desde}`);
	return data;
};

export const searchAllGiftcards = async (termino, desde) => {
	const { data } = await axios.get(`${uri}/api/buscar/giftcards/${termino}?desde=${desde}`);
	return data;
};

export const getGiftcard = async (codigo) => {
	const { data } = await axios.get(`${uri}/api/giftcards/${codigo}`);
	return data;
};

export const isGiftCardExpired = (expirationDate) => {
	// Obtén la fecha actual
	const currentDate = new Date();

	// Convierte la fecha de vencimiento en un objeto Date
	const expiration = new Date(expirationDate);

	// Compara las fechas (ignorando la hora y otros detalles)
	return currentDate <= expiration;
};
