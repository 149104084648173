// Importar las librerías necesarias
import React, { useState } from 'react';
import { Modal, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import './ModalNewPurchase.Module.css';
import toast from 'react-hot-toast';
import { getStaffUsers, getUsersLimit, searchAllUsers } from '../../../utils/users/users';
import { getProducts, searchAllProducts } from '../../../utils/products/products';
import axios from 'axios';
import { DateTime } from 'luxon';
import {
	formatProductosSeleccionados,
	getMediosPago,
	sumaMedios,
} from '../../../utils/puchases/purchases';
import {
	obtenerCostoProductosServicios,
	obtenerPuntosProductosServicios,
} from '../../../utils/products/products';

import { getGiftcard, isGiftCardExpired } from '../../../utils/giftcards/giftcard';

import { validateTotal } from '../../../utils/puchases/purchases';

import UsersTable from '../../../routes/dashboard/usersTable/UsersTable';
import ProductsTable from '../../../routes/dashboard/productsTable/ProductsTable';
import Pagination from '../../pagination/Pagination';
import { currencyDisplay } from '../../../utils/cart/cart';
import { Typography } from '@mui/material';

// Definir el componente EditModal
const ModalNewPurchase = ({ show, handleClose }) => {
	const [productosSeleccionados, setProductosSeleccionados] = React.useState({});

	const[cajaDiaria, setCajaDiaria] = useState(null)

	const [showVarianteSelector, setShowVarianteSelector] = React.useState(false);

	const [selectedVarianteId, setSelectedVarianteId] = React.useState('');

	const [staff, setStaff] =React.useState([])

	const [giftCardInfo, setGiftCardInfo] = React.useState({});
	const [giftCardCode, setGiftCardCode] = React.useState('');

	const cantidadPuntos = obtenerPuntosProductosServicios(productosSeleccionados);

	const handlePriceChange = (e, productId) => {
		const { value } = e.target;
		const productoExistente = productosSeleccionados[productId];

		if (value === 'precioPersonalizado') {
			// Si el valor es 'precioPersonalizado', muestra el input
			const nuevosProductos = {
				...productosSeleccionados,
				[productId]: {
					...productoExistente,
					precioElegido: value,
					showInput: true,
				},
			};
			setProductosSeleccionados(nuevosProductos);
		} else {
			// En otros casos, actualiza el precio seleccionado y oculta el input
			const nuevosProductos = {
				...productosSeleccionados,
				[productId]: {
					...productoExistente,
					precioElegido: value,
					showInput: false,
				},
			};
			setProductosSeleccionados(nuevosProductos);
		}
	};

	const handleCustomPriceChange = (e, productId) => {
		const { value } = e.target;
		const productoExistente = productosSeleccionados[productId];

		if (value === '') {
			// Si el valor está vacío, oculta el input
			const nuevosProductos = {
				...productosSeleccionados,
				[productId]: {
					...productoExistente,
					precioElegido: value,
					showInput: false,
				},
			};
			setProductosSeleccionados(nuevosProductos);
		} else {
			// Si hay un valor, muestra el input y actualiza el precio personalizado
			const nuevosProductos = {
				...productosSeleccionados,
				[productId]: {
					...productoExistente,
					precioElegido: value,
					showInput: true,
				},
			};
			setProductosSeleccionados(nuevosProductos);
		}
	};

	const eliminarUsuario = () => {
		setFormData((prevData) => ({
			...prevData,
			cliente: '', // Actualiza el campo 'cliente' con el ID del usuario clickeado
		}));
		setSelectedBeneficio('');
	};

	const agregarProductoSeleccionado = (producto, varianteId = '') => {
		const productoKey = varianteId === '' ? producto._id : `${producto._id}-${varianteId}`;
		const productoExistente = productosSeleccionados[productoKey];

		if (productoExistente) {
			// Si el producto ya existe, actualiza su cantidad
			const nuevosProductos = {
				...productosSeleccionados,
				[productoKey]: {
					...productoExistente,
					cantidad: productoExistente.cantidad + 1,
				},
			};
			setProductosSeleccionados(nuevosProductos);
		} else {
			// Si el producto no existe, agrégalo con una cantidad inicial de 1
			const nuevoProducto = {
				...producto,
				varianteId,
				cantidad: 1,
				// Otras propiedades como precioElegido, showInput, etc.
			};
			setProductosSeleccionados({
				...productosSeleccionados,
				[productoKey]: nuevoProducto,
			});
		}
	};

	const decrementarCantidad = (producto) => {
		const { _id: productId, varianteId } = producto;

		// Crear una copia del estado actual de productosSeleccionados
		const nuevosProductos = { ...productosSeleccionados };
		const productoKey = varianteId !== '' ? varianteId : productId;

		if (nuevosProductos[productoKey]) {
			// Obtener el producto o variante existente
			const productoExistente = nuevosProductos[productoKey];

			if (productoExistente.cantidad > 1) {
				// Si la cantidad es mayor que 1, decrementamos la cantidad
				productoExistente.cantidad -= 1;
			} else {
				// Si la cantidad es 1 o menor, eliminamos el producto o variante
				delete nuevosProductos[productoKey];
			}

			// Establecer el estado con los productos actualizados
			setProductosSeleccionados(nuevosProductos);
		}
	};

	const incrementarCantidad = (producto) => {
		const { _id: productId, varianteId } = producto;

		// Crear una copia del estado actual de productosSeleccionados
		const nuevosProductos = { ...productosSeleccionados };
		const productoKey = varianteId !== '' ? varianteId : productId;

		if (nuevosProductos[productoKey]) {
			// Incrementar la cantidad del producto o variante existente
			nuevosProductos[productoKey].cantidad += 1;
		} else {
			// Si el producto o variante no existe, crearlo con cantidad 1
			nuevosProductos[productoKey] = {
				...producto,
				cantidad: 1,
			};
		}

		// Establecer el estado con los productos actualizados
		setProductosSeleccionados(nuevosProductos);
	};

	const cambiarCantidad = (e, producto) => {
		const nuevaCantidad = parseInt(e.target.value, 10); // Parsea el valor del input a un número entero

		if (
			!isNaN(nuevaCantidad) &&
			nuevaCantidad >= 1 &&
			nuevaCantidad <= (producto.disponibles || 100)
		) {
			// Verifica que la nueva cantidad sea un número válido y está dentro del rango

			const { _id: productId, varianteId } = producto;

			// Crear una copia del estado actual de productosSeleccionados
			const nuevosProductos = { ...productosSeleccionados };
			const productoKey = varianteId !== '' ? varianteId : productId;

			if (nuevosProductos[productoKey]) {
				// Obtener el producto o variante existente
				const productoExistente = nuevosProductos[productoKey];

				// Actualizar la cantidad con la nueva cantidad
				productoExistente.cantidad = nuevaCantidad;

				// Establecer el estado con los productos actualizados
				setProductosSeleccionados(nuevosProductos);
			}
		}
	};

	const eliminarProducto = (producto) => {
		const { _id: productId, varianteId } = producto;

		// Crear una copia del estado actual de productosSeleccionados
		const nuevosProductos = { ...productosSeleccionados };
		const productoKey = varianteId !== '' ? varianteId : productId;

		if (nuevosProductos[productoKey]) {
			delete nuevosProductos[productoKey];
		} else {
			return;
		}

		// Establecer el estado con los productos actualizados
		setProductosSeleccionados(nuevosProductos);
	};

	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	// Manejar el estado del formulario en el modal
	const [formData, setFormData] = React.useState({
		cliente: '',
		detalle: [],
		total: '',
		totalPuntos: '',
		mediosPago: [],
		estadoCompra: 'FINALIZADA',
		beneficio: '',
		atendidoPor:"",
		notas:""
	});

	const [selectedBeneficio, setSelectedBeneficio] = React.useState(''); // Agrega el estado para el beneficio seleccionado

	const [subTotalConDescuento, setSubtotalConDescuento] = React.useState(0); // Agrega el estado para el beneficio seleccionado

	const [paginaActualClientes, setPaginaActualClientes] = React.useState(1);
	const [paginaActualProductos, setPaginaActualProductos] = React.useState(1);
	const productosPorPagina = 8;

	//Estado Usuarios
	const [terminoUsuario, setTerminoUsuario] = React.useState('');
	const [usuariosDesde, setUsuariosDesde] = React.useState(0);
	const [users, setUsers] = React.useState([]);

	//Estado productos
	const [terminoProducto, setTerminoProducto] = React.useState('');
	const [productosDesde, setProductosDesde] = React.useState(0);
	const [products, setProducts] = React.useState([]);

	const paginadoUsers = (numeroPagina) => {
		setPaginaActualClientes(numeroPagina);
		setUsuariosDesde(numeroPagina * 8 - 8);
	};

	const paginadoProducts = (numeroPagina) => {
		setPaginaActualProductos(numeroPagina);
		setProductosDesde(numeroPagina * 16 - 16);
	};

	// Manejar click de usuario
	const handleUserClick = (userId) => {
		setFormData((prevData) => ({
			...prevData,
			cliente: userId, // Actualiza el campo 'cliente' con el ID del usuario clickeado
		}));
	};
	const handleStaffSelect = (staffId)=>{

		setFormData((prevData) => ({
			...prevData,
			atendidoPor: staffId, // Actualiza el campo 'cliente' con el ID del usuario clickeado
		}));
	}

	const handleSearchUser = (e) => {
		e.preventDefault();
		setUsuariosDesde(0);
		setPaginaActualClientes(1);
		setTerminoUsuario(e.target.value);
	};
	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
	
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	// const handleProductClick = (producto) => {
	// 	agregarProductoSeleccionado(producto);
	// };
	const handleProductClick = (producto) => {
		if (producto?.variantes?.length > 0) {
			// Si el producto tiene variantes, muestra el selector de variantes
			setSelectedVarianteId(producto.variantes[0]._id); // Establece la primera variante como seleccionada por defecto
			setShowVarianteSelector(true); // Muestra el selector de variantes
		}
		agregarProductoSeleccionado(producto); // Siempre agrega el producto, incluso si no tiene variantes
	};

	const handleVarianteChange = (e, productId) => {
		const { value: varianteId } = e.target;
		const productoExistente = productosSeleccionados[productId];

		if (productoExistente) {
			// Elimina la clave anterior del producto en el estado
			const nuevosProductos = { ...productosSeleccionados };
			delete nuevosProductos[productId];

			// Busca la variante correspondiente en el producto original
			const varianteSeleccionada = productoExistente.variantes.find(
				(variante) => variante._id === varianteId
			);

			// Agrega una nueva clave con el ID de la variante y el color al nombre
			nuevosProductos[varianteId] = {
				...productoExistente,
				nombre: `${productoExistente.nombre} - ${varianteSeleccionada.color}`,
				varianteId: varianteId,
			};

			setProductosSeleccionados(nuevosProductos);
		}
	};

	const handleSearchProduct = (e) => {
		e.preventDefault();
		setProductosDesde(0);
		setPaginaActualProductos(1);
		setTerminoProducto(e.target.value);
	};
	const getColor = (number) => {
		
		if(number <= 0){
			return "#9c000095"
		}else if ( number === 1){
			return "#a19f04c6"
		}else return "#05c205bb"
	}
	// Manejar el evento de guardar los cambios en el modal
	const handleNewPurchase = async (formData) => {
		const productosFormateados = formatProductosSeleccionados(productosSeleccionados);

		// Verifica si "GIFTCARD" está en los medios de pago seleccionados
		const isGiftCardSelected = selectedMediosPago.some(
			(medioPago) => medioPago.nombre === 'GIFTCARD'
		);

		const totalConsumidoEnGiftCard = isGiftCardSelected
			? selectedMediosPago.find((medioPago) => medioPago.nombre === 'GIFTCARD').monto
			: 0;

		const fechaArgentina = DateTime.now()
			.setZone('America/Argentina/Buenos_Aires')
			.toFormat('yyyy-MM-dd HH:mm:ss');

			
		const data = {
			cliente: formData.cliente?._id,
			atendidoPor:formData.atendidoPor,
			notas:formData.notas,
			detalle: productosFormateados.detalle,
			total: subTotalConDescuento,
			totalPuntos: cantidadPuntos,
			estadoCompra: 'FINALIZADA', // Puedes ajustar este valor según tu lógica
			beneficio: selectedBeneficio,
			mediosPago: selectedMediosPago,
			fecha: fechaArgentina,
		};

		try {
			await axios.post(`${process.env.REACT_APP_BACK_URL}/api/compras/nueva`, data, config);

			// Actualiza el saldo consumido en la gift card
			if (isGiftCardSelected) {
				const giftCardCodigo = giftCardCode; // Reemplaza esto por la forma de obtener el código de la gift card
				const saldoConsumido = totalConsumidoEnGiftCard;
				const estado =
					giftCardInfo.saldoDisponible - saldoConsumido === 0 ? 'UTILIZADA' : 'DISPONIBLE';

				await axios.put(
					`${process.env.REACT_APP_BACK_URL}/api/giftcards/${giftCardCodigo}`,
					{ estado, saldoConsumido },
					config
				);
				const event2 = new Event('actualizar-giftcard');
				window.dispatchEvent(event2);
			}

			const event = new Event('actualizar-compra');
			window.dispatchEvent(event);

			toast.success('Venta almacenada con éxito');
			// setFormData({
			// 	precio: '',
			// 	cliente: '',
			// });
			handleClose();
		} catch (error) {
			toast.error('Ocurrió un error al enviar la venta');
		}
	};
	//------------------------------------------------------------------------------------------

	// Handler para el cambio de beneficio seleccionado
	// Handler para el cambio de beneficio seleccionado
	const handleBeneficioChange = (e) => {
		const selectedBeneficioId = e.target.value;
		// Buscar el objeto completo del beneficio por su ID en los beneficios disponibles
		const beneficioSeleccionado = formData.cliente?.beneficios.find(
			(beneficio) => beneficio._id === selectedBeneficioId
		);
		setSelectedBeneficio(beneficioSeleccionado); // Actualiza el estado con el objeto completo del beneficio seleccionado
	};

	//console.log(selectedBeneficio);

	//---------------------------------------------------------------------------------------------
	const [mediosPago, setMediosPago] = React.useState([]);
	const [selectedMediosPago, setSelectedMediosPago] = React.useState([]); // Estado para los medios de pago seleccionados



	const handleMediosPagoChange = (medioPagoId) => {
		// Buscar el medio de pago por su id en la lista de mediosPago
		const medioPagoSeleccionado = mediosPago.find((medio) => medio._id === medioPagoId);

		if (medioPagoSeleccionado) {
			setSelectedMediosPago((prevSelectedMediosPago) => {
				// Verificar si el medio de pago ya existe en el estado
				const medioExistente = prevSelectedMediosPago.find(
					(medio) => medio.medioPago === medioPagoId
				);

				if (medioExistente) {
					return prevSelectedMediosPago.map((medio) =>
						medio.medioPago === medioPagoId
							? { ...medio, monto: '' } // Reiniciar el monto si el medio de pago ya existe
							: medio
					);
				} else {
					return [
						...prevSelectedMediosPago,
						{ medioPago: medioPagoId, nombre: medioPagoSeleccionado.nombre, monto: 0 },
					];
				}
			});
		}
	};

	const handleMontoMedioPagoChange = (e, index) => {
		const { value } = e.target;
		const updatedSelectedMediosPago = [...selectedMediosPago];
		updatedSelectedMediosPago[index].monto = value !== '' ? parseFloat(value) : 0;
		setSelectedMediosPago(updatedSelectedMediosPago);
	};

	const handleRemoveMedioPago = (index) => {
		setSelectedMediosPago((prevSelectedMediosPago) => {
			// Filtra los medios de pago para mantener solo los que no coinciden con el índice
			return prevSelectedMediosPago.filter((_, i) => i !== index);
		});
	};
	const handleGetCierre = async () => {
		try {
			const today = new Date();
			const formattedDate = today.toISOString().split('T')[0]; // Obtiene la fecha en formato "año-mes-día"
			// console.log(formattedDate)
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/cajadiaria?fechaDesde=${formattedDate}`,
				config
			);
	
			setCajaDiaria(data);
		} catch (error) {
			console.log(error);
			toast.error(error.response.data?.msg);
		}
	};
React.useEffect(() => {
	handleGetCierre()
	
}, [])

	React.useEffect(() => {
		const fetchMediosPago = async () => {
			try {
				const mediosPagoData = await getMediosPago(0, 20);
				setMediosPago(mediosPagoData.mediosDePago);
				const staffData = await getStaffUsers()
				setStaff(staffData)
			} catch (error) {
				toast.error('hubo un error obteniendo los medios de pago');
			}
		};

		fetchMediosPago();
	}, []);

	const handleVerifyGiftCard = async () => {
		try {
			const giftCardData = await getGiftcard(giftCardCode);
			setGiftCardInfo(giftCardData);
		} catch (error) {
			toast.error('Hubo un error obteniendo los datos de la gift card');
		}
	};

	React.useEffect(() => {
		toast.remove();
		async function obtenerProductos() {
			setProducts(await getProducts(productosDesde));
		}
		async function buscarProductos() {
			setProducts(await searchAllProducts(terminoProducto, productosDesde));
		}

		if (terminoProducto === '') {
			obtenerProductos();
		} else {
			buscarProductos();
		}
		window.addEventListener('actualizar-producto', obtenerProductos);
		return () => {
			window.removeEventListener('actualizar-producto', obtenerProductos);
			setCajaDiaria(null)
		};
	}, [productosDesde, terminoProducto]);

	React.useEffect(() => {
		async function obtenerUsuarios() {
			setUsers(await getUsersLimit(usuariosDesde, 8));
		}
		async function buscarUsuarios() {
			setUsers(await searchAllUsers(terminoUsuario, usuariosDesde));
		}

		if (terminoUsuario === '') {
			obtenerUsuarios();
		} else {
			buscarUsuarios();
		}
	}, [usuariosDesde, terminoUsuario]);

	React.useEffect(() => {
		// Calcula el costo de productos y servicios con el beneficio seleccionado
		const costoConBeneficio = obtenerCostoProductosServicios(
			productosSeleccionados,
			selectedBeneficio
		);

		// Actualiza el estado de subTotalConDescuento con el costo calculado
		setSubtotalConDescuento(costoConBeneficio);
	}, [productosSeleccionados, selectedBeneficio]);

	return (
		<Modal className='modalNewPurchase' backdrop='static' show={show} onHide={handleClose}>
			<Modal.Header className=' headerNewPurchase' closeButton>
				<Modal.Title>Nueva venta/servicio</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer bodyNewPurchase'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}

				<div>
				<label htmlFor='nombre'>Selecciona Staff:</label>
				<DropdownButton
							// className='medivarianstyle={{color:}}
							variant="success"
							id='mediosPagoDropdown'
							title={
								formData?.atendidoPor !== ""
									? `${staff?.usuarios?.filter(u => u._id === formData?.atendidoPor)[0].nombre} ${staff?.usuarios?.filter(u => u._id === formData?.atendidoPor)[0].apellido}`
									: 'Atendido por'
							}
							onSelect={(eventKey) => handleStaffSelect(eventKey)}
						>
								{staff?.usuarios?.map((staff) => (
									<Dropdown.Item  key={staff._id} eventKey={staff._id} >
										{staff?.nombre?.toUpperCase()} {staff?.apellido?.toUpperCase()}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</div>

						<br></br>
				{formData?.atendidoPor && formData?.cliente === '' && (
					<section className='usersNewPurchase'>
						<div>
							<label htmlFor='nombre'>Selecciona Cliente:</label>


							<div className='containerTablaProductos'>
								<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
									<input
										className='form-control inputSearch'
										type='search'
										placeholder='Buscar'
										value={terminoUsuario}
										onChange={(e) => handleSearchUser(e)}
									/>
									<i className='bx bx-search form-control-feedback'></i>
								</form>
							</div>
							<UsersTable
								users={users.usuarios}
								onUserClick={(userId) => handleUserClick(userId)}
							/>
							<Pagination
								key={paginaActualClientes}
								productosPorPagina={productosPorPagina}
								productos={users.total}
								paginado={paginadoUsers}
								paginaActual={paginaActualClientes}
							/>
						</div>
					</section>
				)}
	{formData?.cliente && (

				<section className='productsNewPurchase'>
					<div className='containerTablaProductos'>
						<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
							<input
								className='form-control inputSearch'
								type='search'
								placeholder='Buscar'
								value={terminoProducto}
								onChange={(e) => handleSearchProduct(e)}
							/>
							<i className='bx bx-search form-control-feedback'></i>
						</form>
					</div>

					<ProductsTable products={products.productos} onProductClick={handleProductClick} />

					<Pagination
						key={paginaActualProductos}
						productosPorPagina={productosPorPagina}
						productos={products.total}
						paginado={paginadoProducts}
						paginaActual={paginaActualProductos}
					/>
				</section>
	)}
				<div>
					<h4 htmlFor='Cliente'>Cliente seleccionado: </h4>
					<label>

					<h5 style={{backgroundColor:"#ffff"}}>
						{formData.cliente ? (
							<>
								{`${formData.cliente?.nombre} ${formData.cliente?.apellido}`}{' '}
								<abbr title='Eliminar usuario seleccionado'>
									<button className='btn btn-sm  carrito' onClick={eliminarUsuario}>
										<i className='bx bx-trash'></i>
									</button>
								</abbr>
							</>
						) : (
							<h5 style={{color:"red"}}>Debes seleccionar un cliente</h5>
						)}
					</h5>
					</label>
					<br></br>
					<section style={{backgroundColor:"#fff"}} className='productosSeleccionados'>
						<h4>Productos Seleccionados:</h4>
						<ul>
							{Object.values(productosSeleccionados).map((producto, index) => (
								<li key={producto._id}>
									<div  style={{paddingTop:"10px", paddingBottom:"10px", alignItems:"center", paddingRight:"60px"}} className='producto-info'>
										<span >{producto.nombre}</span>

										<div >
											{producto.variantes &&
												producto.variantes.length > 0 &&
												producto.varianteId === '' && (
													<div>
														<select
															value={producto.varianteId || ''}
															onChange={(e) => handleVarianteChange(e, producto._id)}
														>
															<option value=''>Variante</option>
															{producto.variantes?.map((variante) => (
																<option key={variante._id} value={variante._id}>
																	{variante.color}
																</option>
															))}
														</select>
													</div>
												)}
										</div>
										{(producto.variantes?.length > 0 && producto.varianteId !== '') ||
										producto.variantes?.length === 0 ? (
											<div className={`precios`}>
												<label>Selecciona el precio:</label>
												{!producto.showInput && (
													<select
														className={`form-control ${
															!producto.precioElegido ? 'select-red-background' : ''
														}`}
														value={producto.precioElegido ? producto.precioElegido : ''}
														onChange={(e) =>
															handlePriceChange(
																e,
																producto.varianteId !== '' ? producto.varianteId : producto._id
															)
														}
													>
														<option value='' disabled hidden>
															Selecciona una opción
														</option>
														<option value={producto.precioTarjeta}>
															Precio Lista {currencyDisplay(producto.precioTarjeta)}
														</option>
														<option value={producto.precioEfectivo}>
															Precio Promo {currencyDisplay(producto.precioEfectivo)}
														</option>
														{producto.precioOferta > 0 && (
															<option value={producto.precioOferta}>
																Precio Oferta {currencyDisplay(producto.precioOferta)}
															</option>
														)}
														<option value='precioPersonalizado'>Precio Personalizado</option>
													</select>
												)}
												{producto.showInput &&  (
													<>
														<label htmlFor={`customPriceInput${index}`}>
															Precio Personalizado:
														</label>
														<input
															className='form-control'
															type='number'
															id={`customPriceInput${index}`}
															value={producto.precioElegido || ''}
															onChange={(e) =>
																handleCustomPriceChange(
																	e,
																	producto.varianteId !== '' ? producto.varianteId : producto._id
																)
															}
														/>
													</>
												)}
											</div>
										) : null}
										<div style={{display:"flex"}}className='acciones'>

												<div style={{display:"flex"}}>
												<button
												className='btn btn-sm btn-outline-secondary menos'
												onClick={() => decrementarCantidad(producto)}
											>
												-
											</button>
											<input
												className='imputCantidad canti'
												style={{display:"flex", justifyContent:"center", 
													appearance: "textfield",
													MozAppearance: "textfield",
													WebkitAppearance: "none"}}
												type='number'
												name=''
												value={producto.cantidad}
												id=''
												max={producto.disponibles || 100}
												min={1}
												onChange={(e) => cambiarCantidad(e, producto)}
											/>
											<button
												className='btn btn-sm btn-outline-secondary mas'
												onClick={() => incrementarCantidad(producto)}
											>
												+
											</button>
												</div>
										
											<abbr title='Eliminar del carrito'>
												<button
													className='btn btn-sm  carrito'
													onClick={() => eliminarProducto(producto)}
												>
													<i className='bx bx-trash'></i>
												</button>
											</abbr>
											<div className='stock' style={{backgroundColor:"#d9d9d9"}}>
												<label style={{padding:"5px"}}>STOCK:</label>
												{producto.disponibles > 0 ? (
													<span style={{
														backgroundColor: getColor(producto.disponibles),
														width:"150px",
														color: 'white',
														fontWeight:600,
														padding:"2px 5px"
													}}>{producto.disponibles}</span>
												) : (
													<div>
														<ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
															{producto.variantes?.map((variante) => (
																<li style={{
																	backgroundColor: getColor(variante.disponibles),
																	color: 'white',
																	width:"150px",
																	fontWeight:600
																}} className='variante'>{`${variante.color} - ${variante.disponibles}`}</li>
															))}
														</ul>
													</div>
												)}
											</div>
										</div>
									</div>
								</li>
							))}
						</ul>
					</section>
					{formData.cliente && (
						<section className='beneficiosCliente'>
							<label>
								Beneficios disponibles para cliente categoria{' '}
								{formData.cliente?.categoria.nombre || ''}: 
							</label>
							<br></br>
							<select value={selectedBeneficio} onChange={handleBeneficioChange}>
								<option value=''>Selecciona un beneficio</option>
								{formData.cliente?.beneficios
									?.filter((beneficio) => beneficio.disponible === true)
									.map((beneficio, index) => (
										<option key={index} value={beneficio._id}>
											{beneficio?.nombre || ''}
										</option>
									))}
							</select>
						</section>
					)}
					<section style={{gap:"8px"}} className='costoYdiamantes'>
					<h4>Detalle de venta:</h4>
					<label>

					<Typography 
						style={{
							backgroundColor:"green", 
							color:"#fff",
							height:"25px",
							paddingLeft:"5px",
							width:"25%"
							}}>
							{`TOTAL DE LA VENTA
						${currencyDisplay(obtenerCostoProductosServicios(productosSeleccionados))}`}
						</Typography >
					</label>

						{selectedBeneficio && (
							<>
								<div>
									<label style={{ fontStyle: 'italic' }}> Beneficio seleccionado: {selectedBeneficio?.nombre} </label>
									<abbr title='Eliminar beneficio seleccionado'>
										<button
											className='btn btn-sm  carrito'
											onClick={() => setSelectedBeneficio('')}
										>
											<i className='bx bx-trash'></i>
										</button>
									</abbr>
								</div>
								<label style={{ fontStyle: 'italic' }}>
									{`Monto descontado: ${currencyDisplay(
										obtenerCostoProductosServicios(productosSeleccionados) - subTotalConDescuento
									)}`}
								</label>
								<label>
									<strong style={{backgroundColor:"#ffff"}}>
										{`Total de la venta con descuento: ${currencyDisplay(subTotalConDescuento)}`}
									</strong>
								</label>
							</>
						)}

						
						<label>{`Suma de medios de pago: ${currencyDisplay(
							sumaMedios(selectedMediosPago)
						)}`}
						</label>
						
						<label >
						<Typography 
						style={{
							backgroundColor:"orange", 
							color:"#fff",
							height:"25px",
							paddingLeft:"5px",
							width:"25%"
							}}>
							{`Aún debes asignar: ${currencyDisplay(
							subTotalConDescuento - sumaMedios(selectedMediosPago)
						)}`}
						</Typography>
						
						</label>
						<label style={{ fontStyle: 'italic' }}>{`Perlas sumadas por venta: ${cantidadPuntos && cantidadPuntos}`}</label>
					</section>
					
					<section className='mediosDePagoNew'>
						<div>
							<DropdownButton
								className='mediosPagoDrop dropNew'
								id='mediosPagoDropdown'
								title='Selecciona los medios de pago'
								onSelect={(eventKey) => handleMediosPagoChange(eventKey)}
							>
								{mediosPago?.map((medio) => (
									<Dropdown.Item style={{backgroundColor:medio?.color ||""}} key={medio._id} eventKey={medio._id} className='itemEditPurchase'>
										{medio?.nombre || ''}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</div>

						{selectedMediosPago?.map((medioPagoObj, index) => (
							<div key={index}>
									{medioPagoObj.nombre === 'GIFTCARD' && (
									<div style={{display:"flex", flexDirection:"column", justifyContent:"center",gap:"10px",alignItems:"center"}}>
										<label htmlFor='giftCardCode'>Código de Gift Card:</label>
										<input
											className='form-control'
											type='text'
											id='giftCardCode'
											value={giftCardCode}
											onChange={(e) => setGiftCardCode(e.target.value)}
										/>
										<Button onClick={handleVerifyGiftCard}>Verificar</Button>
										{/* Aquí puedes agregar una función para hacer una petición GET al servidor para verificar la gift card */}
										{giftCardInfo[0]?.vencimiento &&
										!isGiftCardExpired(giftCardInfo[0].vencimiento) ? (
											<div>
												<label
													className='pRed'
													htmlFor='giftCardCode'
												>{`Estado: GIFTCARD VENCIDA`}</label>
											</div>
										) : (
											giftCardInfo[0] && 
												<div>
												<label htmlFor='giftCardCode'>{`Estado: GIFTCARD DISPONIBLE`}</label>
											</div>
											
											
											
										
										)}
										{giftCardInfo[0]?.saldoDisponible > 0 ? (
											<div>
												<label htmlFor='giftCardCode'>{`Saldo disponible: ${currencyDisplay(
													giftCardInfo[0].saldoDisponible
												)}`}</label>
											</div>
										) : giftCardCode !== '' ? (
											<div>
												<label>La giftcard ingresada no posee saldo</label>
											</div>
										) : null}
									</div>
								)}
								<label  htmlFor={`montoMedioPago${index}`}>
									Ingrese monto de <strong> {medioPagoObj?.nombre || ''}</strong>:
								</label>
								<abbr title='Eliminar medio de pago' style={{ cursor: 'pointer' }}>
									<i
										className='bi bi-x-circle filterOptionXnew'
										onClick={() => handleRemoveMedioPago(index)}
									></i>
								</abbr>
								<input
									className='form-control'
									type='number'
									id={`montoMedioPago${index}`}
									value={medioPagoObj.monto || ''}
									onChange={(e) => handleMontoMedioPagoChange(e, index)}
								/>
							
							</div>
						))}
					</section>
				</div>
				<div>
          <label htmlFor='notas'>Notas:</label>
          <input
            className='form-control'
            type='text'
            name='notas'
            value={formData?.notas}
            onChange={handleChange}
            placeholder='Ingresa un comentario acerca de esta venta'
          ></input>
        </div>
				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						disabled={
							!validateTotal(subTotalConDescuento, selectedMediosPago) ||
							(giftCardInfo[0]?.vencimiento && !isGiftCardExpired(giftCardInfo[0].vencimiento)) ||
							cajaDiaria?.cierre?.estado === "CERRADA" ||
							
							!formData?.cliente
						}
						onClick={() => handleNewPurchase(formData)}
					>
						Enviar nueva venta
					</Button>
				</div>
				{!validateTotal(subTotalConDescuento, selectedMediosPago) && (
					<p className='pRed'>
						***El monto de los medios de pago seleccionados, debe coincidir con el subtotal con
						descuento***
					</p>
				)}
					{cajaDiaria && !cajaDiaria?.cierre && (
					<p className='pRed'>
						***No se encontro caja diaria para hoy***
					</p>
				)}
					{cajaDiaria?.cierre?.estado === "CERRADA" && (
					<p className='pRed'>
						***No puedes cargar ventas si la caja está cerrada***
					</p>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default ModalNewPurchase;
