import React from 'react';
import { Table } from 'react-bootstrap';
import { formatDate } from '../../../utils/validate/validate';
import { currencyDisplay } from '../../../utils/cart/cart';
import './GiftcardsTable.Module.css';

const GiftcardsTable = ({ giftcards, onGiftcardClick }) => {
	const [selected, setSelected] = React.useState(null);

	const handleGiftcardClick = (giftcard) => {
		setSelected(giftcard);
		onGiftcardClick(giftcard);
	};
	return (
		<Table responsive="sm"  striped bordered hover>
			<thead>
				<tr>
					<th>Codigo</th>
					<th>Fecha Compra</th>
					<th>De (comprador)</th>
					<th>Valor Total</th>
					<th>Utilizado</th>
					<th>Saldo Disponible</th>
					<th>Vencimiento</th>
					<th>Estado</th>
				</tr>
			</thead>
			<tbody>
				{giftcards?.map((giftcard) => (
					<tr key={giftcard._id} onClick={() => handleGiftcardClick(giftcard)}>
						<td className={selected?._id === giftcard._id ? 'selected' : ''}>
						<b>{giftcard.codigo}</b>
						</td>
						<td className={selected?._id === giftcard._id ? 'selected' : ''}>
							{formatDate(giftcard.fecha)}
						</td>

						<td className={selected && selected._id === giftcard._id ? 'selected' : ''}>
							{`${giftcard.cliente.nombre} ${giftcard.cliente.apellido}`}
						</td>
						<td className={selected?._id === giftcard._id ? 'selected' : ''}>
							{currencyDisplay(giftcard.precio)}
						</td>
						
						<td className={selected?._id === giftcard._id ? 'selected' : ''}>
							{currencyDisplay(giftcard.saldoConsumido)}
						</td>

						<td className={selected?._id === giftcard._id ? 'selected' : ''}>
							{currencyDisplay(
								giftcard.saldoDisponible || giftcard.precio - giftcard.saldoConsumido
							)}
						</td>
					
				
						<td className={selected?._id === giftcard._id ? 'selected' : ''}>
							{formatDate(giftcard.vencimiento)}
						</td>

						<td
  className={
    new Date(giftcard.vencimiento) < new Date()
      ? "vencida-state"
      : giftcard.saldoConsumido > 0 && giftcard.saldoDisponible > 0
      ? "parcial-state"
      : `${giftcard.estado.toLowerCase()}-state`
  }
>
  {new Date(giftcard.vencimiento) < new Date()
    ? "VENCIDA"
    : giftcard.saldoConsumido > 0 && giftcard.saldoDisponible > 0
    ? "PARCIAL"
    : giftcard.estado}
</td>

					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default GiftcardsTable;
