import React from 'react';
import './Stamp.Module.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import reglamentoPdf from "../../assets/reglamento.pdf"
import DialogSellos from '../dialogSellos/DialogSellos';

function Stamp({ imgStamp }) {
	const [openDialog, setOpenDialog] = React.useState(false);

	const [openDialogBox, setOpenDialogBox] = React.useState(false);

	const [openDialogBoxMarket, setOpenDialogBoxMarket] = React.useState(false);

	const [openDialogGift, setOpenDialogGift] = React.useState(false);

	const [openDialogGiftTop, setOpenDialogGiftTop] = React.useState(false);

	const [openDialogPet, setOpenDialogPet] = React.useState(false);

	const [openDialogPetTop, setOpenDialogPetTop] = React.useState(false);

	const [openDialogCrueltyFree, setOpenDialogCrueltyFree] = React.useState(false);

	const [openDialogVegan, setOpenDialogVegan] = React.useState(false);

	const [openDialogAtencion, setOpenDialogAtencion] = React.useState(false);

	const [openDialogMix, setOpenDialogMix] = React.useState(false);

	const [openDialogOnlyText, setOpenDialogOnlyText] = React.useState(false)



	const imgBeautiBox = {
		url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1715785768/bivwkuuicyobwzyvxiq2.jpg",
		descripcion:"En nuestro Market, enamorate de nuestras #𝘽𝙚𝙖𝙪𝙩𝙞𝘽𝙤𝙭𝙚𝙨. /\n Lleva tu experiencia a la comodidad de casa./\n Contamos con combos de SKIN CARE , MAKEUP, SPA DAY /\n ¡𝘠 𝘵𝘢𝘮𝘣𝘪é𝘯 𝘱𝘰𝘥𝘦𝘴 𝘦𝘭𝘦𝘨𝘪𝘳 𝘭𝘢 𝘰𝘱𝘤𝘪ó𝘯 𝘥𝘦 𝘭𝘭𝘦𝘷𝘢𝘳𝘭𝘢𝘴 𝘱𝘦𝘳𝘴𝘰𝘯𝘢𝘭𝘪𝘻𝘢𝘥𝘢𝘴  !", 
		nombre:"Beautiboxes", 
		subcategoria:"BeautiBoxes"}

		const imgBeautiBoxMarket = {
			url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1718112128/aejxiaxsj7jubsucquj2.jpg",
			descripcion:"En nuestro Market, enamorate de nuestras #𝘽𝙚𝙖𝙪𝙩𝙞𝘽𝙤𝙭𝙚𝙨. /\n Lleva tu experiencia a la comodidad de casa./\n Contamos con combos de SKIN CARE , MAKEUP, SPA DAY /\n ¡𝘠 𝘵𝘢𝘮𝘣𝘪é𝘯 𝘱𝘰𝘥𝘦𝘴 𝘦𝘭𝘦𝘨𝘪𝘳 𝘭𝘢 𝘰𝘱𝘤𝘪ó𝘯 𝘥𝘦 𝘭𝘭𝘦𝘷𝘢𝘳𝘭𝘢𝘴 𝘱𝘦𝘳𝘴𝘰𝘯𝘢𝘭𝘪𝘻𝘢𝘥𝘢𝘴  !", 
			nombre:"#Beautiboxes", 
			subcategoria:"BeautiBoxes"}

	const imgGiftCard =  {
		url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1716652850/ao1eaubkfpsmj7j03fgm.jpg",
		descripcion: "Regala diferente, Regala experiencias únicas. /\n Al momento de elegir sorprender a esa persona especial  tu mejor opción, es una 𝙂𝙞𝙛𝙩 𝘾𝙖𝙧𝙙 𝘽𝙚𝙖𝙪𝙩𝙞𝙦𝙪𝙚𝙧𝙖. /\n Contamos con servicios, productos de nuestro Market y la opción de lujo, regalando tus diamantes. /\n Y ahora podes optar por la versión impresa con retiro en el local  y ahora también con  FORMATO DIGITAL para hacer llegar tu regalito a cualquier parte del mundo! \n #𝘉𝘦𝘢𝘶𝘵𝘪𝘲𝘶𝘦𝘮𝘰𝘴𝘦𝘭𝘮𝘶𝘯𝘥𝘰",
		nombre:"Giftcards", 
		subcategoria:""}

		const imgGiftCardTop =  {
			url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1717854326/zhgxhncv9kppibwjhykk.jpg",
			descripcion: "Regala diferente, Regala experiencias únicas. /\n Al momento de elegir sorprender a esa persona especial  tu mejor opción, es una 𝙂𝙞𝙛𝙩 𝘾𝙖𝙧𝙙 𝘽𝙚𝙖𝙪𝙩𝙞𝙦𝙪𝙚𝙧𝙖. /\n Contamos con servicios, productos de nuestro Market y la opción de lujo, regalando tus diamantes. /\n Y ahora podes optar por la versión impresa con retiro en el local  y ahora también con  FORMATO DIGITAL para hacer llegar tu regalito a cualquier parte del mundo! \n #𝘉𝘦𝘢𝘶𝘵𝘪𝘲𝘶𝘦𝘮𝘰𝘴𝘦𝘭𝘮𝘶𝘯𝘥𝘰",
			nombre:"#Giftcards", 
			subcategoria:""}

		
	const imgPetFriendly =  {
		url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1715785768/ffprymuejhm6qgrpp1fr.jpg",
		descripcion:"En la Beauti los momentos se comparten /\n con todos los integrantes de nuestra familia" ,
		nombre:"Pet Friendly", 
		subcategoria:""}

		const imgPetFriendlyTop =  {
			url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1717854327/lxjedpxv9gtdttma8vl2.jpg",
			descripcion:"En la Beauti los momentos se comparten /\n con todos los integrantes de nuestra familia" ,
			nombre:"Pet Friendly", 
			subcategoria:""}

		const imgVegan =  {
			url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1717854326/ecppkgfc9ph0xbbwavg3.jpg",
			descripcion:"Dentro de nuestro market pueden encontrar productos con el sello “Vegano” /\n este sello certifica que durante los procesos de fabricación y/o desarrollo del producto y sus ingredientes,/\n NO se emplearon productos animales, subproductos o derivados." ,
			nombre:"Vegan", 
			subcategoria:""}


		const imgCruelty =  {
			url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1718111484/lkotdzwr23pi7gsecemj.jpg",
			descripcion: "La mayoría de nuestros productos cuentan con el sello CRUELTY FREE, /\n certificando de esta manera que no están testeando sus productos en seres vivos.", 
			nombre:"Cruelty Free", 
			subcategoria:""}

		const imgAtencion =  {
			url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1719673844/fgrd9ohccocitkwpvepd.jpg",
			descripcion:"En Nuestro Market creemos que tu experiencia debe ser única! /\nPor eso te vamos a presentar a MARKETINA quien te va a acompañar en tu recorrido por nuestro shop, /\n despejando dudas y curiosidades." ,
			nombre:"Atencion Personalizada", 
			subcategoria:""}

		const imgBeautimix =  {
			url:"https://res.cloudinary.com/dlblszxyo/image/upload/v1715786094/jsbje1jgwdwqqmj660ck.jpg",
			descripcion:" 𝙃𝙖𝙮 𝙪𝙣 𝙉𝙖𝙞𝙡 𝘼𝙧𝙩 𝙥𝙖𝙧𝙖 𝙘𝙖𝙙𝙖 𝙗𝙚𝙖𝙪𝙩𝙞𝙦𝙪𝙚𝙧𝙖! /\n Es momento de dejar liberar tu creatividad y reflejar tu estilo único." ,
			nombre:"Beautimix", 
			subcategoria:""}



		const handleOpenDialogOnlyText=()=>{
			openDialogOnlyText === true ?
			setOpenDialogOnlyText (false) :
			setOpenDialogOnlyText(true)
		}
		
	const handleOpenDialogBox = () => {
		openDialogBox===true?
			setOpenDialogBox(false)
			:
			setOpenDialogBox(true);
	};

	const handleOpenDialogBoxMarket = () => {
		openDialogBoxMarket===true?
			setOpenDialogBoxMarket(false)
			:
			setOpenDialogBoxMarket(true);
	};
	const handleCloseDialogOnlyText = () => {
		setOpenDialogOnlyText(false);
};

			
	const handleOpenDialogGift = () => {
		openDialogGift===true?
			setOpenDialogGift(false)
			:
			setOpenDialogGift(true);
	};

				
	const handleOpenDialogGiftTop = () => {
		openDialogGiftTop===true?
			setOpenDialogGiftTop(false)
			:
			setOpenDialogGiftTop(true);
	};

	const handleOpenDialogVegan = () => {
		openDialogVegan===true?
			setOpenDialogVegan(false)
			:
			setOpenDialogVegan(true);
	};

	const handleOpenDialogCruelty = () => {
		openDialogCrueltyFree===true?
			setOpenDialogCrueltyFree(false)
			:
			setOpenDialogCrueltyFree(true);
	};

	const handleOpenDialogAtencion = () => {
		openDialogAtencion===true?
			setOpenDialogAtencion(false)
			:
			setOpenDialogAtencion(true);
	};
				
	const handleOpenDialogPet = () => {
		openDialogPet===true?
			setOpenDialogPet(false)
			:
			setOpenDialogPet(true);
	};

	const handleOpenDialogPetTop = () => {
		openDialogPetTop===true?
			setOpenDialogPetTop(false)
			:
			setOpenDialogPetTop(true);
	};
	const handleOpenDialogMix = () => {
		openDialogMix===true?
			setOpenDialogMix(false)
			:
			setOpenDialogMix(true);
	};
	const navigate = useNavigate()

	const handleOpenDialog = () => {
		openDialog===true?
			setOpenDialog(false)
			:
			setOpenDialog(true);
	};

	const handleCloseDialogBoton = () => {
			setOpenDialog(false);
	};

	const handleCloseDialogBox = () => {
		setOpenDialogBox(false);
};

const handleCloseDialogBoxMarket = () => {
	setOpenDialogBoxMarket(false);
};

const handleCloseDialogGift = () => {
	setOpenDialogGift(false);
};

const handleCloseDialogGiftTop = () => {
	setOpenDialogGiftTop(false);
};

const handleCloseDialogPet = () => {
	setOpenDialogPet(false);
};

const handleCloseDialogPetTop = () => {
	setOpenDialogPetTop(false);
};

const handleCloseDialogMix = () => {
	setOpenDialogMix(false);
};
const handleCloseDialogVegan = () => {
	setOpenDialogVegan(false);
};
const handleCloseDialogCruelty = () => {
	setOpenDialogCrueltyFree(false);
};
const handleCloseDialogAtencion = () => {
	setOpenDialogAtencion(false);
};

	const handleClick = () => {
			if (imgStamp.name === 'Beauti comunidad') {
				navigate(imgStamp.link)
			}else if (imgStamp.name === 'Beautiboxes') {
				handleOpenDialogBox();
			}else if (imgStamp.name === 'Giftcards') {
				handleOpenDialogGift();	
			}else if (imgStamp.name === '#Beautiboxes') {
				handleOpenDialogBoxMarket();	
			}else if (imgStamp.name === '#Giftcards') {
				handleOpenDialogGiftTop();		
			}else if	(imgStamp.name === 'Pet Friendly') {
				handleOpenDialogPet();	
		}else if	(imgStamp.name === '#Pet Friendly') {
				handleOpenDialogPetTop();	
		}else if(imgStamp.name === 'Beautimix') {
			handleOpenDialogMix();	
	}else if (imgStamp.name === "Alianzas") {
					navigate(imgStamp.link)

				}else if(imgStamp.name === 'Productos veganos') {
					handleOpenDialogVegan()
				}else if(imgStamp.name === 'Cruelty free') {
					handleOpenDialogCruelty();
				}else if(imgStamp.name === 'Atención personalizada') {
					handleOpenDialogAtencion();			
				// }else if (imgStamp.name === "Productos veganos" || imgStamp.name === "Cruelty free"||imgStamp.name === "Atención personalizada"  ) {
				// handleOpenDialogOnlyText()
				// }
				}
	};

	const handleDownloadPDF = () => {
			const anchor = document.createElement('a');
			anchor.href = reglamentoPdf;
			anchor.download = 'reglamento.pdf';
			anchor.click();
	};

	return (
			<div style={{ cursor: "pointer" }} className='px-2 bd-placeholder-img rounded-circle testimonios__img'>
					<img   onClick={handleClick} src={imgStamp.image} alt='Productos Veganos' className=' respira bd-placeholder-img rounded-circle stamp__img' />
					<h3>{imgStamp.name}</h3>


							
									<Dialog open={openDialog} onClose={handleCloseDialogBoton}>
											<DialogTitle>Reglamento de Beauti Comunidad</DialogTitle>
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<p>Descargá el archivo para ver el reglamento</p>
											</DialogContent>
											<DialogActions>
													{/* <Button onClick={handleCloseDialogBoton}>Cerrar</Button> */}
													<Button onClick={handleDownloadPDF} color="primary">Descargar PDF</Button>
											</DialogActions>
									</Dialog>

									<Dialog open={openDialogBox} onClose={handleCloseDialogBox}>
											{/* <DialogTitle>Beauti box</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgBeautiBox}/>
											</DialogContent>
									</Dialog>

									<Dialog open={openDialogBoxMarket} onClose={handleCloseDialogBoxMarket}>
											{/* <DialogTitle>Beauti box</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgBeautiBoxMarket}/>
											</DialogContent>
									</Dialog>

									<Dialog open={openDialogVegan} onClose={handleCloseDialogVegan}>
											{/* <DialogTitle>Beauti box</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgVegan}/>
											</DialogContent>
											</Dialog>

											<Dialog open={openDialogCrueltyFree} onClose={handleCloseDialogCruelty}>
											{/* <DialogTitle>Beauti box</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgCruelty}/>
											</DialogContent>
											</Dialog>

											<Dialog open={openDialogAtencion} onClose={handleCloseDialogAtencion}>
											{/* <DialogTitle>Beauti box</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgAtencion}/>
											</DialogContent>
											</Dialog>
									<Dialog open={openDialogGift} onClose={handleCloseDialogGift}>
											{/* <DialogTitle>Gift Cards</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgGiftCard}/>
											</DialogContent>
											{/* <DialogActions>
													<Button onClick={handleCloseDialogGift}>X</Button>
													
											</DialogActions> */}
									</Dialog>

									<Dialog open={openDialogGiftTop} onClose={handleCloseDialogGiftTop}>
											<DialogContent>
													<DialogSellos imagen={imgGiftCardTop}/>
											</DialogContent>
									</Dialog>

									<Dialog open={openDialogPet} onClose={handleCloseDialogPet}>
											{/* <DialogTitle>Gift Cards</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgPetFriendly}/>
											</DialogContent>
											{/* <DialogActions>
													<Button onClick={handleCloseDialogPet}>X</Button>
													
											</DialogActions> */}
									</Dialog>

									<Dialog open={openDialogPetTop} onClose={handleCloseDialogPetTop}>
											{/* <DialogTitle>Gift Cards</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgPetFriendlyTop}/>
											</DialogContent>
											{/* <DialogActions>
													<Button onClick={handleCloseDialogPet}>X</Button>
													
											</DialogActions> */}
									</Dialog>

									<Dialog open={openDialogMix} onClose={handleCloseDialogMix}>
											{/* <DialogTitle>Gift Cards</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<DialogSellos imagen={imgBeautimix}/>
											</DialogContent>
											{/* <DialogActions>
													<Button onClick={handleCloseDialogMix}>X</Button>
													
											</DialogActions> */}
									</Dialog>
							
							
									<Dialog open={openDialogOnlyText} onClose={handleCloseDialogOnlyText}>
											{/* <DialogTitle>Gift Cards</DialogTitle> */}
											<DialogContent>
													{/* Aquí puedes renderizar tu PDF */}
													<Typography>{imgStamp?.text} </Typography>
											</DialogContent>
											{/* <DialogActions>
													<Button onClick={handleCloseDialogOnlyText}>X</Button>
													
											</DialogActions> */}
									</Dialog>
				
			</div>
	);
}

export default Stamp;
