import React from 'react';
import About from '../../components/about/About';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import { Button, Typography, Stack, Box, Backdrop, CircularProgress, BackdropRoot, Paper, Grid } from "@mui/material";
// import NavBar from '../../components/navBar/NavBar';
import NavBarRosa from '../../components/navBarRosa/NavBarRosa.jsx';
import CarouselInfo from '../../components/carouselInfo/CarouselInfo.jsx';
import Promotion from '../../components/promotion/Promotion';
import SocialNetwork from '../../components/socialNetwork/SocialNetwork';
import StampTop from '../../components/stamp/StampTop';
import { useParams, useNavigate } from 'react-router-dom';
import VideoHome from '../../components/videoHome/VideoHome';
import { useSelector } from 'react-redux';
import { getSales } from '../../utils/products/products';
import './LandingInfo.Module.css';
import VideoInfo from '../../components/videoInfo/VideoInfo.jsx';


import MouseOutlinedIcon from '@mui/icons-material/MouseOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import { isEditMode } from '../../utils/users/users';



import ItemLandingInfo1 from '../../components/itemLandingInfo1/ItemLandingInfo1.jsx';
import ItemLandingInfo2 from '../../components/itemLandingInfo2/ItemLandingInfo2.jsx';
import { useMediaQuery } from 'react-responsive';
import { styled } from "@mui/material/styles";
import StampBottom from '../../components/stamp/StampBottom';
import StampLanding from '../../components/stamp/StampLanding.jsx';
import ItemLandingInfo3 from '../../components/itemLandingInfo3/ItemLandingInfo3.jsx';
import ItemLandingInfo4 from '../../components/itemLandingInfo4/ItemLandingInfo4.jsx';
import ItemLandingInfo5 from '../../components/itemLandingInfo5/ItemLandingInfo5.jsx';
import GloboContactoLanding from '../../components/globoContactoLanding/GloboContactoLanding.jsx';




export const LandingInfo = () => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const isMobile = useMediaQuery({ maxWidth: 700 });
	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
const navigate = useNavigate()
	const [sales, setSales] = React.useState([]);
	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const [renderKey, setRenderKey] = React.useState(0);
	const [reloadData, setReloadData] = React.useState(false)


	const [nailsImage, setNailsImage] = React.useState(null)
	const [beautiboxImage, setBeautiboxImage] = React.useState(null)

	const [marketImage, setMarketImage] = React.useState(null)
	const [workshopImage, setWorkshopImage] = React.useState(null)
	const [eventsImage, setEventsImage] = React.useState(null)
	const [giftcardImage, setGiftcardImage] = React.useState(null)
	const [teamImage, setTeamImage] = React.useState(null)
	const [reloadExecuted, setReloadExecuted] = React.useState(false);





	React.useEffect(() => {
	
		const cargarImagenesInfo = async () => {
			
			const { data: nailsData } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=INFO_NAILS`
			);
			setNailsImage(nailsData.imagenes[0]);
	
			const { data: beautiboxData } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=INFO_BEAUTIBOX`
			);
			setBeautiboxImage(beautiboxData.imagenes[0]);
	
			const { data: marketData } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=INFO_MARKET`
			);
			setMarketImage(marketData.imagenes[0]);

			const { data: eventsData } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=INFO_EVENTOS`
			);
			setEventsImage(eventsData.imagenes[0]);
	
			const { data: workshopData } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=INFO_WORKSHOP`
			);
			setWorkshopImage(workshopData.imagenes[0]);

			const { data: giftcardData } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=INFO_MINIBEAUTI`
			);
			setGiftcardImage(giftcardData.imagenes[0]);
			
			const { data: teamData } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=INFO_TEAM`
			);
			setTeamImage(teamData.imagenes[0]);
			
			
		};
	
		cargarImagenesInfo();
	
		window.addEventListener('actualizar-producto', cargarImagenesInfo);
	}, [renderKey, reloadData]);
	
	React.useEffect(() => {
    if (reloadData && !reloadExecuted) {
        window.location.reload(); // Recargar la página solo si reloadData cambia y no se ha ejecutado un recargo aún
        setReloadExecuted(true); // Marcar que ya se ejecutó el recargo
				setTimeout(() => {
					setReloadExecuted(false); // Restablecer reloadExecuted después de un tiempo
			}, 1000); // Cambia
    }
}, [reloadData, reloadExecuted]);

	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_LANDING`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagenes();
		window.addEventListener('actualizar-producto', cargarImagenes);
	}, [reloadData]);

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}

	React.useEffect(() => {
		toast.remove();
		async function getOfertas() {
			setSales(await getSales());
			setLoader(false);
		}
		getOfertas();
		window.addEventListener('actualizar-producto', getOfertas);
		return () => {
			window.removeEventListener('actualizar-producto', getOfertas);
		};
	}, [reloadData]);

	// React.useEffect(() => {
	// 	// Ejecuta la función para comprobar el modo de edición
	// 	isEditMode(user && user);
	// }, []);

	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<div className='fondoLanding' >
					<header>
						<NavBarRosa />
					</header>
				
					{isSuperAdmin && (
								<div style={{zIndex:500}} className='fondohome' onClick={() => handleOpenWidget()}>
									<i className='bi bi-pencil'></i>
									cambiar fondo
								</div>
							)}
					<main>
					<div className="background-image" 
					style={{
						  backgroundImage: `url(${image?.url})`,
						//  backgroundColor:"#fffff"
						 }}>

					
						
							<Toaster
								position='bottom-left'
								reverseOrder={false}
								toastOptions={{ className: 'fondoToast' }}
							/>

								<CarouselInfo/>
								<Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
								<Typography style={{fontFamily:"Montserrat", color:"#5c5c5c", fontSize:!isMobile?"58px":"30px",marginTop:isMobile?"":"5vh"}} variant="h4" className="fade-in">
									La Beautiquería
								</Typography>
								<Typography style={{fontFamily:"Montserrat", color:"#5c5c5c", fontSize:!isMobile?"25px":"14px"}}variant="h6" className="fade-in2">
									Nails, Beauty & Market
								</Typography>


								<Box sx={{display:"flex",flexDirection:"column",alignItems:"center", paddingLeft:isMobile?"5vw":"10vw", paddingRight:isMobile?"8vw":"12vw"}}className="fade-in3">
							<Typography style={{
								fontFamily:"Montserrat", 
								color:"#20563f", 
				
								fontStyle:"oblique",
								fontWeight:500,
								fontSize:!isMobile?"18px":"12px",
								marginTop:isMobile?"3vh":"3vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
									La esencia beautiquera define a una persona divertida, fuerte e independiente.
								</Typography>
								<Typography style={{
										fontFamily:"Montserrat", 
										color:"#20563f", 
							
										fontStyle:"oblique",
										fontWeight:500,
										fontSize:!isMobile?"18px":"12px",
										 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
									Aquella que puede ser simple y elegante cuando se lo proponga. 
									
								</Typography>
								<Typography style={{
										fontFamily:"Montserrat", 
										color:"#20563f", 
							
										fontStyle:"oblique",
										fontWeight:500,
										fontSize:!isMobile?"18px":"12px",
										 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
									Tiene como principio básico en la vida disfrutar de momentos auténticos y reales...
									
								</Typography>

								<Typography style={{
							fontFamily:"Montserrat", 
							color:"#20563f", 
						
							fontStyle:"oblique",
							fontWeight:500,
							fontSize:!isMobile?"18px":"12px",
							 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
			Persona bella por naturaleza y cuando hablamos de belleza,
								</Typography>
								<Typography style={{
							fontFamily:"Montserrat", 
							color:"#20563f", 
						
							fontStyle:"oblique",
							fontWeight:500,
							fontSize:!isMobile?"18px":"12px",
							 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
			 es la que está más allá de lo que a simple vista se ve...
								</Typography>
								<Typography style={{
										fontFamily:"Montserrat", 
										color:"#20563f", 
							
										fontStyle:"oblique",
										fontWeight:500,
										fontSize:!isMobile?"18px":"12px",
										 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
									Esa belleza cómplice de risas, que traspasa pantallas, provoca empatía,
								</Typography>
								<Typography style={{
										fontFamily:"Montserrat", 
										color:"#20563f", 
							
										fontStyle:"oblique",
										fontWeight:500,
										fontSize:!isMobile?"18px":"12px",
										 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
								despierta admiración y genera en el otro un hermoso sentido de familia.
								</Typography>

								<Typography style={{
							fontFamily:"Montserrat", 
							color:"#20563f", 
		
							fontStyle:"oblique",
							fontWeight:500,
							fontSize:!isMobile?"18px":"12px",
							 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
									Podríamos compararla con un diamante en bruto, cuyo potencial
								</Typography>
								<Typography style={{
							fontFamily:"Montserrat", 
							color:"#20563f", 
		
							fontStyle:"oblique",
							fontWeight:500,
							fontSize:!isMobile?"18px":"12px",
							 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
						está a la espera de liberar el brillo ÚNICO e INIGUALABLE que tienen.
								</Typography>
								<Typography style={{
						fontFamily:"Montserrat", 
						color:"#20563f", 
						height:"60px",
						fontStyle:"oblique",
						fontWeight:500,
						fontSize:!isMobile?"18px":"12px",
						 marginTop:isMobile?"1vh":"1vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
									Es momento de...
								</Typography>
								<Typography style={{
								fontFamily:"Dancing", 
								color:"#20563f", 
								// height:"60px",
								fontWeight:550,
								fontSize:!isMobile?"70px":"45px",
								 marginTop:isMobile?"-4vh":"-2vh",
								// textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #7e0374, 0 0 30px #7e0374, 0 0 40px #7e0374, 0 0 55px #7e0374, 0 0 75px #7e0374"
							}}  
								// className="neon"
								>
									Liberar tu belleza
								</Typography>
								</Box>
							</Box>
					
						
							<Box >
								<div className="scroll-indicator">
          				<KeyboardArrowDownIcon className="bounce" />
       					</div>
							</Box>
						{
						nailsImage?.descripcion && (
							<ItemLandingInfo1 imagen={nailsImage} reloadData={reloadData} setReloadData={setReloadData}/>
							)
						}							
						<Box  sx={{height:!isMobile ? "25vh" : "8vh"}}>
							{isMobile &&(
								<div className="scroll-indicator2">
								<KeyboardArrowDownIcon className="bounce" />
							 </div>
							)}
						</Box>
						
						{beautiboxImage && (
						<ItemLandingInfo2 imagen={beautiboxImage}reloadData={reloadData} setReloadData={setReloadData}/>
						)}
						<Box  sx={{height:!isMobile ? "25vh" : "8vh"}}>
							{isMobile &&(
								<div className="scroll-indicator2">
								<KeyboardArrowDownIcon className="bounce" />
							 </div>
							)}
						</Box>

						<section style={{backgroundColor:"#e9ced281"}}>
							<StampLanding/>
						</section>
						</div>

						
						<div className="reveal-image-container">
							<div className="reveal-image-content">
								{/* Botón "Conocé nuestro Market" */}
								<Button onClick={()=>navigate("/markethome")} variant="contained" style={{cursor:"pointer" ,marginTop:"-100px",backgroundColor:"#20563f", width:!isMobile?"30%":"100%", height:"60px",fontWeight:600, fontFamily:"Montserrat"}}>
									IR AL MARKET
								</Button>

								{/* Contenido que anula la imagen de fondo del contenedor general */}
								<div className="reveal-content" style={{ height: isMobile ? "400px" : "400", backgroundImage: "none" }}></div>
							</div>

							{/* Imagen fija que se revelará */}
							<img
								style={{ marginTop: isMobile ? "20vh" : "18vh", height: isMobile ? "70%" : "100%" }}
								src={marketImage?.url}
								alt="Imagen Fija"
								className="fixed-background-image"
							/>
						</div>

					<div className="background-image" 
					style={{ 
						backgroundImage: `url(${image?.url})` 
						// backgroundColor:"#ffff"
					}}>

					<section style={{backgroundColor:"#e9ced281"}}>
							<SocialNetwork/>
						</section>

						<Box  sx={{height:!isMobile ? "25vh" : "8vh"}}>
							{isMobile &&(
								<div className="scroll-indicator2">
								<KeyboardArrowDownIcon className="bounce" />
							 </div>
							)}
						</Box>
											
			
						{giftcardImage && (
						<ItemLandingInfo4 imagen={giftcardImage}reloadData={reloadData} setReloadData={setReloadData}/>
						)}
									<Box  sx={{height:!isMobile ? "25vh" : "8vh"}}>
							{isMobile &&(
								<div className="scroll-indicator2">
								<KeyboardArrowDownIcon className="bounce" />
							 </div>
							)}
						</Box>
						{
						eventsImage && (
							<ItemLandingInfo5 imagen={eventsImage}reloadData={reloadData} setReloadData={setReloadData}/>
							)}	
				<Box  sx={{height:!isMobile ? "25vh" : "8vh"}}>
							{isMobile &&(
								<div className="scroll-indicator2">
								<KeyboardArrowDownIcon className="bounce" />
							 </div>
							)}
						</Box>
							{
						workshopImage && (
							<ItemLandingInfo3 imagen={workshopImage}reloadData={reloadData} setReloadData={setReloadData}/>
							)
						}			
									<Box  sx={{height:!isMobile ? "25vh" : "8vh"}}>
							{/* {isMobile &&(
								<div className="scroll-indicator2">
								<KeyboardArrowDownIcon className="bounce" />
							 </div>
							)} */}
						</Box>
              
							<GloboContactoLanding />
							<VideoInfo />
						
						</div>
					</main>
					<footer>
						<Footer />
					</footer>
				</div>
			)}
		</>
	);
}

