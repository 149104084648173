/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import axios from 'axios';
import LoginNavBar from '../../components/loginNavBar/LoginNavBar';
import { validateCreateAccount } from '../../utils/validate/validate';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import { useNavigate, Link } from 'react-router-dom';
import './CreateAccount.Module.css';

function CreateAccount() {
	const uri = process.env.REACT_APP_BACK_URL;
	const navigate = useNavigate();

	const [errors, setErrors] = React.useState({});
	const [userInfo, setUserInfo] = React.useState({
		nombre: '',
		apellido: '',
		correo: '',
		password: '',
		dni: 0,
		cumpleaños: '',
		telefono: 0,
		direccion: '',
		redes: '',
		password2: '',
		role: 'USER_ROLE',
	});

	//mostrar div inputs no obligatorios
	const [showInputs, setShowInputs] = React.useState(false);
	// const showDivInputs = () => {
	// 	if (
	// 		!errors.nombre &&
	// 		!errors.apellido &&
	// 		!errors.correo &&
	// 		!errors.password &&
	// 		!errors.password2
	// 	) {
	// 		setShowInputs(true);
	// 	}
	// };

	// Botón de ojo para la contraseña
	const [openEye, setOpenEye] = React.useState(true);
	const toggleEye = () => {
		setOpenEye(!openEye);
	};

	const handleChange = (e) => {
		e.preventDefault();
		setUserInfo({
			...userInfo,
			[e.target.name]: e.target.value,
		});
		setErrors(
			validateCreateAccount({
				...userInfo,
				[e.target.name]: e.target.value,
			})
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (errors.correo || errors.password || errors.nombre || errors.apellido || errors.password2) {
			return toast.error('Revisa los campos obligatorios');
		} else {
			try {
				const { password2, ...resto } = userInfo;
				const { data } = await axios.post(`${uri}/api/usuarios`, resto);

				data && toast.success(`${data.msg}`, { duration: 3000 });
				setTimeout(() => {
					navigate('/iniciarSesion');
				}, 3000);
			} catch (error) {
				toast.error(
					`Ha ocurrido un error en la creación del usuario. Intenta nuevamente más tarde.`,
					{
						duration: 3000,
					}
				);
			}
		}
	};

	React.useEffect(() => {
		if (
			userInfo.nombre &&
			userInfo.apellido &&
			userInfo.correo &&
			userInfo.dni &&
			userInfo.password &&
			userInfo.password2 &&
			!errors.nombre &&
			!errors.apellido &&
			!errors.correo &&
			!errors.password &&
			!errors.password2
		) {
			setShowInputs(true);
		}
	}, [errors]);

	return (
		<div className='fondoCreate'>
			<header>
				<LoginNavBar />
			</header>
			<main>
				<Toaster
					position='bottom-left'
					reverseOrder={false}
					toastOptions={{ className: 'fondoToast' }}
				/>
				<h2>Crea tu cuenta</h2>
				<h4>Por favor, ingresá tus datos</h4>
				<div className='container containerCreate p-2'>
					<form action='' onSubmit={handleSubmit} className=''>
						<div className='d-flex row displayResponsive'>
							<section className='obligatorios col-md'>
								<div className='mt-3 px-3 py-1 inputtt'>
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										type='text'
										name='nombre'
										placeholder='Nombre *'
									/>
									{errors.nombre ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.nombre}
										</p>
									) : (
										<br></br>
									)}
								</div>
								<div className=' px-3 py-1 inputtt'>
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										type='text'
										name='apellido'
										placeholder='Apellido *'
									/>
									{errors.apellido ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.apellido}
										</p>
									) : (
										<br></br>
									)}
								</div>
								<div className='mt-3 px-3 py-1 inputtt'>
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										type='text'
										name='dni'
										placeholder='DNI *'
									/>
									{errors.dni ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.dni}
										</p>
									) : (
										<br></br>
									)}
								</div>
								<div className=' px-3 py-1 inputtt'>
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										name='correo'
										type='text'
										placeholder='Correo Electrónico *'
									/>
									{errors.correo ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.correo}
										</p>
									) : (
										<br></br>
									)}
								</div>

								<div className=' px-3 py-1 contraseña inputtt '>
									<div className='input-group'>
										<input
											autoComplete='off'
											onChange={handleChange}
											className='form-control shadow py-3'
											name='password'
											type={openEye ? 'password' : 'text'}
											placeholder='Contraseña *'
										/>
										<div className='input-group-apend'>
											<span className='input-group-text shadow py-3'>
												{openEye ? (
													<abbr title='Mostrar contraseña'>
														<i onClick={toggleEye} className='bi bi-eye-slash'></i>
													</abbr>
												) : (
													<abbr title='Ocultar contraseña'>
														<i onClick={toggleEye} className='bi bi-eye'></i>
													</abbr>
												)}
											</span>
										</div>
									</div>
									{errors.password ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.password}
										</p>
									) : (
										<br></br>
									)}
								</div>

								<div className=' px-3 py-1 contraseña inputtt '>
									<input
										autoComplete='off'
										onChange={handleChange}
										className='form-control shadow py-3'
										name='password2'
										type='password'
										placeholder='Confirmar contraseña *'
									/>
									{errors.password2 ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.password2}
										</p>
									) : (
										<br></br>
									)}
								</div>
								<h5 className='mb-3 px-3 textoCamposObligatorios'>
									*Estos campos son obligatorios
								</h5>
							</section>

							<section className={showInputs ? 'noobligatorios col-md' : 'd-none'}>
								<div className='mt-3 px-3 py-1 inputtt'>
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										type='number'
										name='telefono'
										placeholder='Teléfono'
									/>
									{errors.telefono ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.telefono}
										</p>
									) : (
										<br></br>
									)}
								</div>

								<div className='px-3 py-1 inputtt'>
									<label htmlFor=''>Cumpleaños</label>
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										type='date'
										name='cumpleaños'
										placeholder='Cumpleaños'
									/>
									{errors.cumpleaños ? (
										<p
											className='pt-1'
											style={{
												color: 'rgba(255, 0, 0, 0.719)',
												height: '20px',
												textAlign: 'left',
											}}
										>
											{errors.cumpleaños}
										</p>
									) : (
										<br></br>
									)}
								</div>

								<div className=' px-3 py-1 inputtt'>
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										type='text'
										name='direccion'
										placeholder='Dirección'
									/>
									{errors.direccion ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.direccion}
										</p>
									) : (
										<br></br>
									)}
								</div>
								<div className=' px-3 py-1 inputtt'>
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										type='text'
										name='redes'
										placeholder='Instagram'
									/>
									{errors.redes ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.redes}
										</p>
									) : (
										<br></br>
									)}
								</div>
								<h5 className='mb-3 px-3 textoCamposObligatorios'>
									Querés una atención más personalizada? Completá estos campos.
								</h5>
							</section>
						</div>
						<div className='botonIngresar px-5 py-3 mt-5'>
							<button type='submit' className='btn btn-secondary py-3'>
								CREAR CUENTA
							</button>
						</div>
					</form>
					<h4 className='h4CreateAccount'>
						Ya estás registrado?{' '}
						<Link className='linkLogin' to='/iniciarSesion'>
							Ingresá
						</Link>
					</h4>
				</div>
			</main>
		</div>
	);
}

export default CreateAccount;
