import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalConfirmation.Module.css';

function ModalConfirmation({ show, title, message, onConfirm, onCancel }) {
	return (
		<Modal className='containerConfirmation' show={show} onHide={onCancel} centered>
			<Modal.Header className='headerModalConfirmation' closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{message}
				<div className='divMagico py-2'>
					<Button className='buttonSecondary' variant='secondary' onClick={onCancel}>
						Cancelar
					</Button>
					<Button className='botonModalConfirmacion' variant='primary' onClick={onConfirm}>
						Confirmar
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default ModalConfirmation;
