// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalNewClient.Module.css';
import { formatDate } from '../../../utils/validate/validate';
import toast from 'react-hot-toast';
import { validateCreateAccount } from '../../../utils/validate/validate';
import axios from 'axios';

// Definir el componente EditModal
const ModalNewClient = ({ show, handleClose }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [errors, setErrors] = React.useState({});
	// Manejar el estado del formulario en el modal
	const [formData, setFormData] = React.useState({
		correo: '',
		password: 'Beauti1234',
		password2:'Beauti1234',
		nombre: '',
		apellido: '',
		dni: '',
		cumpleaños: null,
		telefono: '',
		direccion: '',
		redes: '',
		beautiId: '',
		notas: '',
		puntosAcumulados: 0,
		role: 'USER_ROLE',
	});

	  // Manejar cambios en los campos del formulario
		const handleChange = (e) => {
			e.preventDefault();
			const { name, value, type, checked } = e.target;
	
			if (type === 'checkbox') {
				// Manejar cambios en el checkbox
				setFormData((prevData) => ({
					...prevData,
					[name]: checked ? 'STAFF_ROLE' : 'USER_ROLE',
				}));
			} else if (name === 'cumpleaños' && value !== '') {
				// Formatear la fecha al formato "yyyy-MM-dd"
				const dateParts = value.split('-');
				const formattedDate = new Date(
					parseInt(dateParts[0]),
					parseInt(dateParts[1]) - 1,
					parseInt(dateParts[2])
				)
					.toISOString()
					.split('T')[0];
	
				setFormData((prevData) => ({
					...prevData,
					cumpleaños: formattedDate,
				}));
			} else {
				setFormData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
	
			setErrors(
				validateCreateAccount({
					...formData,
					[name]: value,
				})
			);
		};
	// Manejar el evento de guardar los cambios en el modal
	const handleModalSave = async (data) => {
		if (errors.nombre || errors.apellido || errors.password2) {
			return toast.error('Revisa los campos obligatorios');
		} else {
			try {
				const { password2, ...resto } = data;
				await axios.post(`${process.env.REACT_APP_BACK_URL}/api/usuarios`, resto, config);
				// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
				const event = new Event('actualizar-cliente');
				window.dispatchEvent(event);
				toast.success('Usuario creado con éxito');

				setFormData({
					email: '',
					nombre: '',
					apellido: '',
					dni: '',
					cumpleaños: '',
					telefono: '',
					direccion: '',
					redes: '',
					estado: '',
					beautiId: '',
					notas: '',
					puntosAcumulados:0
				});
				handleClose2();
			} catch (error) {
				const errorMessage = error?.response?.data?.msg
				console.log(error);
				toast.error(errorMessage);
			}
		}
	};

	const [openEye, setOpenEye] = React.useState(true);
	const toggleEye = () => {
		setOpenEye(!openEye);
	};

	const handleClose2 = () => {
		// Limpiar el estado aquí
		setFormData({
			correo: '',
			password: 'Beauti1234',
			password2: 'Beauti1234',
			nombre: '',
			apellido: '',
			dni: '',
			cumpleaños: '',
			telefono: '',
			direccion: '',
			redes: '',
			beautiId: '',
			notas: '',
			puntosAcumulados: 0,
			role: 'USER_ROLE',
		});
	handleClose()
		// Cerrar el modal
		
	};
	

	return (
		<Modal show={show} onHide={handleClose2} backdrop='static'>
			<Modal.Header className='headerModalHistorial headerNewClient' closeButton>
				<Modal.Title>Crear cliente</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer newClientContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}
				<div>
					<label htmlFor='nombre'>Nombre:  <strong style={{color:"#4472c4"}}>**Campo obligatorio</strong></label>

					<input
						className='form-control'
						type='text'
						name='nombre'
						value={formData?.nombre}
						onChange={handleChange}
						placeholder='Nombre'
					/>
				</div>
				{!formData.nombre ? (
					<p
						className='pt-1'
						style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
					>
						"Debes ingresar un Nombre para crear un usuario"
					</p>
				) : (
					<br></br>
				)}
				<div>
					<label htmlFor='nombre'>Apellido: <strong style={{color:"#4472c4"}}>**Campo obligatorio</strong></label>

					<input
						className='form-control'
						type='text'
						name='apellido'
						value={formData?.apellido}
						onChange={handleChange}
						placeholder='Apellido'
					/>
				</div>
				{!formData?.apellido ? (
					<p
						className='pt-1'
						style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
					>
						"Debes ingresar un Apellido para crear un usuario"
					</p>
				) : (
					<br></br>
				)}

				<div>
					<label htmlFor='descripcion'>DNI: <strong style={{color:"#4472c4"}}>**Campo obligatorio</strong></label>
					<input
						className='form-control'
						type='text'
						name='dni'
						value={formData?.dni}
						onChange={handleChange}
						placeholder='DNI'
					/>
				</div>
				{!formData.dni ? (
					<p
						className='pt-1'
						style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
					>
						"Debes ingresar un DNI para crear un usuario"
					</p>
				) : (
					<br></br>
				)}
				<div>
					<label htmlFor='nombre'>Correo Electrónico:</label>

					<input
						className='form-control'
						type='text'
						name='correo'
						value={formData?.correo}
						onChange={handleChange}
						placeholder='Correo'
					/>
				</div>

				<div>
					<label htmlFor='nombre'>Contraseña:</label>
					<div className='input-group'>
						<input
							autoComplete='off'
							className='form-control'
							type={openEye ? 'password' : 'text'}
							name='password'
							value={formData?.password}
							onChange={handleChange}
							placeholder='Ingresar contraseña'
						/>
						<div className='input-group-apend'>
							<span className='input-group-text shadow py-3'>
								{openEye ? (
									<abbr title='Mostrar contraseña'>
										<i onClick={toggleEye} className='bi bi-eye-slash'></i>
									</abbr>
								) : (
									<abbr title='Ocultar contraseña'>
										<i onClick={toggleEye} className='bi bi-eye'></i>
									</abbr>
								)}
							</span>
						</div>
					</div>
					{errors.password ? (
						<p
							className='pt-1'
							style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
						>
							{errors.password}
						</p>
					) : (
						<br></br>
					)}
				</div>

				<div>
					<label htmlFor='nombre'>Contraseña:</label>
					<div className='input-group'>
						<input
							autoComplete='off'
							className='form-control'
							type={openEye ? 'password' : 'text'}
							name='password2'
							value={formData?.password2}
							onChange={handleChange}
							placeholder='Confirmar Contraseña'
						/>
						<div className='input-group-apend'>
							<span className='input-group-text shadow py-3'>
								{openEye ? (
									<abbr title='Mostrar contraseña'>
										<i onClick={toggleEye} className='bi bi-eye-slash'></i>
									</abbr>
								) : (
									<abbr title='Ocultar contraseña'>
										<i onClick={toggleEye} className='bi bi-eye'></i>
									</abbr>
								)}
							</span>
						</div>
					</div>
					{errors.password2 ? (
						<p
							className='pt-1'
							style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
						>
							{errors.password2}
						</p>
					) : (
						<br></br>
					)}
				</div>

			

				<div>
					<label htmlFor='cumpleaños'>Cumpleaños:</label>
					<input
						className='form-control'
						type='date'
						name='cumpleaños'
						value={formData?.cumpleaños}
						onChange={handleChange}
						
					/>
				</div>

				<div>
					<label htmlFor='telefono'>Telefono:</label>
					<input
						className='form-control'
						type='text'
						name='telefono'
						value={formData?.telefono}
						onChange={handleChange}
						placeholder='Telefono'
					/>
				</div>

				<div>
					<label htmlFor='direccion'>Direccion:</label>
					<input
						className='form-control'
						type='text'
						name='direccion'
						value={formData?.direccion}
						onChange={handleChange}
						placeholder='Direccion'
					></input>
				</div>

				<div>
					<label htmlFor='redes'>Redes:</label>
					<input
						className='form-control'
						type='text'
						name='redes'
						value={formData?.redes}
						onChange={handleChange}
						placeholder='Redes'
					></input>
				</div>

				<div>
					<label htmlFor='notas'>Notas:</label>
					<input
						className='form-control'
						type='text'
						name='notas'
						value={formData?.notas}
						onChange={handleChange}
						placeholder='Notas'
					></input>
				</div>

				<div>
          <label htmlFor='role'>El usuario forma parte del staff?</label>
          <input
            type='checkbox'
            name='role'
            checked={formData?.role === 'STAFF_ROLE'}
            onChange={handleChange}
          />
        </div>

				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleModalSave(formData)}
					>
						Guardar Cambios
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalNewClient;
