import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import './UsersTable.Module.css';

const UsersTable = ({ users, onUserClick }) => {
	const [selected, setSelected] = useState(null);

	const handleUserClick = (user) => {
		setSelected(user);
		onUserClick(user);
	};

	return (
		<Table responsive="sm" striped bordered hover>
			<thead>
				<tr>
					<th>Nombre</th>
					<th>Apellido</th>
					<th>DNI</th>
					<th>Notas</th>
				</tr>
			</thead>
			<tbody >
				{users?.map((user) => (
					<tr key={user._id} onClick={() => handleUserClick(user)}>
						<td className={selected?._id === user._id ? 'selected' : ''}>
							{user?.nombre.toUpperCase()}
						</td>
						<td className={selected?._id === user._id ? 'selected' : ''}>
							{user?.apellido.toUpperCase()}
						</td>
						<td className={selected?._id === user._id ? 'selected' : ''}>
							{user?.dni}
						</td>

						<td className={selected?._id === user._id ? 'selected' : ''}>
							{user?.notas}
						</td>
						
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default UsersTable;
