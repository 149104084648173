import axios from 'axios';

const uri = process.env.REACT_APP_BACK_URL;

export const getPurchases = async (desde) => {
	const { data } = await axios.get(`${uri}/api/compras?desde=${desde}`);
	return data;
};

export const getWebPurchases = async (desde) => {
	const { data } = await axios.get(`${uri}/api/compras/web?desde=${desde}`);
	return data;
};

export const searchAllPurchases = async (termino, desde) => {
	const { data } = await axios.get(`${uri}/api/buscar/compras/${termino}?desde=${desde}`);
	return data;
};

export const searchAllWebPurchases = async (termino, desde) => {
	const { data } = await axios.get(`${uri}/api/buscar/comprasweb/${termino}?desde=${desde}`);
	return data;
};

export const getMediosPago = async (desde, limit) => {
	const { data } = await axios.get(`${uri}/api/mediosPago?desde=${desde}&limit=${limit}`);
	return data;
};

export const validateTotal = (subtotal, mediosPago) => {
	// Calcular la suma de los montos de los medios de pago
	const totalMedios = mediosPago.reduce((total, medio) => total + medio.monto, 0);

	// Comparar el subtotal con el total de los medios de pago y devolver true o false
	return subtotal === totalMedios;
};

export const sumaMedios = (mediosPago) => {
	// Calcular la suma de los montos de los medios de pago
	const totalMedios = mediosPago.reduce((total, medio) => total + medio.monto, 0);

	// Comparar el subtotal con el total de los medios de pago y devolver true o false
	return totalMedios;
};
export const formatProductosSeleccionados = (productosSeleccionados) => {
	const detalle = [];

	for (const key in productosSeleccionados) {
		if (productosSeleccionados.hasOwnProperty(key)) {
			const producto = productosSeleccionados[key];
			const detalleProducto = {
				nombre: producto.nombre,
				id: producto._id,
				precioEfectivo: producto.precioEfectivo,
				precioOferta: producto.precioOferta,
				precioTarjeta: producto.precioTarjeta,
				precioElegido: Number(producto.precioElegido),
				cantidad: producto.cantidad,
				puntos: producto.puntos,
			};

			// Verifica si varianteId no es una cadena vacía antes de agregarlo
			if (producto.varianteId !== '') {
				detalleProducto.varianteId = producto.varianteId;
			}

			detalle.push(detalleProducto);
		}
	}

	return {
		detalle,
	};
};
