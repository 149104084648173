import axios from 'axios';

const uri = process.env.REACT_APP_BACK_URL;

export const getAliances = async (desde) => {
	const { data } = await axios.get(`${uri}/api/alianzas?desde=${desde}`);
	return data;
};

export const getAllAliances = async (desde) => {
	const { data } = await axios.get(`${uri}/api/alianzas?desde=${desde}&limit=50`);
	return data;
};

export const searchAllAliances = async (termino, desde) => {
	const { data } = await axios.get(`${uri}/api/buscar/alianzas/${termino}?desde=${desde}`);
	return data;
};
