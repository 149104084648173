import React from 'react';
import NavBar from '../../components/navBar/NavBar';
import NavBarRosa from "../../components/navBarRosa/NavBarRosa";
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom';
import './Contact.Module.css';
import toast from 'react-hot-toast';
import axios from 'axios';
import Loader from '../../components/loader/Loader';

import { useSelector } from 'react-redux';
import { Button , Typography, Box} from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Contact() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const isMobileScreen = useMediaQuery({ maxWidth: 600 }); // Cambio de imagen en pantallas pequeñas

	const [image, setImage] = React.useState('');
	const [teamImage, setTeamImage] = React.useState("")

	const [loader, setLoader] = React.useState(true);

	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);

	const numero = "+543416242464";
	const nombre = user?.nombre || "Usuario"
	const apellido= user?.apellido || "No registrado"
	const mensajeCompra = `Hola! soy ${nombre} ${apellido} y quiero formar parte del team!!! `;
	const encodedMensaje = encodeURIComponent(mensajeCompra);
	const whatsappLink = `https://api.whatsapp.com/send?phone=${numero}&text=${encodedMensaje}`;

	const handleWhatsapp = () => {
		window.open(whatsappLink, "_blank");
	};

	// React.useEffect(() => {
	// 	const cargarImagenes = async () => {
	// 		const { data } = await axios.get(
	// 			`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=PREV_CATALOGUE`
	// 		);
	// 		setImage(data.imagenes);

	// 		setLoader(false);
	// 	};
	// 	cargarImagenes();
	// }, []);

	React.useEffect(() => {
		const cargarImagen = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_LANDING`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagen();
	}, [renderKey]);

	React.useEffect(()=>{
const cargarTeamImage = async() => {
	const { data} = await axios.get(
		`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=INFO_TEAM`
	);
	setTeamImage(data?.imagenes[0]);
	
}
cargarTeamImage()

	},[])

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}

	return (
		<>
			{!image && !teamImage ? (
				<Loader />
			) : (
				<div className='fondoContact' style={{ backgroundImage: `url(${image?.url})` }}>
					<header>
						{/* <NavBar /> */}
						<NavBarRosa/>
					</header>
					<main className='mainContact py-5'>
						{isSuperAdmin && (
							<div className='fondoPrevButton' onClick={() => handleOpenWidget()}>
								<i className='bi bi-pencil'></i>
								cambiar fondo
							</div>
						)}

<Typography style={{fontFamily:"Montserrat", color:"#5c5c5c", fontSize:!isMobileScreen?"28px":"20px",marginTop:isMobileScreen?"10vh":"5vh"}} variant="h4" className="fade-in-contact">
						¿Dónde encontrarnos?
								</Typography>
						<div style={{width:isMobileScreen?"100vw":"70vw"}} className='container contactoContainer py-4 shadow'>
							{/* <div className='d-flex justify-content-center align-items-center p-4 mb-4 rounded  contactoContainer2'>
						<h2 className='fw-normal'>Contactame y Hablamos</h2>
					</div> */}
						
							{/* <div className='contactoMedios mb-4'>
								<p>341 624-2464</p>
								<p>
									Atención de Lunes a Viernes de 9:00hs a 19:00hs y Sábados de 09:00hs a 14:00hs
								</p>
							</div> */}
							{/* <div className='contactoMedios mb-4'>
								<p style={{fontFamily:"montserrat", fontSize:"18px"}}>Rioja 1917, Rosario - Santa Fe</p>

							</div> */}
						
					
							<div className='contactoMapa'>
								<iframe
									title='mapa'
									src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.2036168518994!2d-60.65233098783957!3d-32.94563317172896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b7abe22ab37481%3A0x91688815b66d30e0!2sLa%20Beautiqueria%20Nails%2C%20Beauty%20%26%20Market!5e0!3m2!1ses-419!2sar!4v1690300646710!5m2!1ses-419!2sar'
									loading='lazy'
								></iframe>
							</div>
						
							<Typography style={{fontFamily:"Montserrat", color:"#5c5c5c", fontSize:!isMobileScreen?"18px":"14px", marginBottom:"15px"}} variant="h4" className="fade-in-contact">
						Rioja 1917, Rosario
								</Typography>
						</div>

						<Typography style={{fontFamily:"Montserrat", color:"#5c5c5c", fontSize:!isMobileScreen?"28px":"20px",marginTop:isMobileScreen?"5vh":"5vh"}} variant="h4" className="fade-in-contact">
						¿Querés formar parte de nuestro team?
								</Typography>
								<Typography style={{fontFamily:"Montserrat", color:"#5c5c5c", fontSize:!isMobileScreen?"18px":"14px",marginBottom:"15px"}} variant="h4" className="fade-in-contact">
						Clickeá el botón de abajo
								</Typography>

						<Box >
								<div className="scroll-indicator-contact">
          				<KeyboardArrowDownIcon className="bounce" />
       					</div>
							</Box>

						<Box className="buttonTeam" onClick={()=>handleWhatsapp()} style={{cursor:"pointer", borderRadius:"8px" ,width:isMobileScreen?"100vw":"70vw"}} >

								<img style={{borderRadius:"8px 8px 0 0",width:"100%", height:"300px", objectFit:"cover"}} src={teamImage?.url} alt='beauti team pic' />
								<Box  variant="contained" style={{borderRadius:"0 0 8px 8px",display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center", backgroundColor:"#20563f", width:"100%", height:"50px"}}>
								<Typography style={{fontFamily:"Montserrat", color:"#ffff", fontSize:!isMobileScreen?"18px":"14px"}} variant="h4" className="fade-in-contact">
										Quiero unirme al TEAM
								</Typography>
								</Box>

						</Box>
						<Typography style={{fontFamily:"Montserrat", color:"#5c5c5c", fontSize:!isMobileScreen?"28px":"20px",marginTop:isMobileScreen?"5vh":"5vh", marginBottom:"15px"}} variant="h4" className="fade-in-contact">
						ENCONTRANOS EN CUALQUIERA DE NUESTRAS <b>#BEAUTIREDES</b>
								</Typography>
							<ul className='nav  justify-content-center list-unstyled d-flex  mb-4 containerListaRedes'>
								<li className='ms-3'>
									<Link
										className='text-muted'
										to='https://www.instagram.com/labeautiqueria/'
										target='_blank'
									>
										<i style={{fontSize:isMobileScreen?"25px":"55px"}}  className='bx bxl-instagram'></i>
									</Link>
								</li>
								<li className='ms-3'>
									<Link
										className='text-muted'
										to='mailto:labeautiqueria@gmail.com'
										target='_blank'
									>
										<i style={{fontSize:isMobileScreen?"25px":"55px", fontWeight:300}} className='bx bxl-gmail'></i>
									</Link>
								</li>

								<li className='ms-3'>
									<Link
										className='text-muted'
										to='https://wa.me/message/RUJEODHSOVNFK1'
										target='_blank'
									>
										<i style={{fontSize:isMobileScreen?"25px":"55px"}} className='bx bxl-whatsapp'></i>
									</Link>
								</li>
								<li className='ms-3'>
									<Link
										className='text-muted'
										to='https://www.tiktok.com/@labeautiqueria'
										target='_blank'
									>
										<i style={{fontSize:isMobileScreen?"25px":"55px"}} className='bx bxl-tiktok'></i>
									</Link>
								</li>
					
							</ul>
					</main>
					<footer>
						<Footer />
					</footer>
				</div>
			)}
		</>
	);
}

export default Contact;
