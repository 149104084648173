// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import es from 'date-fns/esm/locale/es';

import axios from 'axios';
import toast from 'react-hot-toast';

const ModalNuevoIngreso = ({ show, handleClose }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [ingresoData, setIngresoData] = React.useState({
		fecha: '',
		motivo: '',
		monto: 0,
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setIngresoData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSaveData = async (ingresoData) => {
		if (!ingresoData.fecha || !ingresoData.motivo || !ingresoData.monto) {
			return toast.error('Debes completar todos los campos para enviar el ingreso de dinero');
		}
		const formattedtDate = ingresoData.fecha ? format(ingresoData.fecha, 'yyyy-MM-dd') : '';
		const numberMonto = Number(ingresoData.monto);
		const data = { ...ingresoData, fecha: formattedtDate, monto: numberMonto };

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BACK_URL}/api/cajadiaria/nuevoingreso`,
				data,
				config
			);

			if (res.status === 200) {
				toast.success('Nuevo ingreso enviado con éxito');
			} else {
				toast.error(res.data?.msg);
			}
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event('actualizar-caja');
			window.dispatchEvent(event);

			setIngresoData({
				fecha: '',
				motivo: '',
				monto: 0,
			});
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error(error.response.data?.msg);
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Nuevo Ingreso de caja</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}

				<label>Fecha</label>
				<DatePicker
					className='form-control shadow'
					selected={ingresoData.fecha}
					onChange={(date) => setIngresoData({ ...ingresoData, fecha: date })}
					locale={es}
					dateFormat='dd-MM-yyyy'
				/>

				<div>
					<label htmlFor='nombre'>Motivo:</label>

					<input
						className='form-control'
						type='text'
						name='motivo'
						value={ingresoData?.motivo}
						onChange={handleChange}
						placeholder='Motivo del ingreso'
					/>
				</div>

				<div>
					<label htmlFor='descripcion'>Monto:</label>
					<input
						className='form-control'
						type='number'
						name='monto'
						value={ingresoData?.monto}
						onChange={handleChange}
						placeholder='Monto del ingreso'
					/>
				</div>

				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveData(ingresoData)}
					>
						Enviar Ingreso
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalNuevoIngreso;
