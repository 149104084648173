import { createSlice } from '@reduxjs/toolkit';
import { crearTokenCarrito } from '../../utils/cart/cart';
import toast from 'react-hot-toast';
export const carritoSlice = createSlice({
	name: 'carrito',
	initialState: [],
	reducers: {
		agregarAlCarrito: (state, action) => {
			const { nombre, varianteId } = action.payload;

			const existingProduct = state.find(
				(item) => item.nombre === nombre && item.varianteId === varianteId
			);

			if (!existingProduct) {
				state.push(action.payload);

				const existingVariante = action.payload.variantes.find(
					(variante) => variante._id === varianteId
				);

				if (existingVariante) {
					const mensaje = `${nombre} ${existingVariante.color.toUpperCase()} añadido a tu bag`;
					toast.success(mensaje, { duration: 3000 });
				} else {
					toast.success(`${nombre} añadido a tu bag`, { duration: 3000 });
				}
			} else {
				toast.error('Ya existe este producto en tu bag.', {
					duration: 3000,
				});
			}

			crearTokenCarrito(state);
		},
		eliminarDelCarrito: (state, action) => {
			state.splice(action.payload, 1);
			crearTokenCarrito(state);
		},
		cambiarCantidad: (state, action) => {
			const index = action.payload.index;
			const cantidad = action.payload.cantidad;

			state[index].cantidad = cantidad;
			crearTokenCarrito(state);
		},
		borrarCarrito: (state) => {
			return [];
		},
	},
});

export const { agregarAlCarrito, eliminarDelCarrito, cambiarCantidad, borrarCarrito } =
	carritoSlice.actions;
export default carritoSlice.reducer;
