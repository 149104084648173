import React from 'react';
import { Table } from 'react-bootstrap';

const CategoriesTable = ({ categories, onCategoryClick }) => {
	const [selected, setSelected] = React.useState(null);

	const handleCategoryClick = (category) => {
		setSelected(category);
		onCategoryClick(category);
	};
	return (
		<Table responsive="sm" striped bordered hover>
			<thead>
				<tr>
					<th>Nombre</th>
					<th>Estado</th>
				</tr>
			</thead>
			<tbody>
				{categories?.map((category) => (
					<tr key={category._id} onClick={() => handleCategoryClick(category)}>
						<td className={selected?._id === category._id ? 'selected' : ''}>{category.nombre}</td>
						<td className={selected?._id === category._id ? 'selected' : ''}>
							{category.estado.toString()}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default CategoriesTable;
