import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalEditClient.Module.css';
import { formatDate } from '../../../utils/validate/validate';
import toast from 'react-hot-toast';
import axios from 'axios';

// Definir el componente EditModal
const ModalEditClient = ({ show, handleClose, client }) => {
  const token = localStorage.getItem('Token');
  const config = {
    headers: {
      xtoken: `${token}`,
    },
  };

  // Manejar el estado del formulario en el modal
  const [formData, setFormData] = React.useState({
    nombre: client?.nombre,
    correo: client?.correo,
    apellido: client?.apellido,
    dni: client?.dni,
    cumpleaños: client?.cumpleaños,
    telefono: client?.telefono,
    direccion: client?.direccion,
    redes: client?.redes,
    estado: client?.estado,
    beautiId: client?.beautiId,
    notas: client?.notas,
    puntosAcumulados: client?.puntosAcumulados,
    role: client?.role,
  });

  // Manejar cambios en los campos del formulario
  const handleRoleChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      role: checked ? 'STAFF_ROLE' : 'USER_ROLE',
    }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevData) => {
      if (name === 'cumpleaños') {
        // Formatear la fecha al formato "yyyy-MM-dd"
        const dateParts = value.split('-');
        const formattedDate = new Date(
          parseInt(dateParts[0]),
          parseInt(dateParts[1]) - 1,
          parseInt(dateParts[2])
        )
          .toISOString()
          .split('T')[0];

        return {
          ...prevData,
          cumpleaños: formattedDate,
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  // Manejar el evento de guardar los cambios en el modal
  const handleModalSave = async (data) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACK_URL}/api/usuarios/${client?._id}`,
        data,
        config
      );
      // Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
      const event = new Event('actualizar-cliente');
      window.dispatchEvent(event);
      toast.success('Usuario editado con éxito');

      setFormData({
        nombre: '',
        apellido: '',
        dni: '',
        cumpleaños: '',
        telefono: '',
        direccion: '',
        redes: '',
        estado: '',
        beautiId: '',
        notas: '',
        puntosAcumulados: '',
      });
      handleClose();
    } catch (error) {
      toast.error('Error al editar usuario');
    }
  };

  const handleClose2 = () => {
    // Limpiar el estado aquí
    setFormData({
      correo: '',
      password: '',
      password2: '',
      nombre: '',
      apellido: '',
      dni: '',
      cumpleaños: '',
      telefono: '',
      direccion: '',
      redes: '',
      beautiId: '',
      notas: '',
      puntosAcumulados: 0,
      role: 'USER_ROLE',
    });
    handleClose();
    // Cerrar el modal
  };

  return (
    <Modal show={show} onHide={handleClose2} backdrop='static'>
      <Modal.Header className='headerModalHistorial' closeButton>
        <Modal.Title>Editar cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modalConfirmationContainer'>
        {/* Agregar elementos de formulario para cada campo que deseas editar */}

        <div>
          <label htmlFor='nombre'>Nombre:</label>
          <input
            className='form-control'
            type='text'
            name='nombre'
            value={formData?.nombre}
            onChange={handleChange}
            placeholder='Nombre'
          />
        </div>

        <div>
          <label htmlFor='nombre'>Apellido:</label>
          <input
            className='form-control'
            type='text'
            name='apellido'
            value={formData?.apellido}
            onChange={handleChange}
            placeholder='Apellido'
          />
        </div>

        <div>
          <label htmlFor='descripcion'>DNI:</label>
          <input
            className='form-control'
            type='text'
            name='dni'
            value={formData?.dni}
            onChange={handleChange}
            placeholder='DNI'
          />
        </div>
        <div>
          <label htmlFor='nombre'>Correo:</label>
          <input
            className='form-control'
            type='text'
            name='correo'
            value={formData?.correo}
            onChange={handleChange}
            placeholder='Correo electronico'
          />
        </div>
        <div>
          <label htmlFor='cumpleaños'>
            Cumpleaños: {formatDate(formData?.cumpleaños)}
          </label>
          <input
            className='form-control'
            type='date'
            name='cumpleaños'
            value={formData?.cumpleaños}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor='telefono'>Telefono:</label>
          <input
            className='form-control'
            type='text'
            name='telefono'
            value={formData?.telefono}
            onChange={handleChange}
            placeholder='Telefono'
          />
        </div>

        <div>
          <label htmlFor='direccion'>Direccion:</label>
          <input
            className='form-control'
            type='text'
            name='direccion'
            value={formData?.direccion}
            onChange={handleChange}
            placeholder='Direccion'
          ></input>
        </div>

        <div>
          <label htmlFor='redes'>Redes:</label>
          <input
            className='form-control'
            type='text'
            name='redes'
            value={formData?.redes}
            onChange={handleChange}
            placeholder='Redes'
          ></input>
        </div>

        <div>
          <label htmlFor='estado'>Estado:</label>
          <select
            className='form-control'
            name='estado'
            value={formData?.estado}
            onChange={handleChange}
          >
            <option value='true'>Activo</option>
            <option value='false'>Inactivo</option>
          </select>
        </div>

        <div>
          <label htmlFor='notas'>Notas:</label>
          <input
            className='form-control'
            type='text'
            name='notas'
            value={formData?.notas}
            onChange={handleChange}
            placeholder='Notas'
          ></input>
        </div>

        <div>
          <label htmlFor='puntos'>Perlas:</label>
          <input
            className='form-control'
            type='text'
            name='puntosAcumulados'
            value={formData?.puntosAcumulados}
            onChange={handleChange}
            placeholder='Diamantes'
          />
        </div>

        <div>
          <label htmlFor='role'>El usuario forma parte del staff?</label>
          <input
            type='checkbox'
            name='role'
            checked={formData?.role === 'STAFF_ROLE'}
            onChange={handleRoleChange}
          />
        </div>
        <div className='divMagicoReset py-2 mt-4'>
          <Button variant='secondary' onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            className='botonModalConfirmacion'
            variant='primary'
            onClick={() => handleModalSave(formData)}
          >
            Guardar Cambios
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEditClient;
