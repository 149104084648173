// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalNewAliance.Module.css';
import axios from 'axios';
import toast from 'react-hot-toast';

const ModalNewAliance = ({ show, handleClose }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [formData, setFormData] = React.useState({
		nombre: '',
		descripcion: '',
		link: '',
		img: '',
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSaveData = async (formData) => {
		const data = formData;

		try {
			await axios.post(`${process.env.REACT_APP_BACK_URL}/api/alianzas/`, data, config);
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event('actualizar-alianza');
			window.dispatchEvent(event);

			toast.success('Nueva alianza creada con éxito');
			setFormData({
				nombre: '',
				descripcion: '',
				link: '',
				img: '',
			});
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error('Error al crear producto');
		}
	};
	// Manejar cambios en las imágenes (por ejemplo, usando el widget de Cloudinary)
	function handleOpenWidget(img) {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;
						setFormData((prevData) => ({
							...prevData,
							[img]: url,
						}));
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			console.log(error);
			toast.error('Error al cargar la imagen', error);
		}
	}

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Nueva Alianza</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}
				<div>
					<label htmlFor='nombre'>Nombre:</label>

					<input
						className='form-control'
						type='text'
						name='nombre'
						value={formData?.nombre}
						onChange={handleChange}
						placeholder='Nombre'
					/>
				</div>

				<div>
					<label htmlFor='descripcion'>Descripción:</label>
					<input
						className='form-control'
						type='text'
						name='descripcion'
						value={formData?.descripcion}
						onChange={handleChange}
						placeholder='Descripcion'
					/>
				</div>

				<div>
					<label htmlFor='link'>Link:</label>
					<input
						className='form-control'
						type='text'
						name='link'
						value={formData?.link}
						onChange={handleChange}
						placeholder='Link'
					/>
				</div>

				{/* Imagen 1 actual */}
				<section className='imgProductContainer'>
					<div className='imgEdit'>
						<img
							className='imgEditTag'
							src={formData?.img}
							alt='Imagen actual'
							style={{ width: '150px', height: '150px', border: '1px solid black' }}
						/>
						<Button variant='primary' onClick={() => handleOpenWidget('img')}>
							subir imagen
						</Button>
					</div>
				</section>
				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveData(formData)}
					>
						Crear nueva alianza
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalNewAliance;
