// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import es from 'date-fns/esm/locale/es';

import axios from 'axios';
import toast from 'react-hot-toast';

const ModalCerrarCaja = ({ show, handleClose }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [cerrarCajaData, setCerrarCajaData] = React.useState({
		fecha: '',
		notas: '',
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setCerrarCajaData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSaveData = async (cerrarCajaData) => {
		if (!cerrarCajaData.fecha || !cerrarCajaData.notas) {
			return toast.error('Debes completar todos los campos para cerrar caja');
		}
		const formattedDate = cerrarCajaData.fecha ? format(cerrarCajaData.fecha, 'yyyy-MM-dd') : '';

		const data = { fecha: formattedDate, notas: cerrarCajaData.notas };

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BACK_URL}/api/cajadiaria/cerrar`,
				data,
				config
			);

			if (res.status === 200) {
				toast.success('Caja cerrada con éxito');
			} else {
				toast.error(res.data?.msg);
			}
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event('actualizar-caja');
			window.dispatchEvent(event);

			setCerrarCajaData({
				fecha: '',
				notas: '',
			});
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error(error.response.data?.msg);
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Cerrar Caja</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}

				<label>Fecha</label>
				<DatePicker
					className='form-control shadow'
					selected={cerrarCajaData.fecha}
					onChange={(date) => setCerrarCajaData({ ...cerrarCajaData, fecha: date })}
					locale={es}
					dateFormat='dd-MM-yyyy'
				/>

				<div>
					<label htmlFor='nombre'>Notas:</label>

					<input
						className='form-control'
						type='text'
						name='notas'
						value={cerrarCajaData?.notas}
						onChange={handleChange}
						placeholder='Ingresa un comentario para cerrar la caja'
					/>
				</div>

				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveData(cerrarCajaData)}
					>
						Cerrar caja
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalCerrarCaja;
