import React from 'react';
import product1 from '../../assets/PRODUCTO_SIN_FONDO.png';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import './ItemCartSale.Module.css';
import { useDispatch } from 'react-redux';
import { agregarAlCarrito } from '../../features-slice/cart/carritoSlice';
import { isAuthenticated } from '../../utils/auth/auth';

function ItemCartSale({ sale }) {
	const dispatch = useDispatch();

	const [showVariantSelector, setShowVariantSelector] = React.useState(false);
	const [selectedVarianteId, setSelectedVarianteId] = React.useState('');

	// function manejadorAgregarACarrito() {
	// 	dispatch(agregarAlCarrito({ ...sale, cantidad: 1 }));
	// }

	function manejadorAgregarACarrito() {
		if (selectedVarianteId) {
			const carritoItem = {
				...sale,
				cantidad: 1,
				varianteId: selectedVarianteId,
			};
			dispatch(agregarAlCarrito(carritoItem));
		} else {
			dispatch(agregarAlCarrito({ ...sale, cantidad: 1 }));
		}
	}
	const procesarCadena = (cadena) => {
		if (cadena && cadena[0].match(/\d/)) {
			return cadena.slice(1);
		} else {
			return cadena;
		}
	};
	return (
		<div style={{gap:"5px"}} className='containerItemCartSale p-2'>
			<img className='imgCartSaleItem' src={sale.img ? sale.img : product1} alt={sale.nombre} />
			<div className='containerDatosCartSale pb-2'>
				<div className='tituloYPrecioSale'>
					<h3>{procesarCadena(sale.nombre.split(' ').slice(0, 2).join(' '))}</h3>
				</div>
				{/* <div className='d-flex justify-content-between align-items-center '> */}
				{sale.precioOferta > 0 ? (
					<small className='text-muted oferta'>
						<s>${sale.precioEfectivo}</s> ${sale.precioOferta}
					</small>
				) : (
					<small className='text-muted '>${sale.precioEfectivo}</small>
				)}
				{ sale?.variantes.length === 0 ? (
					<button
						className='btn btn-sm btn-outline-secondary carrito'
						onClick={() => manejadorAgregarACarrito()}
					>
						<small style={{fontSize:"10px", fontFamily:"Montserrat"}} className='text-muted'>LO QUIERO</small>
					</button>
				) : (
					<>
						{!selectedVarianteId && !showVariantSelector && (
							<button
								className='btn btn-sm btn-outline-secondary carrito'
								onClick={() => setShowVariantSelector(true)}
								// disabled={sale.disponibles === 0}
							>
								<small style={{fontSize:"10px", fontFamily:"Montserrat"}}  className='text-muted'>LO QUIERO</small>
								
							</button>
						)}

						{showVariantSelector && sale.variantes.length > 0 && (
							<div>
								<select
								style={{width:"90px"}}
									value={selectedVarianteId}
									onChange={(e) => setSelectedVarianteId(e.target.value)}
								>
									<option value=''>Variante</option>
									{sale.variantes?.map((variante) => (
										<option key={variante._id} value={variante._id}>
											{variante.color}
										</option>
									))}
								</select>
								<button
									className='btn btn-sm btn-outline-secondary carrito'
									onClick={manejadorAgregarACarrito}
									disabled={selectedVarianteId === ''}
								>
									<small className='text-muted'>Agregar</small>
									{/* <svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										fill='currentColor'
										className='bi bi-cart2'
										viewBox='0 0 16 16'
									>
										<path d='M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z' />
									</svg> */}
								</button>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default ItemCartSale;
