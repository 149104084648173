import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import ItemCart2 from '../../components/itemCart2/ItemCart2';
import bolsita from '../../assets/BOLSA_CARRITO.png';
import './CartDetail.Module.css';
import { isAuthenticated } from '../../utils/auth/auth';
import { obtenerCostoProductos, obtenerPuntosProductos } from '../../utils/products/products';
import { currencyDisplay, sendNewBuy } from '../../utils/cart/cart';

function CartDetail() {
	const carrito = useSelector((state) => state.carrito);
	const user = useSelector((state) => state.user);
	const cantidadPuntos = obtenerPuntosProductos(carrito);
	const costoProductos = obtenerCostoProductos(carrito);
	const navigate = useNavigate();

	const [loader, setLoader] = React.useState(true);
	React.useEffect(() => {
		if (!isAuthenticated) {
			window.location.replace('/iniciarsesion');
		}
		setTimeout(() => {
			setLoader(false);
		}, 500);
	}, []);

	const handleSeguirComprando = () => {
		navigate('/market');
	};

	return (
		<div className='fondoCartDetail'>
			<header>
				<NavBar />
			</header>
			<main className='containerCartDetail py-4'>
				{loader ? (
					<Loader />
				) : (
					<div className='jeje pt-5'>
						<div className='containerIconTituloCartDetail px-3 py-2'>
							<img src={bolsita} alt='bolsita' style={{ width: '60px', padding: '0 5px' }} />
							<h4>Detalle de tu compra</h4>
						</div>
						<div className='containerSectionCartDetail'>
							<section className='containerSection1'>
								<div className='sectionItemCart'>
									{carrito.length &&
										carrito?.map((producto, index) => {
											return <ItemCart2 producto={producto} index={index} key={index} />;
										})}
								</div>
								<span className='seguirComprando px-4 py-2' onClick={handleSeguirComprando}>
									Seguir comprando
								</span>
							</section>

							<section className='containerSection2'>
								<div className='sectionResumenCart px-4 py-4'>
									<h4>Suma de diamantes: {cantidadPuntos}</h4>
									<h3>Total: {currencyDisplay(costoProductos)} </h3>
								</div>
								<button
									className='primary botonFinalizarCompra '
									onClick={() => sendNewBuy(user.userInfo.uid, carrito)}
								>
									Realizar Pedido
								</button>
							</section>
						</div>
					</div>
				)}
			</main>
			<GloboContacto />
			<footer>
				<Footer />
			</footer>
		</div>
	);
}

export default CartDetail;
