export function validateLogin(loginData) {
	let errors = {};

	if (!loginData.correo) {
		errors.correo = 'Debes ingresar un correo electrónico.';
	} else if (loginData.correo && !/\S+@\S+\.+\S+/.test(loginData.correo)) {
		errors.correo = 'Debe ser un correo válido.';
	}

	if (!loginData.password) {
		errors.password = 'Debes ingresar una contraseña.';
	}

	return errors;
}

export function validateCreateAccount(input) {
	let errors = {};
	if (!input.nombre) {
		errors.nombre = 'Debes ingresar tu nombre';
	} else if (!/^[\s\S]{3,15}$/.test(input.nombre)) {
		errors.nombre = 'El nombre debe contener un mínimo de 3 caracteres y un máximo de 15.';
	}
	if (!input.apellido) {
		errors.apellido = 'Debes ingresar tu apellido';
	} else if (!/^[\s\S]{2,20}$/.test(input.apellido)) {
		errors.apellido = 'El apellido debe contener un mínimo de 2 caracteres y un máximo de 20.';
	}

	if (!input.correo) {
		errors.correo = 'Debes ingresar un correo electrónico.';
	} else if (input.correo && !/\S+@\S+\.+\S+/.test(input.correo)) {
		errors.correo = 'Debe ser un correo válido.';
	}
	if (!input.dni) {
		errors.dni = 'Debes ingresar tu DNI para el registro.';
	}

	if (!input.password) {
		errors.password = 'Debes ingresar una contraseña.';
	} else if (!/^(?=.*[A-Z]).{8,}$/.test(input.password)) {
		errors.password = 'Debe contener 8 caracteres como mínimo y por lo menos una mayúscula.';
	}
	if (!input.password2) {
		errors.password2 = 'Confirma tu contraseña.';
	} else if (input.password !== input.password2) {
		errors.password2 = 'Las contraseñas no coinciden.';
	}

	return errors;
}

export function validateResetPassword(input) {
	let errors = {};

	if (!input.correo) {
		errors.correo = 'Debes ingresar un correo electrónico.';
	} else if (input.correo && !/\S+@\S+\.+\S+/.test(input.correo)) {
		errors.correo = 'Debe ser un correo válido.';
	}

	if (!input.newPassword) {
		errors.newPassword = 'Debes ingresar una contraseña.';
	} else if (!/^(?=.*[A-Z]).{8,}$/.test(input.newPassword)) {
		errors.newPassword = 'Debe contener 8 caracteres como mínimo y por lo menos una mayúscula.';
	}
	if (!input.newPassword2) {
		errors.newPassword2 = 'Confirma tu contraseña.';
	} else if (input.newPassword !== input.newPassword2) {
		errors.newPassword2 = 'Las contraseñas no coinciden.';
	}

	return errors;
}

export const formatDate = (date) => {
	const fecha = new Date(date);

	const dia = fecha.getUTCDate();
	const mes = fecha.getUTCMonth() + 1;
	const anio = fecha.getUTCFullYear() % 100;

	const fechaFormateada = `${dia.toString().padStart(2, '0')}-${mes
		.toString()
		.padStart(2, '0')}-${anio.toString().padStart(2, '0')}`;

	return fechaFormateada;
};
