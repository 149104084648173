import { configureStore } from '@reduxjs/toolkit';
import carritoSlice from '../features-slice/cart/carritoSlice';
import userSlice from '../features-slice/user/userSlice';

export default configureStore({
	reducer: {
		carrito: carritoSlice,
		user: userSlice,
	},
});
