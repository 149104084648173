import React from 'react';
import { Card, Typography, Button, Box, Dialog, DialogContent } from '@mui/material';
import './Footer.Module.css';
import { useMediaQuery } from 'react-responsive';

function Footer() {
const isMobile = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
const [showTerms, setShowTerms] = React.useState(false)
const handleShowTerms = () => {setShowTerms(true)}
const handleShowTermsClose = () => {setShowTerms(false)}


const politicas = [
	{
		pregunta: "CAMBIOS Y DEVOLUCIONES MARKET LA BEAUTIQUERIA",
		respuesta: "*Toda mercadería será chequeada antes de ser despachada.* \n Ante un cambio y/o devolución de producto, las alternativas son:  \n ♡  *Reemplazar el producto en día y horario a coordinar.*  \nEl cambio debe ser solicitado vía Whatsapp 341-5140700 indicando: nombre y apellido, dirección, horario de retiro (en caso de corresponder), número de pedido, código y descripción del producto, cantidad, motivo del cambio o devolución. \nEl cambio solo se realizará en sucursal previo control de esta información vía whatsapp y aprobación del mismo. \n *IMPORTANTE* \nPara cambiar o devolver un producto éste deberá conservar el envase original y  etiquetas originales y presentar la info anteriormente mencionada (nombre completo y número de pedido). \nEstos requisitos son INDISPENSABLES. \nAdemás se deberá considerar que, para los cambios, los productos  comprados en fechas especiales con ofertas se recibirán por el mismo valor del precio en promoción. \n *En ningún caso se devuelve el dinero.* \n *♡ NO TIENEN CAMBIO:* \nesmaltes, aceites, fragancias y relacionados. Aquellos productos donde no se puede verificar la modificación de su contenido. \n *♡Contemplamos los siguientes casos:* \n -El producto recibido no es el correspondiente al que Ud. seleccionó y el mismo no presentara anomalías (el empaque está cerrado) se procederá a realizar el cambio. \n -El producto solicitado no es del agrado de la persona que lo recibió y el mismo no fue abierto y no presenta anomalías (el empaque está cerrado) se podrá solicitar el cambio. \n -Si ha recibido un producto dañado deberá realizar el reclamo al instante al número de whatsapp  341-5140700 donde se le indicarán los pasos a seguir. \n Para todos los casos deberá comunicarse dentro de los 10 días desde  la recepción del pedido forma que se le indique como proceder. \n *♡El envío está a cargo del consumidor.*"
	},
	{
		pregunta: "POLÍTICA DE TURNOS BEAUTIQUEROS ",
		respuesta: "Cuando reservas un turno para utilizar un servicio beautiquero, estás generando un compromiso con *La Beautiqueria* de asistencia y nosotras de brindarte el servicio elegido. \nComo en nuestro salón no se pide seña, si no se cumple con la asistencia y no se realiza la cancelación con los tiempos correspondientes, el turno disponible no podrá ser utilizado por otra persona. \nPor eso establecemos estas políticas tanto para reservar como para cancelar, así todas podemos disfrutar de esta experiencia Beautiquera. \n♡Los turnos deben ser confirmados, respondiendo el mensaje que se envía desde la institución el día/ turno anterior. \n En caso de no haber recibido dicha respuesta  4 horas antes la cita quedará anulada y el turno liberado. \n La no confirmación de la reserva, implica una cancelación de la misma. ♡En caso de no asistir a tu cita, la misma debe ser cancelada con al menos 12 hs. *SOLAMENTE VIA WHATSAPP* \n ♡Todas tenemos imprevistos y emergencias, por lo cual, luego de no asistir a tu cita más de una vez, y de no haberla cancelado con la anticipación solicitada,*se cobrará una multa del 50%* del valor del servicio solicitado. \n En caso de que no desees abonarla, nuestro equipo podrá reservarse el derecho de brindarte una nueva cita.\n ♡Con el fin de respetar el tiempo de toda nuestra comunidad y de nuestro team , te pedimos que llegues puntual a su cita. \n *Contamos con 10 minutos de tolerancia.* \nLamentablemente no podremos atenderte en el caso de sobrepasar este tiempo.\n BRINDAR UNA EXPERIENCIA ÚNICA ES NUESTRA PRIORIDAD. \nEN EL CASO QUE NO ESTÉS SATISFECHA CON EL SERVICIO OFRECIDO, POR FAVOR SOLICITAMOS QUE NOS LO COMUNIQUES DENTRO DE LAS 24HS SIGUIENTES. \nNUESTRO TEAM SE COMPROMETE A RESOLVERLO Y MEJORAR EN EL CASO QUE SEA NECESARIO, Y SIEMPRE BRINDARTE LA MEJOR RESPUESTA. \nGRACIAS POR ELEGIRNOS♡ * # BEUTIQUEMOSELMUNDO *"
	},

]

	return (
		<div className='d-flex flex-wrap justify-content-start align-items-end border-top divFooter '>
				<Typography onClick={ () => handleShowTerms()} sx={{fontSize:"12px", cursor:"pointer"}} className='terminos' href='/terminos'>
					Ver bases y condiciones
				</Typography>
				<Typography sx={{fontSize:"12px"}} className='terminos'>Todos los derechos reservados © 2023 - 2024</Typography>
				<Dialog open={showTerms} onClose={handleShowTermsClose}>
					<DialogContent>
						<Typography>
							{politicas[0].pregunta}
						</Typography>

              {politicas[0].respuesta?.split('\n').map((paragraph, idx) => (
                <Typography key={idx} style={{ fontFamily: "Montserrat", textAlign: "left", fontSize:isMobile?"12px":"14px" }} gutterBottom>
                  {paragraph.split(/(\*[^*]*\*)/).map((text, idx) => {
                    if (text.startsWith('*') && text.endsWith('*')) {
                      return <strong key={idx}>{text.slice(1, -1)}</strong>;
                    } else {
                      return text;
                    }
                  })}
                </Typography>
						))}


						<Typography>
							{politicas[1].pregunta}
						</Typography>

{politicas[1].respuesta?.split('\n').map((paragraph, idx) => (
	<Typography key={idx} style={{ fontFamily: "Montserrat", textAlign: "left", fontSize:isMobile?"12px":"14px" }} gutterBottom>
		{paragraph.split(/(\*[^*]*\*)/).map((text, idx) => {
			if (text.startsWith('*') && text.endsWith('*')) {
				return <strong key={idx}>{text.slice(1, -1)}</strong>;
			} else {
				return text;
			}
		})}
	</Typography>
))}

					</DialogContent>
				</Dialog>
		</div>
	);
}

export default Footer;
