import axios from 'axios';
import { isAuthenticated } from '../auth/auth';
import { useSelector } from 'react-redux';

const uri = process.env.REACT_APP_BACK_URL;

export const isEditMode = async () => {
	const { data } = await axios.get(`${uri}/api/modomantenimiento`);
	const isMaintenanceMode = data.modoMantenimiento?.maintenanceMode;
	return isMaintenanceMode;
};

export const getUsers = async (desde) => {
	const { data } = await axios.get(`${uri}/api/usuarios?desde=${desde}`);
	return data;
};

export const getStaffUsers = async (desde) => {
  const { data } = await axios.get(`${uri}/api/usuarios?desde=${desde}&STAFF=yes`);
  return data;
};


export const getUser = async (id) => {
	const { data } = await axios.get(`${uri}/api/usuarios/${id}`);
	return data;
};

export const getUsersLimit = async (desde, limit) => {
	const { data } = await axios.get(`${uri}/api/usuarios?desde=${desde}&limit=${limit}`);
	return data;
};

export const searchAllUsers = async (termino, desde) => {
	const { data } = await axios.get(`${uri}/api/buscar/usuarios/${termino}?desde=${desde}`);
	return data;
};

export const capitalizeWord = (word) => {
	return word[0].toUpperCase() + word.slice(1);
};
