// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalEditAliance.Module.css';
import toast from 'react-hot-toast';
import ModalConfirmation from '../modalConfirmation/ModalConfirmation';
import axios from 'axios';

// Definir el componente EditModal
const ModalEditAliance = ({ show, handleClose, aliance, handleSave }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	// Manejar el estado del formulario en el modal
	const [formData, setFormData] = React.useState({
		nombre: aliance.nombre,
		descripcion: aliance.descripcion,
		link: aliance.link,
		img: aliance.img,
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const [showConfirmation, setShowConfirmation] = React.useState(false);

	const handleShowConfirmation = () => {
		setShowConfirmation(true);
	};

	const handleDeleteAliance = async () => {
		try {
			await axios.delete(`${process.env.REACT_APP_BACK_URL}/api/alianzas/${aliance?._id}`, config);
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event('actualizar-alianza');
			window.dispatchEvent(event);
			toast.success('Alianza eliminada con éxito');
			setShowConfirmation(false);
			handleClose();
		} catch (error) {
			toast.error('Error al eliminar alianza');
		}

		setShowConfirmation(false); // Cierra el modal de confirmación
	};
	const handleCancelDeleteAliance = () => {
		setShowConfirmation(false); // Cierra el modal de confirmación en caso de cancelación
	};

	// Manejar cambios en las imágenes (por ejemplo, usando el widget de Cloudinary)
	function handleOpenWidget(img) {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;
						setFormData((prevData) => ({
							...prevData,
							[img]: url,
						}));
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			console.log(error);
			toast.error('Error al cargar la imagen', error);
		}
	}

	// Manejar el evento de guardar los cambios en el modal
	const handleModalSave = () => {
		// Realizar la petición PUT para actualizar los datos en el backend
		handleSave(formData);
		setFormData({
			nombre: '',
			descripcion: '',
			link: '',
			img: '',
		});
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Editar Alianza</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}
				<ModalConfirmation
					className='modaaaaal'
					show={showConfirmation}
					title='Eliminar alianza'
					message='¿Estás seguro de que deseas eliminar la alianza?'
					onConfirm={handleDeleteAliance}
					onCancel={handleCancelDeleteAliance}
				/>
				<div>
					<label htmlFor='nombre'>Nombre:</label>

					<input
						className='form-control'
						type='text'
						name='nombre'
						value={formData?.nombre}
						onChange={handleChange}
						placeholder='Nombre'
					/>
				</div>

				<div>
					<label htmlFor='descripcion'>Descripción:</label>
					<textarea
						className='form-control'
						type='text'
						name='descripcion'
						value={formData?.descripcion}
						onChange={handleChange}
						placeholder='Descripcion'
					/>
				</div>

				<div>
					<label htmlFor='link'>Link:</label>
					<input
						className='form-control'
						type='text'
						name='link'
						value={formData?.link}
						onChange={handleChange}
						placeholder='Link'
					/>
				</div>

				{/* Imagen 1 actual */}
				<section className='imgProductContainer'>
					<div className='imgEdit'>
						<img
							className='imgEditTag'
							src={formData?.img}
							alt='Imagen actual'
							style={{ width: '150px', height: '150px', border: '1px solid black' }}
						/>
						<Button variant='primary' onClick={() => handleOpenWidget('img')}>
							Actualizar Imagen
						</Button>
						<Button className='danger-edit' variant='danger' onClick={handleShowConfirmation}>
							Eliminar alianza
						</Button>
					</div>
				</section>
				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button className='botonModalConfirmacion' variant='primary' onClick={handleModalSave}>
						Guardar Cambios
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditAliance;
