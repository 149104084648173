// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import es from 'date-fns/esm/locale/es';

import axios from 'axios';
import toast from 'react-hot-toast';

const ModalAbrirCaja = ({ show, handleClose }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [abrirCajaData, setAbrirCajaData] = React.useState({
		fecha: new Date(),
		saldoInicial: 0,
	});

	const customDayStyles = [
		{
			date: new Date(),
			style: {
				fontWeight: 'bold', // o cualquier estilo que desees aplicar al día actual
				textDecoration: 'underline', // podrías probar con subrayado
			},
		},
	];

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setAbrirCajaData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSaveData = async (abrirCajaData) => {
		if (!abrirCajaData.fecha || abrirCajaData.saldoInicial <= 0) {
			return toast.error('Debes completar todos los campos para abrir caja');
		}
		const formattedDate = abrirCajaData.fecha ? format(abrirCajaData.fecha, 'MM-dd-yyy') : '';
		const numberMonto = Number(abrirCajaData.saldoInicial);
		const data = { fecha: formattedDate, saldoInicial: numberMonto };

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BACK_URL}/api/cajadiaria`,
				data,
				config
			);
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			if (res.status === 201) {
				toast.success('Caja iniciada con éxito');
			} else {
				// console.log(res);
				toast.error(res.data?.msg);
			}

			const event = new Event('actualizar-caja');
			window.dispatchEvent(event);

			setAbrirCajaData({
				fecha: '',
				saldoInicial: 0,
			});
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error(error.response.data?.message);
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Abrir caja</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}

				<label>Fecha</label>
				<DatePicker
					className='form-control shadow'
					selected={abrirCajaData.fecha || Date.now()}
					onChange={(date) => setAbrirCajaData({ ...abrirCajaData, fecha: date })}
					locale={es}
					dateFormat='dd-MM-yyyy' // Establece el formato personalizado
					
				/>

				<div>
					<label htmlFor='nombre'>Saldo Inicial:</label>

					<input
						className='form-control'
						type='number'
						name='saldoInicial'
						value={abrirCajaData?.saldoInicial}
						onChange={handleChange}
						placeholder='Ingresa el saldo inicial de la caja'
					/>
				</div>

				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveData(abrirCajaData)}
					>
						Abrir caja
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalAbrirCaja;
