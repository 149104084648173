import React from 'react';
import LoginNavBar from '../../components/loginNavBar/LoginNavBar';
import { Link, useNavigate } from 'react-router-dom';

import corazon from '../../assets/CORAZON_TRISTE.png';

function Mantenimiento() {

	const navigate = useNavigate();
	return (
		<div className='fondoError'>
			<header>
				<LoginNavBar />
			</header>
			<main className='containerError '>
				<div className='containerCorazon mt-5'>
					<img src={corazon} alt='corazon triste' />
				</div>
				<h2>Estamos realizando tareas de mantenimiento, ingresa mas tarde.</h2>
				<h3>Te pedimos disculpas por las molestias ocasionadas</h3>
				
					<h4 style={{cursor:"pointer"}} onClick={()=>navigate("/")}>Volver al inicio</h4>
			
			</main>
		</div>
	);
}

export default Mantenimiento;
