import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { recuperarContraseña } from '../../../utils/auth/auth';
import toast from 'react-hot-toast';
import './ModalResetPassword.Module.css';
import { validateResetPassword } from '../../../utils/validate/validate';

const ModalResetPassword = ({ show, title, message, onCancel }) => {
	const [userInfo, setUserInfo] = useState({
		correo: '',
		newPassword: '',
		newPassword2: '',
	});
	const [errors, setErrors] = React.useState({});
	const handleChange = (e) => {
		setUserInfo({
			...userInfo,
			[e.target.name]: e.target.value,
		});
		setErrors(
			validateResetPassword({
				...userInfo,
				[e.target.name]: e.target.value,
			})
		);
	};

	// Función para enviar la solicitud de recuperación de contraseña al backend
	const handleForgotPassword = async () => {
		if (errors.correo || errors.newPassword || errors.newPassword2) {
			return toast.error('Revisa los campos ingresados');
		}
		await recuperarContraseña(userInfo.correo, userInfo.newPassword);

		onCancel();
	};

	return (
		<Modal show={show} onHide={onCancel} className='containerConfirmation' centered>
			<Modal.Header className='headerModalReset' closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalResetContainer'>
				{message}
				<Form>
					<Form.Group controlId='formBasicEmail'>
						<Form.Control
							className='mt-4'
							type='email'
							placeholder='Ingresa el correo con el que estás registrado'
							name='correo'
							value={userInfo.correo}
							onChange={handleChange}
						/>
						{errors.correo ? (
							<p
								className='pt-1'
								style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
							>
								{errors.correo}
							</p>
						) : (
							''
						)}
					</Form.Group>

					<Form.Group controlId='formBasicPassword'>
						<Form.Control
							className='mt-4'
							type='password'
							placeholder='Ingresa tu nueva contraseña'
							name='newPassword'
							value={userInfo.newPassword}
							onChange={handleChange}
						/>
						{errors.newPassword ? (
							<p
								className='pt-1'
								style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
							>
								{errors.newPassword}
							</p>
						) : (
							''
						)}
					</Form.Group>
					<Form.Group controlId='formBasicPassword2'>
						<Form.Control
							className='mt-4'
							type='password'
							placeholder='Ingresa nuevamente tu nueva contraseña'
							name='newPassword2'
							value={userInfo.newPassword2}
							onChange={handleChange}
						/>
						{errors.newPassword2 ? (
							<p
								className='pt-1'
								style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
							>
								{errors.newPassword2}
							</p>
						) : (
							''
						)}
					</Form.Group>
				</Form>
				<div className='divMagicoReset py-2 mt-4'>
					<Button className='buttonSecondary' variant='secondary' onClick={onCancel}>
						Cancelar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={handleForgotPassword}
					>
						Confirmar
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalResetPassword;
