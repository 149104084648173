import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './CarouselOfertasCart.Module.css';
import ItemCartSale from '../itemCartSale/ItemCartSale';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 2,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

function CarouselOfertasCart({ sales }) {
	const CustomRightArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType },
		} = rest;
		// onMove means if dragging or swiping in progress.
		return (
			<button className='rightArrow' onClick={() => onClick()}>
				{'>'}
			</button>
		);
	};
	const CustomLefttArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType },
		} = rest;
		// onMove means if dragging or swiping in progress.
		return (
			<button className='leftArrow' onClick={() => onClick()}>
				{'<'}
			</button>
		);
	};

	return (
		<div className=' container py-2 containerOfertasCarouselCart'>
			<h2 style={{fontFamily:"Montserrat"}} className='tituloCarouselOfertasCart'>No te pierdas nuestras Beautigangas</h2>
			<Carousel
				customRightArrow={<CustomRightArrow />}
				customLeftArrow={<CustomLefttArrow />}
				draggable={true}
				showDots={false}
				swipeable={true}
				responsive={responsive}
				infinite={true}
				arrows={true}
				autoPlay={true}
				keyBoardControl={true}
				//autoPlay={props.deviceType !== 'mobile' ? true : false}
				autoPlaySpeed={5000}
				containerClass='carouselOfertasCart-container py-2'
				removeArrowOnDeviceType={['tablet', 'mobile']}
			>
				{sales?.map((sale, index) => {
					return (
						<div className='containerItemCartSaleCarousel px-3' key={index}>
							<ItemCartSale sale={sale} />
						</div>
					);
				})}
			</Carousel>
		</div>
	);
}

export default CarouselOfertasCart;
