import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import toast from 'react-hot-toast';

import axios from 'axios';
import './ModalEditUserCategory.Module.css';
const ModalEditUserCategory = ({ show, handleClose, userCategory }) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const handleShowConfirmationModal = () => {
		setShowConfirmationModal(true);
	};

	const handleCloseConfirmationModal = () => {
		setShowConfirmationModal(false);
	};
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [formData, setFormData] = useState({
		nombre: userCategory.nombre,
		puntosNecesarios: userCategory.puntosNecesarios,
		beneficios: userCategory.beneficios,
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	// Manejar cambios en los beneficios
	const handleBeneficioChange = (index, field, value) => {
		const updatedBeneficios = [...formData.beneficios];
		updatedBeneficios[index][field] = value;
		setFormData((prevData) => ({
			...prevData,
			beneficios: updatedBeneficios,
		}));
	};

	const handleRemoveBeneficio = (index) => {
		const updatedBeneficios = [...formData.beneficios];
		updatedBeneficios.splice(index, 1); // Eliminar el beneficio en el índice especificado
		setFormData((prevData) => ({
			...prevData,
			beneficios: updatedBeneficios,
		}));
	};

	const handleAddBeneficio = () => {
		const newBeneficio = {
			nombre: '',
			tipo: 'porcentaje',
			valor: '',
			disponible: true,
			alwaysTrue: false,
		};

		setFormData((prevData) => ({
			...prevData,
			beneficios: [...prevData.beneficios, newBeneficio],
		}));
	};

	const handleDeleteCategory = async () => {
		try {
			await axios.delete(
				`${process.env.REACT_APP_BACK_URL}/api/categoriasusuario/${userCategory?._id}`,
				config
			);
			// Disparar un evento personalizado 'actualizar-alianza' cuando se haya eliminado la categoría
			const event = new Event('actualizar-categoriaUsuario');
			window.dispatchEvent(event);

			toast.success('Categoría de usuario eliminada con éxito');
			handleClose();
		} catch (error) {
			toast.error('Error al eliminar la categoría de usuario');
		}

		handleCloseConfirmationModal();
	};

	const handleSaveDataCategory = async () => {
		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/categoriasusuario/${userCategory?._id}`,
				formData,
				config
			);

			// Disparar un evento personalizado 'actualizar-categoriaUsuario' cuando se haya actualizado la categoría
			const event = new Event('actualizar-categoriaUsuario');
			window.dispatchEvent(event);

			toast.success('Categoría de usuario actualizada con éxito');
			setFormData({
				nombre: '',
				puntosNecesarios: '',
				beneficios: [],
			});
			handleClose();
		} catch (error) {
			toast.error('Error al actualizar la categoría de usuario');
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Editar Categoría de usuario {userCategory?._id}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				<div>
					<label htmlFor='nombre'>Nombre:</label>
					<input
						className='form-control'
						type='text'
						name='nombre'
						value={formData.nombre}
						onChange={handleChange}
						placeholder='Nombre'
					/>
				</div>
				<div>
					<label htmlFor='puntosNecesarios'>Perlas Necesarias:</label>
					<input
						className='form-control'
						type='text'
						name='puntosNecesarios'
						value={formData.puntosNecesarios}
						onChange={handleChange}
						placeholder='Perlas necesarias'
					/>
				</div>
				<div className='beneficios-section'>
					<h4>Beneficios</h4>
					<Button variant='primary' onClick={handleAddBeneficio}>
						Agregar Beneficio
					</Button>

					<ul>
						{formData.beneficios.map((beneficio, index) => (
							<div key={index}>
								<li className='container py-3'>
									<div>
										<label htmlFor={`nombreBeneficio${index}`}>Nombre:</label>
										<i
											className='bi bi-x-circle filterOptionXnew'
											onClick={() => handleRemoveBeneficio(index)}
										></i>
										<input
											className='form-control'
											type='text'
											id={`nombreBeneficio${index}`}
											name={`nombreBeneficio${index}`}
											value={beneficio.nombre}
											onChange={(e) => handleBeneficioChange(index, 'nombre', e.target.value)}
										/>
									</div>
									<div>
										<label htmlFor={`tipoBeneficio${index}`}>Tipo:</label>
										<select
											className='form-control'
											id={`tipoBeneficio${index}`}
											name={`tipoBeneficio${index}`}
											value={beneficio.tipo}
											onChange={(e) => handleBeneficioChange(index, 'tipo', e.target.value)}
										>
											<option value='porcentaje'>Porcentaje</option>
											<option value='regalo'>Regalo</option>
											<option value='cantidad'>Cantidad</option>
										</select>
									</div>
									<div>
										<label htmlFor={`valorBeneficio${index}`}>Valor:</label>
										<input
											className='form-control'
											type='text'
											id={`valorBeneficio${index}`}
											name={`valorBeneficio${index}`}
											value={!beneficio.valor ? '' : beneficio.valor}
											onChange={(e) => handleBeneficioChange(index, 'valor', e.target.value)}
										/>
									</div>
									<div>
										<label htmlFor={`disponibleBeneficio${index}`}>Disponible:</label>
										<select
											className='form-control'
											id={`disponibleBeneficio${index}`}
											name={`disponibleBeneficio${index}`}
											value={beneficio.disponible}
											onChange={(e) => handleBeneficioChange(index, 'disponible', e.target.value)}
										>
											<option value='true'>True</option>
											<option value='false'>False</option>
										</select>
									</div>
									<div>
										<label htmlFor={`alwaysTrueBeneficio${index}`}>Always True:</label>
										<select
											className='form-control'
											id={`alwaysTrueBeneficio${index}`}
											name={`alwaysTrueBeneficio${index}`}
											value={beneficio.alwaysTrue}
											onChange={(e) => handleBeneficioChange(index, 'alwaysTrue', e.target.value)}
										>
											<option value='true'>True</option>
											<option value='false'>False</option>
										</select>
									</div>
								</li>
							</div>
						))}
					</ul>
				</div>
				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={handleSaveDataCategory}
					>
						Guardar Cambios
					</Button>

					<Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
						<Modal.Header className='headerModalHistorial' closeButton>
							<Modal.Title>Confirmar eliminación</Modal.Title>
						</Modal.Header>
						<Modal.Body className='modalConfirmationEliminar'>
							¿Seguro que deseas eliminar esta categoría?
						</Modal.Body>

						<Button variant='secondary' onClick={handleCloseConfirmationModal}>
							Cancelar
						</Button>
						<Button variant='danger' onClick={handleDeleteCategory}>
							Eliminar
						</Button>
					</Modal>
				</div>
				<Button variant='danger' onClick={handleShowConfirmationModal}>
					Eliminar Categoría
				</Button>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditUserCategory;
