import React from 'react';
import Footer from '../../components/footer/Footer';
import NavBarRosa from '../../components/navBarRosa/NavBarRosa';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog
} from "@mui/material";
import NavBar from '../../components/navBar/NavBar';
import VideoHome from '../../components/videoHome/VideoHome';
import './Alianzas.Module.css';
import { getAllAliances } from '../../utils/alianzas/alianzas';
import { Toaster, toast } from 'react-hot-toast';
import Alianza from '../../components/alianza/Alianza';
import axios from 'axios';
import { useSelector } from 'react-redux';
import VideoInfo from '../../components/videoInfo/VideoInfo';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMediaQuery } from 'react-responsive';

export default function Alianzas() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const isMobile = useMediaQuery({ maxWidth: 700 });
	const [image, setImage] = React.useState('');
	const [loader, setLoader] = React.useState(true);
	const [alianzas, setAlianzas] = React.useState([]);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);

	function agruparEnPares(arr) {
		const resultado = [];
		for (let i = 0; i < arr.length; i += 2) {
			resultado.push(arr.slice(i, i + 2));
		}
		return resultado;
	}

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	

	const preguntas = [
		{
			pregunta: "¿Qué es la Beauti Comunidad?",
			respuesta: "La BeautiComunidad es la red de beneficios y premios que creamos para todos los que forman parte de esta comunidad."
		},
		{
			pregunta: "¿En qué consiste?",
			respuesta: `Cada persona que forme parte de la comunidad Beautiquera, va a ir acumulando 𝑷𝒆𝒓𝒍𝒂𝒔 por los consumos tanto en servicios como en nuestro Market. Con sus 𝑷𝒆𝒓𝒍𝒂𝒔 van a ir accediendo a grandes beneficios y premios como descuentos, regalos y mucho más ! Ante cualquier consulta, te dejamos el Link de acceso para que puedas chequear las <a target="blank",className="aLink" style={{color:"#5c5c5c!important"}} href="https://docs.google.com/document/d/1XMXyncai7qCrQchst0C4qsYMDCzK7q6lwf-C36f2Oh4/edit#heading=h.xxenreunm81d">BASES Y CONDICIONES DE LA BEAUTICOMUNIDAD</a>`
		},
		{
			pregunta: "¿Cómo sumo mis perlas?",
			respuesta: `Se recibirá un puntaje correspondiente a cada compra de servicio y/o producto en nuestro local. En el punto 6 de nuestro reglamento se podrá leer un ejemplo explicativo y las condiciones. <a className="aLink" target="blank" href="https://docs.google.com/document/d/1XMXyncai7qCrQchst0C4qsYMDCzK7q6lwf-C36f2Oh4/edit#heading=h.xxenreunm81d">PAUTAS Y CONDICIONES DE LA BEAUTICOMUNIDAD</a>`
		},
		{
			pregunta: "¿Donde puedo ver los beneficios/premios?",
			respuesta: `En nuestra presentación con detalles que te dejamos a continuación: <a target="blank" className="aLink" href="https://docs.google.com/presentation/d/12olEDH6jw3Z6ryrqyOko5CuvNsHwSfCa2Ty8UyO43Ic/edit#slide=id.g8b37602b7e_0_5">DETALLE DE PERLAS Y BENEFICIOS</a>`
		},
		{
			pregunta: "¿Tiene algún costo extra?",
			respuesta: `¡NO! Los beneficios tanto de nuestra BeautiComunidad como el acceso a nuestras alianzas son totalmente GRATUITAS para los integrantes activos* de la comunidad beautiquera. 𝑺𝒊𝒈𝒂𝒎𝒐𝒔 𝑩𝒆𝒂𝒖𝒕𝒊𝒒𝒖𝒆𝒂𝒏𝒅𝒐 𝒆𝒍 𝑴𝒖𝒏𝒅𝒐 𝒚 𝒄𝒓𝒆𝒂𝒏𝒅𝒐 𝒏𝒖𝒆𝒗𝒂𝒔 𝒓𝒆𝒅𝒆𝒔! *Ingresa a nuestras Bases y Condiciones para chequear la condición de “integrante activo” (punto 15 reglamento de BEAUTICOMUNIDAD)`
		}
	];


	const politicas = [
		{
			pregunta: "CAMBIOS Y DEVOLUCIONES MARKET LA BEAUTIQUERIA",
			respuesta: "*Toda mercadería será chequeada antes de ser despachada.* \n Ante un cambio y/o devolución de producto, las alternativas son:  \n ♡  *Reemplazar el producto en día y horario a coordinar.*  \nEl cambio debe ser solicitado vía Whatsapp 341-5140700 indicando: nombre y apellido, dirección, horario de retiro (en caso de corresponder), número de pedido, código y descripción del producto, cantidad, motivo del cambio o devolución. \nEl cambio solo se realizará en sucursal previo control de esta información vía whatsapp y aprobación del mismo. \n *IMPORTANTE* \nPara cambiar o devolver un producto éste deberá conservar el envase original y  etiquetas originales y presentar la info anteriormente mencionada (nombre completo y número de pedido). \nEstos requisitos son INDISPENSABLES. \nAdemás se deberá considerar que, para los cambios, los productos  comprados en fechas especiales con ofertas se recibirán por el mismo valor del precio en promoción. \n *En ningún caso se devuelve el dinero.* \n *♡ NO TIENEN CAMBIO:* \nesmaltes, aceites, fragancias y relacionados. Aquellos productos donde no se puede verificar la modificación de su contenido. \n *♡Contemplamos los siguientes casos:* \n -El producto recibido no es el correspondiente al que Ud. seleccionó y el mismo no presentara anomalías (el empaque está cerrado) se procederá a realizar el cambio. \n -El producto solicitado no es del agrado de la persona que lo recibió y el mismo no fue abierto y no presenta anomalías (el empaque está cerrado) se podrá solicitar el cambio. \n -Si ha recibido un producto dañado deberá realizar el reclamo al instante al número de whatsapp  341-5140700 donde se le indicarán los pasos a seguir. \n Para todos los casos deberá comunicarse dentro de los 10 días desde  la recepción del pedido forma que se le indique como proceder. \n *♡El envío está a cargo del consumidor.*"
		},
		{
			pregunta: "POLÍTICA DE TURNOS BEAUTIQUEROS ",
			respuesta: "Cuando reservas un turno para utilizar un servicio beautiquero, estás generando un compromiso con *La Beautiqueria* de asistencia y nosotras de brindarte el servicio elegido. \nComo en nuestro salón no se pide seña, si no se cumple con la asistencia y no se realiza la cancelación con los tiempos correspondientes, el turno disponible no podrá ser utilizado por otra persona. \nPor eso establecemos estas políticas tanto para reservar como para cancelar, así todas podemos disfrutar de esta experiencia Beautiquera. \n♡Los turnos deben ser confirmados, respondiendo el mensaje que se envía desde la institución el día/ turno anterior. \n En caso de no haber recibido dicha respuesta  4 horas antes la cita quedará anulada y el turno liberado. \n La no confirmación de la reserva, implica una cancelación de la misma. ♡En caso de no asistir a tu cita, la misma debe ser cancelada con al menos 12 hs. *SOLAMENTE VIA WHATSAPP* \n ♡Todas tenemos imprevistos y emergencias, por lo cual, luego de no asistir a tu cita más de una vez, y de no haberla cancelado con la anticipación solicitada,*se cobrará una multa del 50%* del valor del servicio solicitado. \n En caso de que no desees abonarla, nuestro equipo podrá reservarse el derecho de brindarte una nueva cita.\n ♡Con el fin de respetar el tiempo de toda nuestra comunidad y de nuestro team , te pedimos que llegues puntual a su cita. \n *Contamos con 10 minutos de tolerancia.* \nLamentablemente no podremos atenderte en el caso de sobrepasar este tiempo.\n BRINDAR UNA EXPERIENCIA ÚNICA ES NUESTRA PRIORIDAD. \nEN EL CASO QUE NO ESTÉS SATISFECHA CON EL SERVICIO OFRECIDO, POR FAVOR SOLICITAMOS QUE NOS LO COMUNIQUES DENTRO DE LAS 24HS SIGUIENTES. \nNUESTRO TEAM SE COMPROMETE A RESOLVERLO Y MEJORAR EN EL CASO QUE SEA NECESARIO, Y SIEMPRE BRINDARTE LA MEJOR RESPUESTA. \nGRACIAS POR ELEGIRNOS♡ * # BEUTIQUEMOSELMUNDO *"
		},

	]

	React.useEffect(() => {
		async function obtenerAlianzas() {
			setAlianzas(await getAllAliances(0));
			setLoader(false);
		}
		obtenerAlianzas();
		window.addEventListener('actualizar-alianza', obtenerAlianzas);
		return () => {
			window.removeEventListener('actualizar-alianza', obtenerAlianzas);
		};
	}, []);

	React.useEffect(() => {
		const cargarImagen = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_ALIANZAS`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagen();
	}, [renderKey]);

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}

	return (
		<div className='fondoAlianzas' style={{ backgroundImage: `url(${image?.url})` }}>
			<header>
				<NavBarRosa />
			</header>
			{isSuperAdmin && (
				<div className='fondoAlianzasButton' onClick={() => handleOpenWidget()}>
					<i className='bi bi-pencil'></i>
					cambiar fondo
				</div>
			)}
			{/* <main className=' py-5 containerAlianzas'>
				{loader ? (
					<Loader />
				) : (
					alianzas.alianzas?.map((alianza) => {
						return <Alianza alianza={alianza} />;
					})
				)}
			</main> */}
			<main className=' py-5 containerAlianzas'>
				<Toaster
					position='bottom-left'
					reverseOrder={false}
					toastOptions={{ className: 'fondoToast' }}
				/>

				{loader ? (
					<Loader />
				) : (
					<Box>
						<Box style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",width:"100vw", gap:"15px", marginTop:"5vh", marginBottom:"5vh"}}>
						<Typography  style={{
								fontFamily:"Dancing", 
								color:"#5c5c5c", 
						// height:"60px",
								fontWeight:550,
								fontSize:!isMobile?"75px":"45px",
              }} 
                >
      Beauti Comunidad
    </Typography>
		{preguntas?.map((p, index) => (
        <Accordion
          key={index}
          sx={{ backgroundColor: 'transparent', width: !isMobile ? "50vw" : "100vw", borderRadius: '5px', '::before': { backgroundColor: '#5c5c5c0 !important' } }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowRightRoundedIcon sx={{ fontSize: '24px', color: '#3483FA' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: "#f4c6c950", border: 'none !important', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}
          >
            <Box style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
              <Typography sx={{ fontFamily: "Montserrat", gap: '5px', display: 'flex', fontSize: isMobile ? "12px" : '25px', fontWeight: '400', alignItems: 'center', color: '#5c5c5c' }}>
                {p?.pregunta}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: 'rgba(32, 86, 63, 0.17)' }}>
            <Box style={{ cursor: "pointer", width: "100%", justifyContent: "space-between", display: "flex", padding: '0px 0px 0px 0px', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0)' }}>
              <Box style={{ display: "flex", gap: "10px" }}>
                <Box style={{ width: "5px", height: "", backgroundColor: "#20563f", borderRadius: "5px" }}></Box>
                <div
                  style={{ fontFamily: "Montserrat", gap: '5px', display: 'flex', flexDirection:"column", fontSize: isMobile ? '16px' : "20px", fontWeight: '600', padding: "25px", alignItems: 'center', justifyContent: "center", color: '#898484' }}
                  dangerouslySetInnerHTML={{ __html: p?.respuesta }}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
						</Box>

						<Box >
						<Typography  style={{
								fontFamily:"Dancing", 
								color:"#5c5c5c", 
								fontWeight:550,
								fontSize:!isMobile?"75px":"45px",
              }} 
                >
      Beauti Alianzas
    </Typography>
						{agruparEnPares(alianzas?.alianzas || []).map((fila, index) => (
						<div key={index} className='row'>
							{fila.map((alianza) => (
								<div key={alianza._id} className='col-md-6'>
									<Alianza alianza={alianza} />
								</div>
							))}
						</div>
					))
				}

						</Box>

						<Box style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",width:"100vw", gap:"15px", marginTop:"5vh", marginBottom:"5vh"}}>
						<Typography  style={{
								fontFamily:"Dancing", 
								color:"#5c5c5c", 
						// height:"60px",
								fontWeight:550,
								fontSize:!isMobile?"75px":"45px",
              }} 
                >
      Políticas Beautiqueras
    </Typography>
		{politicas?.map((p, index) => (
        <Accordion
          key={index}
          sx={{ backgroundColor: 'transparent', width: !isMobile ? "50vw" : "100vw", borderRadius: '5px', '::before': { backgroundColor: '#5c5c5c0 !important' } }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowRightRoundedIcon sx={{ fontSize: '24px', color: '#3483FA' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: 'rgba(32, 86, 63, 0.17)', border: 'none !important', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}
          >
            <Box style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
              <Typography sx={{ fontFamily: "Montserrat", gap: '5px', display: 'flex', fontSize: isMobile ? "12px" : '25px', fontWeight: '400', textAlign:"left", color: '#5c5c5c' }}>
                {p?.pregunta}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#f4c6c950" }}>
            <Typography variant="body2">
              {p?.respuesta?.split('\n').map((paragraph, idx) => (
                <Typography key={idx} style={{ fontFamily: "Montserrat", textAlign: "left", fontSize:isMobile?"12px":"14px" }} gutterBottom>
                  {paragraph.split(/(\*[^*]*\*)/).map((text, idx) => {
                    if (text.startsWith('*') && text.endsWith('*')) {
                      return <strong key={idx}>{text.slice(1, -1)}</strong>;
                    } else {
                      return text;
                    }
                  })}
                </Typography>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
						</Box>


					</Box>


			
				)}
			</main>
			<GloboContacto />
			<VideoInfo />
			<footer>
				<Footer />
			</footer>
		</div>
	);
}
