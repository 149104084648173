import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { DropdownButton, Dropdown, Navbar, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../utils/auth/auth';
import logo from '../../assets/logo_info.png';
import bolsita from '../../assets/BOOLSA_CARRITO.png';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { isAuthenticated } from '../../utils/auth/auth';
import './NavBarRosa.Module.css';
import { setUserInfo } from '../../features-slice/user/userSlice';
import { clearInfo } from '../../features-slice/user/userSlice';
import { isEditMode } from '../../utils/users/users';
import { useMediaQuery } from 'react-responsive';


function NavBarRosa({ tipoUsuario, nombre, puntos }) {
	const isSmallScreen = useMediaQuery({ maxWidth: 1400 }); // Cambio de imagen en pantallas pequeñas
	const user = useSelector((state) => state.user.userInfo);
	const idUsuario = localStorage.getItem('Usuario');
	const[editMode, setEditMode] = React.useState(false)

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [userLoading, setUserLoading] = React.useState(true);

	const cerrarSesion = (e) => {
		e.preventDefault();

		window.location.replace('/');
		setTimeout(() => {
			dispatch(clearInfo());
		}, 1500);
		logout();
	};

	// const uri = process.env.REACT_APP_BACK_URL || 'http://localhost:8080';
	const titleDrop = () => {
		if (user.role !== 'ADMIN_ROLE' && user.role !== 'SUPERADMIN_ROLE') {
			return (
				<div className='dropdowncito2'>
					<i className='bi bi-gem'></i>
					{user?.puntosAcumulados === null ? '0' : `${user.puntosAcumulados}`}
				</div>
			);
		} else {
			return (
				<div className=' dropdowncito '>
					<p className='titleDrop'>ADMIN</p>
				</div>
			);
		}
	};

	React.useEffect(() => {
		const isEdit = async () => {

			const rta = await isEditMode();

			// console.log("la rta", rta)
	setEditMode(rta)
		
		};
	
		isEdit()
	
	}, []);
	;
	
	

	React.useEffect(() => {
		if (isAuthenticated() && !user.role) {
			async function recargarEstado() {
				try {
					const userQuery = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/usuarios/${idUsuario}`);
					dispatch(setUserInfo(userQuery.data));
				} catch (error) {
					console.log(error.response.data?.errors[0].msg);
				} finally {
					setUserLoading(false); // Marca que la carga ha finalizado, independientemente del resultado
				}
			}
			recargarEstado();
		}
	}, [user]);

	return (
		<div className='navbar navbar-expand-md border-bottom divNavBarRosa' style={{ zIndex: 2000000 }}>
			<Navbar
				// bg='light'
				expand='lg'
				className='container-fluid justify-content-space-between navbaaar'
				style={{ zIndex: 4000000 }}
			>
				<Navbar.Brand href='/' style={{width:isSmallScreen&&"20vw"}} className='logoRosa'>
					<img src={logo} alt='Logo LABeautiqueria Market' />
				</Navbar.Brand>
				<Navbar.Toggle 	style={{marginBottom:isSmallScreen&& "30px", marginRight: isSmallScreen && "25px"}}className='custom-toggle-image' aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ml-auto nav nav-pills navbar-nav me-auto mb-2 mb-md-0 derecha'>
					<section style={{display:"flex", justifyContent:"flexEnd", gap:"0.5vw"}}>
							<Link style={{fontSize: "14px"}} to='/landing' className='nav-link linkNavBar'>
								Home
							</Link>
							<Link style={{fontSize: "14px"}} to="/cartilla/nails" className='nav-link linkNavBar'>
								Nails
							</Link>
							<Link style={{fontSize:"12px"}} to="/cartilla/minibeautiqueras" className='nav-link linkNavBar'>
								Mini beautiqueras
							</Link>
							<Link style={{fontSize: "14px"}}to='/cartilla/beautibox' className='nav-link linkNavBar'>
								Beautibox
							</Link>
							{(!editMode )  && (

							<Link style={{fontSize: "14px"}}to='/markethome' className='nav-link linkNavBar'>
								Market
							</Link>
							)}
							<Link style={{fontSize: "12px"}} to='/cartilla/w&e' className='nav-link linkNavBar'>
							Events | Workshops
							</Link>
							
						
							<Link style={{fontSize:"12px"}} to='/alianzas' className='nav-link linkNavBar'>
								Beauti Comunidad
							</Link>
              
							<Link style={{fontSize: "14px"}}to='/contacto' className='nav-link linkNavBar'>
								Contacto
							</Link>
							{user.role !== undefined ? (
								<DropdownButton
							
									className='dropdowncito dropNav'
									id='dropdown-basic-button'
									title={titleDrop()}
								>
									<div className='container-items' style={{marginRight:!isSmallScreen&&"45px"}}>
										{(user.role === 'ADMIN_ROLE' || user.role === 'SUPERADMIN_ROLE') && (
											// <Dropdown.Item>
											<Link className='link-drop' to='/dashboard'>
												Dashboard
											</Link>

											// </Dropdown.Item>
										)}
										{user.role === 'SUPERADMIN_ROLE' && (
											// <Dropdown.Item>
											<Link className='link-drop' to='/stats'>
												Estadisticas
											</Link>

											// </Dropdown.Item>
										)}
										{(user.role === 'ADMIN_ROLE' || user.role === 'SUPERADMIN_ROLE') && (
											// <Dropdown.Item>
											<Link className='link-drop' to='/cajadiaria'>
												Caja diaria
											</Link>

											// </Dropdown.Item>
										)}
										{(user.role === 'ADMIN_ROLE' || user.role === 'SUPERADMIN_ROLE') && (
											// <Dropdown.Item>
											<Link className='link-drop' to='/iniciarSesion'>
												Pantalla Login
											</Link>

											// </Dropdown.Item>
										)}
										{/* <Dropdown.Item> */}
										<Link className='link-drop' to='/perfil'>
											Mi perfil
										</Link>
										{/* </Dropdown.Item> */}
										<Dropdown.Item
											className='link-drop'
											href='#action/2'
											onClick={(e) => cerrarSesion(e)}
										>
											Cerrar sesión
										</Dropdown.Item>
									</div>
								</DropdownButton>
							) : (
								<div  className='iniciarSesion'>
									<Link to={'/iniciarSesion'} className='iniciarSesionLink'>
										<button className='btn btn-sm btn-outline-secondary iniciarSesionButton'>
											Iniciar Sesión
										</button>
									</Link>
								</div>
							)}
						</section>
						{/* <section>
					
						</section> */}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
}

export default NavBarRosa;