import React from 'react';
import './LoginNavBar.Module.css';
import logo from '../../assets/logo_info.png';

function LoginNavBar() {
	return (
		<div className='navbarLogin'>
			<img src={logo} alt='imgLogo' />
		</div>
	);
}

export default LoginNavBar;
