import axios from 'axios';
import toast from 'react-hot-toast';

const token = localStorage.getItem('Token');
const config = {
	headers: {
		xtoken: `${token}`,
	},
};

// export const obtenerCostoProductos = (carrito) => {
// 	const initialValue = 0;
// 	return carrito.reduce((acc, itemActual) => {
// 		return (
// 			acc +
// 			(itemActual.precioOferta ? itemActual.precioOferta : itemActual.precioEfectivo) *
// 				(itemActual.cantidad || 1)
// 		);
// 	}, initialValue);
// };
export const obtenerCostoProductos = (carrito, paymentMethod) => {
	const initialValue = 0;
	return carrito.reduce((acc, itemActual) => {
		const precio =
			paymentMethod === 'TARJETA'
				? itemActual.precioTarjeta
				: itemActual.precioOferta !== 0 && itemActual.precioOferta
				? itemActual.precioOferta
				: itemActual.precioEfectivo;

		const cantidad = itemActual.cantidad || 1;

		return acc + precio * cantidad;
	}, initialValue);
};

// export const obtenerCostoProductosServicios = (productosSeleccionados, beneficio) => {
// 	const initialValue = 0;

// 	// Obtener un array de los valores del objeto productosSeleccionados
// 	const productos = Object.values(productosSeleccionados);

// 	// Aplicar la misma lógica de acumulación
// 	const subtotal = productos.reduce((acc, productoActual) => {
// 		const precio = productoActual.precioElegido || 0;
// 		const cantidad = productoActual.cantidad || 1;
// 		return acc + precio * cantidad;
// 	}, initialValue);

// 	// Verificar si se ha proporcionado un beneficio
// 	if (beneficio) {
// 		if (beneficio.tipo === 'porcentaje') {
// 			// Aplicar descuento por porcentaje
// 			const descuento = (beneficio.valor / 100) * subtotal;
// 			const totalConDescuento = subtotal - descuento;
// 			return isNaN(totalConDescuento) ? 0 : totalConDescuento;
// 		} else if (beneficio.tipo === 'cantidad') {
// 			// Aplicar descuento por cantidad
// 			const totalConDescuento = subtotal - beneficio.valor;
// 			return isNaN(totalConDescuento) ? 0 : totalConDescuento;
// 		}
// 	}

// 	// Si no hay beneficio o no se aplica ningún descuento, devuelve el subtotal
// 	return isNaN(subtotal) ? 0 : subtotal;
// };

export const obtenerCostoProductosServicios = (productosSeleccionados, beneficio) => {
	const initialValue = 0;
	//const productos = Object.values(productosSeleccionados);
	let productos;

	if (Array.isArray(productosSeleccionados)) {
		// Si productosSeleccionados es un array, úsalo directamente
		productos = productosSeleccionados;
	} else if (typeof productosSeleccionados === 'object') {
		// Si productosSeleccionados es un objeto, conviértelo en un array de sus valores
		productos = Object.values(productosSeleccionados);
	} else {
		// En caso de entrada inválida, retorna un valor predeterminado (por ejemplo, 0)
		return 0;
	}

	const subtotal = productos.reduce((acc, productoActual) => {
		const precio = productoActual.precioElegido || 0;
		const cantidad = productoActual.cantidad || 1;
		return acc + precio * cantidad;
	}, initialValue);

	if (beneficio) {
		if (beneficio.tipo === 'porcentaje') {
			let descuento = 0;
			const nombreBeneficio = beneficio.nombre.toLowerCase();

			// Verificar si el nombre del beneficio contiene un guion
			if (nombreBeneficio.includes('-')) {
				const filtro = nombreBeneficio.split('-')[1];

				// Verificar si el filtro coincide con la categoría o tags de los productos
				productos.forEach((producto) => {
					const categoriaProducto = producto.categoria.nombre.toLowerCase().trim();
					const tagsProducto = producto.tags.map((tag) => tag.toLowerCase());

					// Convertir el filtro a minúsculas para hacer la comparación
					const filtroEnMinusculas = filtro.toLowerCase().trim();

					// Verificar si el filtro coincide con la categoría o tags del producto

					if (
						categoriaProducto === filtroEnMinusculas ||
						tagsProducto.includes(filtroEnMinusculas)
					) {
						descuento +=
							(beneficio.valor / 100) * (producto.precioElegido || 0) * producto.cantidad;
					}
				});
			} else {
				// Aplicar descuento por porcentaje sin filtro
				descuento = (beneficio.valor / 100) * subtotal;
			}

			const totalConDescuento = subtotal - descuento;

			return isNaN(totalConDescuento) ? 0 : totalConDescuento;
		} else if (beneficio.tipo === 'cantidad') {
			const totalConDescuento = subtotal - beneficio.valor;
			return isNaN(totalConDescuento) ? 0 : totalConDescuento;
		}
	}

	return isNaN(subtotal) ? 0 : subtotal;
};

export const obtenerPuntosProductos = (carrito) => {
	const initialValue = 0;
	return carrito.reduce((acc, itemActual) => {
		return acc + itemActual.puntos * itemActual.cantidad;
	}, initialValue);
};

export const obtenerPuntosProductosServicios = (productosSeleccionados) => {
	const initialValue = 0;

	// Obtener un array de los valores del objeto productosSeleccionados
	const productos = Object.values(productosSeleccionados);

	// Aplicar la misma lógica de acumulación
	return productos.reduce((acc, productoActual) => {
		return acc + productoActual.puntos * productoActual.cantidad;
	}, initialValue);
};

const uri = process.env.REACT_APP_BACK_URL;
export const getSales = async () => {
	const { data } = await axios.get(`${uri}/api/productos/ofertas`);
	return data;
};

export const getCategories = async () => {
	const { data } = await axios.get(`${uri}/api/categorias`);
	return data.categorias;
};

export const getMediosPago = async (desde) => {
	const { data } = await axios.get(`${uri}/api/mediospago?desde=${desde}&all=yes`);
	return data;
};

export const getUserCategories = async (desde) => {
	const { data } = await axios.get(`${uri}/api/categoriasusuario?desde=${desde}`);
	return data;
};

export const getAllCategories = async (desde) => {
	const { data } = await axios.get(`${uri}/api/categorias/all?desde=${desde}&limit=25`);
	return data;
};
export const ajustarPrecios = async (porcentaje,marca, tag, operacion) => {
	const data = { porcentaje: Number(porcentaje), marca:marca, tag:tag, operacion: operacion };

	try {
		await axios.post(
			`${process.env.REACT_APP_BACK_URL}/api/productos/ajustarprecios`,
			data,
			config
		);
		// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
		const event = new Event('actualizar-producto');
		window.dispatchEvent(event);

		toast.success('Precios actualizados con éxito');
	} catch (error) {
		toast.error('Error al actualizar producto');
	}
};

export const ajustarPuntos = async (porcentaje, marca, tag) => {
	const data = { porcentaje: Number(porcentaje), marca: marca, tag:tag };

	try {
		await axios.post(
			`${process.env.REACT_APP_BACK_URL}/api/productos/ajustarpuntos`,
			data,
			config
		);
		// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
		const event = new Event('actualizar-producto');
		window.dispatchEvent(event);

		toast.success('Perlas actualizadas con éxito');
	} catch (error) {
		toast.error('Error al actualizar las perlas');
	}
};

export const getProductsByCategories = async (categoria, desde, query) => {
	// Crear una matriz de cadenas de consulta para cada parámetro en query

	// console.log("recibo esto", categoria, desde, query)
	const queryParams = [];

	for (const key in query) {
		if (query.hasOwnProperty(key)) {
			// Si el valor es una cadena, divídela por comas y agrégala como una lista
			if (typeof query[key] === 'string') {
				queryParams.push(`${key}=${query[key].split(',').join(',')}`);
			} else {
				// De lo contrario, simplemente agrégalo como está
				queryParams.push(`${key}=${query[key]}`);
			}
		}
	}

	// Combinar las cadenas de consulta en una sola cadena usando "&"
	const queryString = queryParams.join('&');

	const { data } = await axios.get(
		`${uri}/api/productos?categoria=${categoria}&desde=${desde}&${queryString}`
	);
	return data;
};

export const getFilters = async (categoria) => {
	const { data } = await axios.get(`${uri}/api/productos/filtros/${categoria}`);
	return data;
};

export const getProducts = async (desde, consumoInterno) => {
const url = consumoInterno ? `${uri}/api/productos?desde=${desde}&consumoInterno=true`:`${uri}/api/productos?desde=${desde}`
	const { data } = await axios.get(url);
	return data;
};

export const getProductById = async (id) => {
	const { data } = await axios.get(`${uri}/api/productos/${id}`);
	return data;
};

export const getProductosInternos = async (categoria, limit) => {
	const { data } = await axios.get(`${uri}/api/productos?categoria=${categoria}&limit=${limit}`);
	return data;
};

export const getServicios = async (categoria, limit) => {
	const { data } = await axios.get(`${uri}/api/productos?categoria=${categoria}&limit=${limit}`);
	return data;
};


export const searchProducts = async (categoria, termino, desde, limit,superadmin,consumoInterno) => {

	const { data } = await axios.get(
		`${uri}/api/buscar/productos/${termino}?desde=${desde}&categoria=${categoria}&limit=${limit}&superadmin=${superadmin}&consumoInterno=${consumoInterno}`
	);
	return data;
};

export const searchAllProducts = async (termino, desde, superadmin ) => {
	
  // Construye la URL con el parámetro superadmin si es true
  const url = superadmin
    ? `${uri}/api/buscar/productos/${termino}?desde=${desde}&superadmin=true&consumoInterno=false`
    : `${uri}/api/buscar/productos/${termino}?desde=${desde}`;

  const { data } = await axios.get(url);
  return data;
};

export const searchMedios = async (termino, desde) => {
  const { data } = await axios.get(`${uri}/api/buscar/medios/${termino}?desde=${desde}`);
  return data;
};

export const ordenarProductos = (productos, tipoOrdenamiento) => {
	if (tipoOrdenamiento === 'precioMenor') {
		return [...productos].sort((a, b) => a.precioEfectivo - b.precioEfectivo);
	} else if (tipoOrdenamiento === 'precioMayor') {
		return [...productos].sort((a, b) => b.precioEfectivo - a.precioEfectivo);
	} else if (tipoOrdenamiento === 'nombreA') {
		return [...productos].sort((a, b) => a.nombre.localeCompare(b.nombre));
	} else if (tipoOrdenamiento === 'nombreZ') {
		return [...productos].sort((a, b) => b.nombre.localeCompare(a.nombre));
	}

	// Si el tipo de ordenamiento no es válido, devuelve la lista sin cambios
	return productos;
};

export const isNewProduct = (creationDate) => {
	const currentDate = new Date();
	const productCreationDate = new Date(creationDate);
	const timeDifference = currentDate - productCreationDate;
	const daysDifference = timeDifference / (1000 * 3600 * 24); // Convertir a días

	return daysDifference <= 30; // Determinar si es un producto nuevo en los últimos 30 días
};

// Función auxiliar para dividir un array en fragmentos
export function chunkArray(array, chunkSize) {
	const chunkedArray = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		chunkedArray.push(array.slice(i, i + chunkSize));
	}
	return chunkedArray;
}
