import React from 'react';
import { Table } from 'react-bootstrap';

const AliancesTable = ({ aliances, onAlianceClick }) => {
	const [selected, setSelected] = React.useState(null);

	const handleAlianceClick = (aliance) => {
		setSelected(aliance);
		onAlianceClick(aliance);
	};
	return (
		<Table responsive="sm" striped bordered hover>
			<thead>
				<tr>
					<th>Nombre</th>
					<th>Descripcion</th>
				</tr>
			</thead>
			<tbody>
				{aliances?.map((aliance) => (
					<tr key={aliance._id} onClick={() => handleAlianceClick(aliance)}>
						<td className={selected?._id === aliance._id ? 'selected' : ''}>{aliance.nombre}</td>
						<td className={selected?._id === aliance._id ? 'selected' : ''}>
							{aliance.descripcion}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default AliancesTable;
