import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalNewProduct.Module.css';
import axios from 'axios';
import toast from 'react-hot-toast';
import { getAllCategories, getCategories } from '../../../utils/products/products';
import diamante from "../../../assets/CORAZON_TRISTE.png"

// Definir el componente EditModal
const ModalNewProduct = ({ show, handleClose }) => {
	const token = localStorage.getItem('Token');
	const [categorias, setCategorias] = React.useState([]);

	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	// Manejar el estado del formulario en el modal
	const [formData, setFormData] = React.useState({
		nombre: '',
		categoria: '',
		precioOferta: '',
		precioEfectivo: '',
		precioTarjeta: '',
		disponibles: '',
		descripcion: '',
		puntos: '',
		img: '',
		img2: '',
		vegan: false,
		tester:false,
		marca: '',
		variantes: [],
		tags: [],
		tags2: [],
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	// Manejar cambios en el estado del checkbox 'vegan'
	const handleCheckboxChange = (e) => {
		const { name, checked } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: checked,
		}));
	};

	// Manejar cambios en los campos de entrada de 'tags' y 'tags2'
	const handleTagsChange = (e, index, field) => {
		const { value } = e.target;
		setFormData((prevData) => {
			const updatedTags = [...prevData[field]];
			updatedTags[index] = value;
			return {
				...prevData,
				[field]: updatedTags,
			};
		});
	};

	// Manejar la adición de una nueva etiqueta en 'tags' o 'tags2'
	const handleAddTag = (field) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: [...prevData[field], ''], // Agrega un nuevo campo de entrada vacío
		}));
	};

	// Manejar la eliminación de una etiqueta en 'tags' o 'tags2' por índice
	const handleRemoveTag = (field, index) => {
		setFormData((prevData) => {
			const updatedTags = prevData[field] ? prevData[field].filter((_, i) => i !== index) : [];
			return {
				...prevData,
				[field]: updatedTags,
			};
		});
	};

	// Manejar cambios en las variantes
	const handleVarianteChange = (e, index, field) => {
		const { name, value } = e.target;

		setFormData((prevData) => {
			const updatedVariantes = [...prevData.variantes];
			updatedVariantes[index] = {
				...updatedVariantes[index],
				[name]: value,
			};
			return {
				...prevData,
				variantes: updatedVariantes,
			};
		});
	};
	// Manejar la eliminación de una variante por índice
	const handleRemoveVariante = (index) => {
		setFormData((prevData) => {
			const updatedVariantes = [...prevData.variantes];
			updatedVariantes.splice(index, 1);
			return {
				...prevData,
				variantes: updatedVariantes,
			};
		});
	};

	// Manejar la adición de una nueva variante
	const handleAddNewVariante = () => {
		setFormData((prevData) => ({
			...prevData,
			variantes: [
				...prevData.variantes,
				{
					color: '',
					disponibles: '',
				},
			],
		}));
	};

	const handleSaveData = async (formData) => {
		const data = formData;

		try {
			await axios.post(`${process.env.REACT_APP_BACK_URL}/api/productos/`, data, config);
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event('actualizar-producto');
			window.dispatchEvent(event);

			toast.success('Producto creado con éxito');
			setFormData({
				nombre: '',
				categoria: '',
				precioOferta: '',
				precioEfectivo: '',
				precioTarjeta: '',
				disponibles: '',
				descripcion: '',
				puntos: '',
				img: '',
				img2: '',
				vegan: false,
				tester: false,
				marca: '',
				variantes: [],
				tags: [],
				tags2: [],
			});
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error('Error al crear producto');
		}
	};

	// Manejar cambios en las imágenes (por ejemplo, usando el widget de Cloudinary)
	function handleOpenWidget(img) {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;
						setFormData((prevData) => ({
							...prevData,
							[img]: url,
						}));
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			console.log(error);
			toast.error('Error al cargar la imagen', error);
		}
	}

	// Manejar el evento de guardar los cambios en el modal
	React.useEffect(() => {
		const cargarCategorias = async () => {
			setCategorias(await getAllCategories(0));
		};
		cargarCategorias();
	}, []);
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Crear Producto</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}
				<div>
					<label htmlFor='nombre'>Nombre:</label>

					<input
						className='form-control'
						type='text'
						name='nombre'
						value={formData?.nombre}
						onChange={handleChange}
						placeholder='Nombre'
					/>
				</div>
				<div>
					<label htmlFor='marca'>Marca:</label>
					<input
						className='form-control'
						type='text'
						name='marca'
						value={formData?.marca}
						onChange={handleChange}
						placeholder='Marca'
					/>
				</div>
				{/* Agregar el select para la categoría */}
				<div>
					<label htmlFor='categoria'>Categoría:</label>
					<select
						className='form-control'
						name='categoria'
						value={formData?.categoria}
						onChange={handleChange}
					>
						{categorias.categorias?.map((categoria) => (
							<option key={categoria._id} value={categoria._id}>
								{categoria.nombre}
							</option>
						))}
					</select>
				</div>

				<div>
					<label htmlFor='descripcion'>Descripción:</label>
					<input
						className='form-control'
						type='text'
						name='descripcion'
						value={formData?.descripcion}
						onChange={handleChange}
						placeholder='Descripcion'
					/>
				</div>

				<div>
					<label htmlFor='vegan'>Producto Vegano:</label>
					<input
						type='checkbox'
						name='vegan'
						checked={formData?.vegan}
						onChange={handleCheckboxChange}
					/>
				</div>
				<div>
					<label htmlFor='vegan'>TESTER:</label>
					<input
						type='checkbox'
						name='tester'
						checked={formData?.tester}
						onChange={handleCheckboxChange}
					/>
				</div>

				<div>
					<label htmlFor='precioEfectivo'>Precio en efectivo:</label>
					<input
						className='form-control'
						type='text'
						name='precioEfectivo'
						value={formData?.precioEfectivo}
						onChange={handleChange}
						placeholder='Precio promo'
					/>
				</div>

				<div>
					<label htmlFor='precioOferta'>Precio de oferta:</label>
					<input
						className='form-control'
						type='text'
						name='precioOferta'
						value={formData?.precioOferta}
						onChange={handleChange}
						placeholder='Precio de oferta'
					/>
				</div>

				<div>
					<label htmlFor='precioTarjeta'>Precio de lista:</label>
					<input
						className='form-control'
						type='text'
						name='precioTarjeta'
						value={formData?.precioTarjeta}
						onChange={handleChange}
						placeholder='Precio de lista'
					></input>
				</div>

				<div>
					<label htmlFor='puntos'>Perlas:</label>
					<input
						className='form-control'
						type='text'
						name='puntos'
						value={formData?.puntos}
						onChange={handleChange}
						placeholder='Perlas'
					/>
				</div>

				<div>
					<label htmlFor='disponibles'>Stock:</label>
					<input
						className='form-control'
						type='text'
						name='disponibles'
						value={formData?.disponibles}
						onChange={handleChange}
						placeholder='Disponibles'
					/>
				</div>
				{/* Agregar otros campos aquí */}
				<br></br>
				{formData.variantes.map((variante, index) => (
					<div key={index}>
						<div className='tituloRemoveVariant'>
							<h5>Variante {index + 1}</h5>

							<abbr title='Eliminar variante' style={{ cursor: 'pointer' }}>
								<i
									className='bi bi-x-circle filterOptionX'
									onClick={() => handleRemoveVariante(index)}
								></i>
							</abbr>
						</div>
						<div>
							<label htmlFor={`variantes[${index}].color`}>Color:</label>
							<input
								className='form-control'
								type='text'
								name={'color'}
								value={variante.color}
								onChange={(e) => handleVarianteChange(e, index, 'variantes')}
								placeholder='Color'
							/>
						</div>

						<div>
							<label htmlFor={`variantes[${index}].disponibles`}>Disponibles:</label>
							<input
								className='form-control'
								type='text'
								name={'disponibles'}
								value={variante.disponibles}
								onChange={(e) => handleVarianteChange(e, index, 'variantes')}
								placeholder='Disponibles'
							/>
						</div>
					</div>
				))}
				<Button variant='success' size='sm' onClick={() => handleAddNewVariante()}>
					Agregar Variante
				</Button>

				<br></br>

				{/* Imagen 1 actual */}
				<label>Imagenes del producto</label>
				<section className='imgProductContainer'>
					<div className='imgEdit'>
						<img
							className='imgEditTag'
							src={formData?.img || diamante}
							alt='Imagen 1 actual'
							style={{ width: '150px', height: '150px', border: '1px solid black' }}
						/>
						<Button variant='primary' onClick={() => handleOpenWidget('img')}>
							Actualizar Imagen 1
						</Button>
					</div>

					{/* Imagen 2 actual */}
					<div className='imgEdit'>
						<img
							className='imgEditTag'
							src={formData?.img2 || diamante}
							alt='Imagen 2 actual'
							style={{ width: '150px', height: '150px', border: '1px solid black' }}
						/>
						<Button variant='primary' onClick={() => handleOpenWidget('img2')}>
							Actualizar Imagen 2
						</Button>
					</div>
				</section>
				<div>
					<label htmlFor='tags'>Tags:</label>
					{
							formData?.tags?.length > 0 ? (
					formData.tags.map((tag, index) => (
						<div className='removeTags' key={index}>
							<input
								className='form-control'
								type='text'
								name={`tags[${index}]`}
								value={tag}
								onChange={(e) => handleTagsChange(e, index, 'tags')}
								placeholder='Tag'
							/>
							<i
								className='bi bi-x-circle filterOptionX'
								onClick={() => handleRemoveTag('tags', index)}
							></i>
						</div>
					)
					)):(
					<br></br>
				)}
					<Button variant='success' size='sm' onClick={() => handleAddTag('tags')}>
						Agregar Tag
					</Button>
				</div>

				{/* Campos para 'tags2' (similar a 'tags') */}
				<div>
					<label htmlFor='tags2'>Tags2:</label>
					{					
					formData?.tags?.length > 0 ? (
					formData.tags2.map((tag, index) => (
						<div className='removeTags' key={index}>
							<input
								className='form-control'
								type='text'
								name={`tags2[${index}]`}
								value={tag}
								onChange={(e) => handleTagsChange(e, index, 'tags2')}
								placeholder='Tag2'
							/>
							<i
								className='bi bi-x-circle filterOptionX'
								onClick={() => handleRemoveTag('tags2', index)}
							></i>
						</div>
					)
					)):(
					<br></br>
				)}
					<Button variant='success' size='sm' onClick={() => handleAddTag('tags2')}>
						Agregar Tag2
					</Button>
				</div>
				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveData(formData)}
					>
						Crear nuevo producto
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalNewProduct;
