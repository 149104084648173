import React from 'react';
import { Table } from 'react-bootstrap';

const MediosPagoTable = ({ mediosPago, onMedioPagoClick }) => {
	const [selected, setSelected] = React.useState(null);

	const handleMedioPagoClick = (medioPago) => {
		setSelected(medioPago);
		onMedioPagoClick(medioPago);
	};
	return (
		<Table  responsive="sm" striped bordered hover>
			<thead>
				<tr>
					<th>Nombre</th>
          <th>Descripcion</th>
					<th>Color</th>
          <th>Estado</th>
				</tr>
			</thead>
			<tbody>
				{mediosPago?.map((medio) => (
					<tr key={medio._id} onClick={() => handleMedioPagoClick(medio)}>
						<td className={selected?._id === medio._id ? 'selected' : ''}>{medio.nombre}</td>
            <td className={selected?._id === medio._id ? 'selected' : ''}>{medio.descripcion}</td>
            <td style={{backgroundColor:medio.color}}></td>
						<td className={selected?._id === medio._id ? 'selected' : ''}>
							{medio?.estado?.toString()}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default MediosPagoTable;