import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './VideoHome.Module.css';
import toast from 'react-hot-toast';

function VideoHome() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [showVideo, setShowVideo] = React.useState(true);

	const user = useSelector((state) => state.user.userInfo);

	const [video, setVideo] = React.useState('');
	const [isEditing, setIsEditing] = React.useState(false);
	const [newVideo, setNewVideo] = React.useState('');
	const [renderKey, setRenderKey] = React.useState(0);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';

	React.useEffect(() => {
		const cargarVideo = async () => {
			const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=VIDEO`);
			setVideo(data.imagenes);
		};
		cargarVideo();
	}, [renderKey]);

	const handleCloseVideo = () => {
		setShowVideo(false);
	};

	useEffect(() => {
		setShowVideo(true);
	}, []);

	const handleEditVideo = () => {
		setIsEditing(true);
		setNewVideo(video[0]?.url);
	};

	const handleCancelEditVideo = () => {
		setIsEditing(false);
	};

	const handleSaveVideo = async () => {
		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes/${video[0]._id}?url=${newVideo}`,
				'',
				config
			);
			setVideo([{ url: newVideo }]);
			setIsEditing(false);
			toast.success('Video actualizado con éxito!');
		} catch (error) {
			toast.error('Hubo un problema al cambiar el video');
		}
	};

	const handleVideoInputChange = (event) => {
		setNewVideo(event.target.value);
	};

	const playerConfig = {
		fullscreen: false, // Evitar que el video se abra en pantalla completa
	};

	return (
		<>
			{showVideo && (
				<div className='videoContainer'>
					<div className='circular'>
						{isSuperAdmin && (
							<button className='edit-icon-video' onClick={() => handleEditVideo()}>
								<i className='bi bi-pencil'></i>
							</button>
						)}
						{isEditing ? ( // Renderizar el campo de entrada y el botón de guardar cuando se está editando
							<>
								<input
									type='text'
									value={newVideo}
									onChange={handleVideoInputChange}
									className='video-input'
								/>
								<button className='saveVideo' onClick={handleSaveVideo}>
									Guardar
								</button>
								<button className='cancelEditVideo' onClick={handleCancelEditVideo}>
									x
								</button>
							</>
						) : (
							<button onClick={handleCloseVideo}>x</button> // Mostrar el botón de cerrar cuando no se está editando
						)}
						<ReactPlayer
							className='custom-react-player'
							controls={false}
							url={video[0]?.url}
							playing={true}
							volume={0.1}
							loop={true}
							config={playerConfig}
							muted={true}
						/>
					</div>
				</div>
			)}
		</>
	);
}

export default VideoHome;
