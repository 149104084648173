// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import toast from 'react-hot-toast';

import axios from 'axios';

// Definir el componente EditModal
const ModalEditCategory = ({ show, handleClose, category }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	// Manejar el estado del formulario en el modal
	const [formData, setFormData] = React.useState({
		nombre: category.nombre,
		estado: category.estado,
		img: category.img,
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;

		// Si el campo es 'estado', convertir el valor a un booleano
		if (name === 'estado') {
			setFormData((prevData) => ({
				...prevData,
				[name]: value === 'true', // 'true' se convierte en true, 'false' se convierte en false
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
	};

	// Manejar cambios en las imágenes (por ejemplo, usando el widget de Cloudinary)
	function handleOpenWidget(img) {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;
						setFormData((prevData) => ({
							...prevData,
							[img]: url,
						}));
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			console.log(error);
			toast.error('Error al cargar la imagen', error);
		}
	}

	const handleSaveDataCategory = async (formData) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/categorias/${category?._id}`,
				formData,
				config
			);
			// Disparar un evento personalizado 'actualizar-alianza' cuando se haya actualizado el producto
			const event = new Event('actualizar-categoria');
			window.dispatchEvent(event);

			toast.success('Categoria actualizada con éxito');
			setFormData({
				nombre: '',
				estado: '',
				img: '',
			});
			handleClose();
		} catch (error) {
			toast.error('Error al actualizar la categoria');
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Editar Categoria {category?._id}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}

				<div>
					<label htmlFor='nombre'>Nombre:</label>

					<input
						className='form-control'
						type='text'
						name='nombre'
						value={formData?.nombre}
						onChange={handleChange}
						placeholder='Nombre'
					/>
				</div>

				<div>
					<label htmlFor='estado'>Estado:</label>
					<select
						className='form-control'
						name='estado'
						value={formData?.estado.toString()}
						onChange={handleChange}
					>
						<option value={true}>Activa</option>
						<option value={false}>Inactiva</option>
					</select>
				</div>
				{/* Imagen 1 actual */}
				<section className='imgProductContainer'>
					<div className='imgEdit'>
						<img
							className='imgEditTag'
							src={formData?.img}
							alt='Imagen actual'
							style={{ width: '150px', height: '150px', border: '1px solid black' }}
						/>
						<Button variant='primary' onClick={() => handleOpenWidget('img')}>
							Actualizar Imagen
						</Button>
					</div>
				</section>
				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveDataCategory(formData)}
					>
						Guardar Cambios
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditCategory;
