import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './CarouselOfertas.Module.css';
import Card from '../card/Card';
import { useMediaQuery } from 'react-responsive';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};
// Función para procesar la cadena


function CarouselOfertas({ sales }) {
	const isMobile = useMediaQuery({ maxWidth: 700 });
	return (
		<div className='container py-5'>
			<h2 className='py-5 tituloOfertas'>Aprovechá nuestras <b>#BeautiGangas</b> </h2>
			<Carousel
				draggable={true}
				// showDots={true}
				// swipeable={true}
				swipeable={!isMobile} // Deshabilita swipeable en dispositivos móviles
				responsive={responsive}
				infinite={true}
				autoPlay={true}
				keyBoardControl={true}
				//autoPlay={props.deviceType !== 'mobile' ? true : false}
				autoPlaySpeed={5000}
				containerClass='carouselOfertas-container py-2'
				removeArrowOnDeviceType={['tablet']}

				//className='testimoniosCarousel'
			>
				{sales &&
					sales.productos?.map((sale, index) => {
						return (
							<div className='itemOfertaHome' key={index}>
								<Card sale={sale}  isMobile={isMobile}/>
							</div>
						);
					})}
			</Carousel>
		</div>
	);
}

export default CarouselOfertas;
