/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Typography, Stack, Box, Backdrop, CircularProgress, BackdropRoot, Paper, Grid, Dialog, DialogContent, DialogTitle } from "@mui/material";

import Card from '../../components/card/Card';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import NavBar from '../../components/navBar/NavBar';
import NavBarRosa from '../../components/navBarRosa/NavBarRosa';
import Pagination from '../../components/pagination/Pagination';
import { useParams, useNavigate } from 'react-router-dom';
import { searchProducts, ordenarProductos } from '../../utils/products/products';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { getProductsByCategories, getFilters } from '../../utils/products/products';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';

import VideoHome from '../../components/videoHome/VideoHome';
import DetailItemInfo from '../../components/detailItemInfo/DetailItemInfo';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Carousel from 'react-multi-carousel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import fondomini from "../../assets/fondos/fondominibeauti.jpg"
import './Cartilla.Module.css';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { Key, Padding } from '@mui/icons-material';
import LogoMiniBeauti from "../../assets/images/logoMiniBeauti.png"




function Cartilla() {

  const bannerText = ["CONOCÉ NUESTRA SECCIÓN MINI BEAUTIQUERAS", "JUGÁ CON NOSOTRAS", "PRODUCTOS PARA LAS MINIS", "MINI BEAUTIQUERAS SHOP"]
  const widgetRef = React.useRef(null);
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

  const [currentBanner, setCurrentBanner] = React.useState(0)
	React.useEffect(() => {
    const interval = setInterval(() => {
  
            setCurrentBanner((prevBanner) => (prevBanner + 1) % bannerText.length);
    
    }, 3000); // Tiempo antes de cambiar al siguiente banner

    return () => clearInterval(interval);
}, []);
	const user = useSelector((state) => state.user.userInfo);

  const [showScrollButton, setShowScrollButton] = React.useState(false);

	const isMobile = useMediaQuery('(max-width: 768px)');

	const [scrollPosition, setScrollPosition] = React.useState(0);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [showEditMini, setShowEditMini] = React.useState(false)

  const handleShowMiniOpen =() => {
    setShowEditMini(true)
  }
  const handleShowMiniClose =() => {
    setShowEditMini(false)
  }

    // Función para manejar el evento de scroll
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      setScrollPosition(currentPosition);
      setShowScrollButton(currentPosition > 100); // Mostrar la flecha cuando el scroll supere los 100px
    };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

	const [image, setImage] = React.useState('');
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);
	const { categoriainfo } = useParams();
	const navigate = useNavigate();
  const [isWidgetOpened, setWidgetOpened] = React.useState(false);
	const [loader, setLoader] = React.useState(false);
  
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [productos, setProductos] = React.useState(null)
	const [imageChangeKey, setImageChangeKey] = React.useState(0);
  const [imagesMini, setImagesMini] = React.useState([])

  React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=MINI_INFO`
			);
			setImagesMini(data.imagenes);
			setLoader(false);
		};
		cargarImagenes();
	}, [imageChangeKey]);

  let categorias = 
  
  categoriainfo === "nails" 
  ? 
  ["65ec8514ac0453bdae7092e5", "65ec8570ac0453bdae7092ee","65ecc7f5ac0453bdae70ac44", 
  "65ecca96ac0453bdae70ac91", "65eccb1fac0453bdae70acbb","65eccc3aac0453bdae70ad18","65eccc48ac0453bdae70ad29"] 
  : 
  categoriainfo === "minibeautiqueras" 
  ? 
  ["64ef5c51eba3f1b3b8f25dce"] 
  :
  categoriainfo === "beautibox" 
  ? 
  ["65f5a61a39933e5cc805ab2d", "65f5ac3439933e5cc805ae3e","65f5b00639933e5cc805b038", "65f5bbb039933e5cc805bc45"] 
  :
  categoriainfo === "w&e" 
  ? 
  ["65f5bded39933e5cc805bcc9","65f5be0139933e5cc805bcd2","65f5be1a39933e5cc805bcdb","6648bea4c933a55e75dbc333"] 
  :
  []
  
  // console.log("categorias a buscar", categorias)


  const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};


	//-----------------------------------------------------------------------------
// 
React.useEffect(() => {

  const cargarProductos = async () => {
    try {
      setLoader(true)
      const productosPorCategoria = [];
      for (const categoriaId of categorias) {
        const {productos} = await getProductsByCategories(categoriaId);
        // console.log("productos en el for",productos)
        const categoriaTitulo = productos[0].categoria?.nombre; // Obtener el título de la categoría
        productosPorCategoria.push({ categoria: categoriaTitulo, productos }); // Agregar categoría y productos al array
      }
      setProductos(productosPorCategoria); // Actualizar el estado de productos con los productos por categoría
   
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error al cargar productos:', error);
      // Manejar el error aquí
    }finally{
      setTimeout(() => {
        
        setLoader(false)
      }, 500);
    }
  };

  cargarProductos();
  // categoriainfo === "minibeautiqueras" && loadProducts()
  // setLoader(false)
}, [categoriainfo]); // Ejecutar cuando cambie la variable categorias


const handleImageChange = async (image, index) => {
  const widgetOptions = {
    cloudName: 'dlblszxyo', // Reemplaza con el nombre de tu cuenta en Cloudinary
    uploadPreset: 'laBeautiqueria', // Reemplaza con el nombre del preset de carga en Cloudinary
    cropping: false, // Deshabilitar la opción de recorte
    sources: ['local', 'url', 'camera'], // Permitir cargar desde archivos locales, URL o cámara
    multiple: false, // Permitir solo una imagen a la vez
    resourceType: 'image', // Permitir solo la carga de imágenes
    clientAllowedFormats: ['jpg', 'png', 'jpeg'], // Formatos permitidos (puedes agregar otros si lo deseas)
    maxFileSize: 5000000, // Tamaño máximo del archivo (en bytes), aquí son 5 MB
  };

  try {
    if (widgetRef.current) {
      setWidgetOpened(true);
      widgetRef.current.open();
    } else {
      const widget = window.cloudinary.createUploadWidget(
        widgetOptions,
        async (error, result) => {
          if (!error && result && result.event === 'success' && result.info.secure_url) {
            const url = result.info.secure_url; //se guarda la url nueva de la img que se subio

            // Actualizar la imagen en el estado local
            setImagesMini((prevImages) =>
              prevImages.map((image, i) => (i === index ? { ...url, url } : image))
            );

            // Actualizar la imagen en el backend
            await axios.put(
              `${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
              '',
              config
            );

            // Incrementar el estado imageChangeKey para forzar la recarga del componente
            setImageChangeKey((prevKey) => prevKey + 1);
          } else if (error) {
            console.error('Error al cargar la imagen:', error);
          }
        }
      );

      widgetRef.current = widget;
      widget.open();
    }
  } catch (error) {
    console.error('Error al eliminar la imagen:', error);
  }
};


	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_LANDING`
			);
			setImage(data.imagenes[0]);
			// setLoader(false);
		};
		cargarImagenes();
	}, [renderKey]);

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}

  const scrollToCategory = (index) => {
    const element = document.getElementById(`categoria-${index}`);
    if (element) {
      const windowHeight = window.innerHeight;
      const elementHeight = element.clientHeight;
      const offsetTop = element.offsetTop;
  
      let scrollToPosition = offsetTop - (windowHeight - elementHeight) / 4;
      if (scrollToPosition < 0) {
        scrollToPosition = 0;
      }
  
      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth',
      });
    }
  };
  const urlCatalog  = `/market/categoria/64ef4986d269731b208134bf?nombre=MARKET&categoria=Mini Beautiqueras`

	return (
    <>
    {loader ? (
      <Loader />
    ) : (
      <div className='fondoLanding' >
        <header>
          <NavBarRosa />
        </header>
      
        {isSuperAdmin && (
              <div className='fondohome' onClick={() => handleOpenWidget()}>
                <i className='bi bi-pencil'></i>
                cambiar fondo
              </div>
            )}
        <main>
          <div className="background-image" style={{ paddingBottom:"45px", backgroundImage: categoriainfo === "minibeautiqueras"?`url(${fondomini})`:`url(${image?.url})` }}>   
          
          {
            productos?.length > 0 && (
              <Box >
               <Box style={{width:"100vw", display:"flex", justifyContent:!isMobile?"flex-start":"center", paddingLeft:!isMobile?"50px":"", marginBottom:isMobile?"5vh":""}} >
                  <Dropdown style={{top:!isMobile?"10vh":"12vh"}}>
                  <Dropdown.Toggle style={{width:!isMobile?"100px":"100vw", backgroundColor:"#e5c4c9", border:"none"}} id="dropdown-basic">
                    Servicios
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {productos?.map((categoria, index) => (
                      <Dropdown.Item key={index} onClick={() => scrollToCategory(index)}>
                        {categoria.categoria}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                </Box> 
              <Box sx={{paddingTop:"12vh"}}>
                {productos?.map((categoria, index) => (
                  <div  style={{display:"flex", flexDirection:"column", alignItems:"center"}} key={index}>
{/* 
<Box style={{
  display:"flex",
  marginBottom:isMobile?"5vh":"5vh",
  alignItems:"center", 
  justifyContent:"center", 
  borderRadius:"10px",
   width:"100vw", 
  height:"120px", 
  backgroundColor:"#5c5c5c1f",
  //  paddingBottom:"10px"
   }}> */}
{
categoriainfo !== "minibeautiqueras" ?
(

  <Typography  style={{
    fontFamily:"Dancing", 
    color:"#5c5c5c", 
// height:"60px",
    fontWeight:550,
    fontSize:!isMobile?"75px":"45px",
    // marginTop:"4vh",
    // marginBottom:isMobile?"5vh":"15vh",
    // textShadow: "0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #cf14a7, 0 0 30px #cf14a7, 0 0 40px #cf14a7, 0 0 55px #cf14a7, 0 0 75px #cf14a7"
  }}   
    id={`categoria-${index}`} 
    // className="neon"
    >
{categoria.categoria.charAt(0).toUpperCase() + categoria.categoria.slice(1).toLowerCase()}
</Typography>
) :(
  <img style={{width:isMobile?"90vw" : "35vw", marginTop:"-5vh"}} src={LogoMiniBeauti} alt='logo miniBeauti'/>
)


}
{/* </Box> */}
                    {categoria?.productos?.map((producto, index) => (
                      <DetailItemInfo key={index} producto={producto} />
                    ))}
                  </div>
                ))}
              </Box>
              </Box>

              
            )
          }     

{categoriainfo === "minibeautiqueras" && imagesMini?.length > 1 &&    (
  <Box sx={{width:isMobile?"100vw":"100vw", display:"flex", flexDirection:"column",gap:"25px", backgroundColor:"#f7de6a", position:"relative"}}>

    <Typography  style={{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
								fontFamily:"Montserrat", 
								color:"#fff",
                backgroundColor:"#f188b0", 
						    height:"50px",
								fontWeight:550,
                textAlign:"center",
								fontSize:!isMobile?"24px":"12px",
              }}   
                className="text-enter"
                >
                  {bannerText[currentBanner]}
  {/* PARA JUGAR EN CASA, CONOCE NUESTRA SECCION ESPECIAL DE PRODUCTOS PARA MINI BEAUTIQUERAS */}
    </Typography>

    {user && isSuperAdmin  && (
				<div style={{backgroundColor: "#1f50157d", width:"35px", height:"35px", borderRadius:"50%", marginLeft:"5vw",position:"absolute", cursor:"pointer"}}  onClick={handleShowMiniOpen}>
			
						<i className='bi bi-pencil'></i>
			
				</div>
			)}
  
    <Carousel

      draggable={true}
      showDots={false}
      swipeable={true}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      keyBoardControl={true}
      autoPlaySpeed={3000}
      shouldResetAutoplay={false} 
      pauseOnHover={false}
      // containerClass="carousel-container"
      removeArrowOnDeviceType={['tablet', 'mobile', "desktop"]}
    >
      {imagesMini?.map((producto, index) => (
        <img style={{width:isMobile?"250px":"350px",
        borderRadius:"5px",
        //  clipPath: "polygon(52% 21%, 80% 10%, 100% 21%, 100% 49%, 86% 73%, 50% 100%, 16% 75%, 0 50%, 0 23%, 20% 10%)",
        height:isMobile?"250px":"350px", objectFit:"cover"}} key={index} src={producto.url} alt={`Imagen de ${producto.nombre}`} />
      ))}
    </Carousel>

    <Box sx={{ backgroundColor:"#f188b0",height:"50px", display:"flex", alignItems:"center", justifyContent:"center"}}>
    <Button onClick={()=>navigate(urlCatalog)} variant="contained" style={{cursor:"pointer" ,backgroundColor:"#6acde0", fontWeight:600, width:"200px", height:"42px"}}>
								VAMOS ALLÁ!
								</Button>
    </Box>
  </Box>
)}
    <Dialog open={showEditMini} onClose={handleShowMiniClose}>
      <DialogTitle>Editar imagenes</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2} justifyContent="center">
            {imagesMini?.map((producto, index) => (
              <Grid item key={index}>
                <Box sx={{ position: "relative", width: "150px", height: "150px" }}>
                  <img
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }}
                    src={producto.url}
                    alt={`Imagen de ${producto.nombre}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#1f50157d",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer"
                    }}
                    onClick={() => handleImageChange(producto, index)}
                  >
                    <i className='bi bi-pencil'></i>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>


       {showScrollButton && (
                      <IconButton
                      className="scroll-to-top"
                      style={{
                          position: 'fixed',
                          bottom: '15vh',
                          right: '5vw',
                          zIndex: 1000,
                          cursor: 'pointer',
                          backgroundColor: 'rgba(32, 86, 63, 0.17)', // Cambiado el color de fondo
                          color: '#5c5c5c', // Cambiado el color de la flecha
                      }}
                      onClick={scrollToTop}
                      sx={{
                          '&:hover': {
                              backgroundColor: '#15432e', // Color de fondo en hover
                              color: 'white', // Color de la flecha en hover
                          },
                      }}
                  >
                            <KeyboardArrowUpIcon />
                        </IconButton>
                    )}   <GloboContacto />
              
            <Toaster
              position='bottom-left'
              reverseOrder={false}
              toastOptions={{ className: 'fondoToast' }}
            />
          </div>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    )}
  </>
	);
}

export default Cartilla;
