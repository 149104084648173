// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import es from 'date-fns/esm/locale/es';

import axios from 'axios';
import toast from 'react-hot-toast';

const ModalEditarCaja = ({ show, handleClose }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [editarCajaData, setEditarCajaData] = React.useState({
		fecha: '',
		nuevoEstado: 'CERRADA',
		saldoInicial: 0,
		notas: '',
	});

	// Manejar cambios en los campos del formulario
	const handleChange = (e) => {
		const { name, value } = e.target;
		setEditarCajaData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSaveData = async (editarCajaData) => {
		if (!editarCajaData.fecha) {
			return toast.error('Debes seleccionar una fecha');
		}

		const data = {};

		// Agregar fecha solo si está seleccionada
		if (editarCajaData.fecha) {
			data.fecha = format(editarCajaData.fecha, 'yyyy-MM-dd');
		}
		// Agregar notas solo si se agrego
		if (editarCajaData.notas) {
			data.notas = editarCajaData.notas;
		}
		// Agregar nuevoEstado solo si está seleccionado y no es una cadena vacía
		if (editarCajaData.nuevoEstado) {
			data.nuevoEstado = editarCajaData.nuevoEstado;
		}

		// Agregar saldoInicial solo si es mayor que 0
		const numberMonto = Number(editarCajaData.saldoInicial);
		if (numberMonto > 0) {
			data.saldoInicial = numberMonto;
		}

		try {
			const res = await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/cajadiaria/actualizarestado`,
				data,
				config
			);

			if (res.status === 200) {
				toast.success('Caja editada con éxito');
			} else {
				toast.error(res.data?.msg);
			}
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event('actualizar-caja');
			window.dispatchEvent(event);

			setEditarCajaData({
				fecha: '',
				nuevoEstado: '',
				saldoInicial: 0,
			});
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error(error.response.data?.msg);
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Editar Caja</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}

				<label>Fecha</label>
				<DatePicker
					className='form-control shadow'
					selected={editarCajaData.fecha}
					onChange={(date) => setEditarCajaData({ ...editarCajaData, fecha: date })}
					locale={es}
					dateFormat='dd-MM-yyyy'
				/>
				<div>
					<label>Estado de la Caja:</label>
					<select
						className='form-control'
						name='nuevoEstado'
						value={editarCajaData?.nuevoEstado}
						onChange={handleChange}
					>
						<option value='ABIERTA'>ABIERTA</option>
						<option value='CERRADA'>CERRADA</option>
					</select>
				</div>
				<div>
					<label htmlFor='nombre'>Saldo Inicial:</label>

					<input
						className='form-control'
						type='number'
						name='saldoInicial'
						value={editarCajaData?.saldoInicial}
						onChange={handleChange}
						placeholder='Aqui puedes editar el saldo inicial de la caja'
					/>
				</div>
				<div>
					<label htmlFor='nombre'>Notas:</label>

					<input
						className='form-control'
						type='text'
						name='notas'
						value={editarCajaData?.notas}
						onChange={handleChange}
						placeholder='Ingresa un comentario'
					/>
				</div>

				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveData(editarCajaData)}
					>
						Editar caja
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditarCaja;
