import React from 'react';


import 'react-multi-carousel/lib/styles.css';


import { Box, Typography } from "@mui/material";
import Carousel from 'react-multi-carousel';
import { useMediaQuery } from 'react-responsive';

// Importa tus logos
import logo1 from '../../assets/marcas/1.png';
import logo2 from '../../assets/marcas/2.png';
import logo3 from '../../assets/marcas/3.png';
import logo4 from '../../assets/marcas/4.png';
import logo5 from '../../assets/marcas/5.png';
import logo6 from '../../assets/marcas/6.png';
import logo7 from '../../assets/marcas/7.png';
import logo8 from '../../assets/marcas/8.png';
import logo9 from '../../assets/marcas/9.png';
import logo10 from '../../assets/marcas/10.png';
import logo11 from '../../assets/marcas/11.png';
import logo12 from '../../assets/marcas/12.png';
import logo13 from '../../assets/marcas/13.png';
import logo14 from '../../assets/marcas/14.png';
import logo15 from '../../assets/marcas/15.png';
import logo16 from '../../assets/marcas/16.png';
import logo17 from '../../assets/marcas/17.png';
import logo18 from '../../assets/marcas/18.png';
import logo19 from '../../assets/marcas/19.png';
import logo20 from '../../assets/marcas/20.png';
import logo21 from '../../assets/marcas/21.png';
import logo22 from '../../assets/marcas/22.png';
import logo23 from '../../assets/marcas/23.png';
import logo24 from '../../assets/marcas/24.png';
import logo25 from '../../assets/marcas/25.png';
import logo26 from '../../assets/marcas/26.png';
import logo27 from '../../assets/marcas/27.png';

// Añade más logos según sea necesario

function CarouselMarcas() {




	const isMobile = useMediaQuery({ maxWidth: 700 });


	// const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 7,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 4,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 3,
			slidesToSlide: 1,
		},
	};

	// Array de logos
	const logoImages = [
		{ url: logo1, nombre: 'Logo 1' },
		{ url: logo2, nombre: 'Logo 2' },
		{ url: logo3, nombre: 'Logo 3' },
    { url: logo4, nombre: 'Logo 4' },
		{ url: logo5, nombre: 'Logo 5' },
		{ url: logo6, nombre: 'Logo 6' },
    { url: logo7, nombre: 'Logo 7' },
		{ url: logo8, nombre: 'Logo 8' },
		{ url: logo9, nombre: 'Logo 9' },
    { url: logo10, nombre: 'Logo 10' },
		{ url: logo11, nombre: 'Logo 11' },
		{ url: logo12, nombre: 'Logo 12' },
    { url: logo13, nombre: 'Logo 13' },
		{ url: logo14, nombre: 'Logo 14' },
		{ url: logo15, nombre: 'Logo 15' },
    { url: logo16, nombre: 'Logo 16' },
		{ url: logo17, nombre: 'Logo 17' },
		{ url: logo18, nombre: 'Logo 18' },
    { url: logo19, nombre: 'Logo 19' },
		{ url: logo20, nombre: 'Logo 20' },
		{ url: logo21, nombre: 'Logo 21' },
    { url: logo22, nombre: 'Logo 22' },
		{ url: logo23, nombre: 'Logo 23' },
		{ url: logo24, nombre: 'Logo 24' },
    { url: logo25, nombre: 'Logo 25' },
		{ url: logo26, nombre: 'Logo 26' },
		{ url: logo27, nombre: 'Logo 27' },
		// Añade más logos según sea necesario
	];

	return (
		<Box sx={{backgroundColor:"white", marginTop:"15px", padding:"10px"}}>
<Typography sx={{fontFamily:"Montserrat", fontSize:isMobile?"14px":"10px", marginBottom:"10px"}}>Estas son algunas de las marcas que podrás encontrar en el <b>#beautimarket</b> </Typography>

			<Box sx={{ width: isMobile ? "100vw" : "100vw", 
        height: !isMobile?"75px":"45px"
        }}>
				<Carousel
					draggable={true}
					showDots={false}
					swipeable={true}
					responsive={responsive}
					infinite={true}
					autoPlay={true}
					keyBoardControl={true}
					autoPlaySpeed={2000}
					shouldResetAutoplay={false}
					pauseOnHover={false}
					removeArrowOnDeviceType={['tablet', 'mobile', "desktop"]}
				>

					{logoImages?.map((producto, index) => (
						<img style={{
               transform: isMobile && "scale(0.7)",
							borderRadius: "5px",
							height: "60px",

							objectFit: "cover"
						}} key={index} src={producto.url} alt={`Imagen de ${producto.nombre}`} />
					))}
				</Carousel>
			</Box>
		</Box>
	);
}

export default CarouselMarcas;
