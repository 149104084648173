import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Typography, Button, Box } from '@mui/material';
import './About.Module.css';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

export default function About() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const navigate= useNavigate()
	const user = useSelector((state) => state.user.userInfo);
	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);
	const [isEditing, setIsEditing] = React.useState(false);
	const [editedDescription, setEditedDescription] = React.useState('');
	const isMobileScreen = useMediaQuery({ maxWidth: 768 });
	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=ABOUT_US`
			);
			setImage(data.imagenes);
			setLoader(false);
		};
		cargarImagenes();
	}, [renderKey]);

	function handleOpenWidget() {
		const widgetCloudinary = window.cloudinary.createUploadWidget(
			{
				cloudName: 'dlblszxyo',
				uploadPreset: 'laBeautiqueria',
			},
			async (err, result) => {
				if (!err && result && result.event === 'success') {
					const url = result.info.secure_url;

					await axios.put(
						`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image[0]._id}?url=${url}`,
						'',
						config
					);

					setImage({ ...image[0], url });
					setRenderKey((prevKey) => prevKey + 1);
				}
			}
		);

		widgetCloudinary.open();
	}

	function handleDescriptionEdit() {
		setIsEditing(true);
		setEditedDescription(image[0]?.descripcion);
	}

	async function handleSaveDescription() {
		const data = {
			descripcion: editedDescription,
		};
		// Puedes guardar la descripción en el estado local y/o enviarla al backend si lo deseas
		await axios.put(`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image[0]._id}`, data, config);
		setIsEditing(false);
		setImage([{ ...image[0], descripcion: editedDescription }]);
		setRenderKey((prevKey) => prevKey + 1);
		// Tu código para guardar la descripción en el backend si es necesario
	}

	const urlCatalog  = `/market/categoria/64ef4986d269731b208134bf?nombre=MARKET&marca=BUNIBLISS`

	function handleCancelEdit() {
		setIsEditing(false);
	}

	return (
		<div className='aboutCont' style={{width:"100vw", display:"flex", flexDirection:isMobileScreen?"column":"row", marginTop:"5vh"}}>
			{image && (
				<div className='img heart-sape'>
					<img className='imgAbout' src={image[0]?.url} alt='beauty-team' />
					{isSuperAdmin && (
						<>
							<div className='edit-icon-about' onClick={() => handleOpenWidget()}>
								<i className='bi bi-pencil'></i>
							</div>
						</>
					)}
				</div>
			)}
			<div style={{ marginBottom:isMobileScreen&&"45px"}} className='text'>
				{isEditing ? (
					<>
						<textarea
							value={editedDescription}
							onChange={(e) => setEditedDescription(e.target.value)}
							rows='4'
							cols='50'
						/>
						<button className='btn btn-sm guardarDesc' onClick={handleSaveDescription}>
							Guardar descripción
						</button>
						{isEditing && (
							<div className='cancel-icon-about edit-icon-aboutText' onClick={handleCancelEdit}>
								<i className='bi bi-x'></i>
							</div>
						)}
					</>
				) : (
					<>
			 <Typography variant="body2">
        {image[0]?.descripcion?.split('\\n').map((paragraph, index) => (
          <React.Fragment key={`paragraph-${index}`}>
            <Typography 
              style={{ fontFamily: "Montserrat", textAlign: "center", color: "#fff", fontSize:isMobileScreen?"16px":"20px" }} 
              gutterBottom
            >
              {paragraph.split(/(\*.*?:)/).map((text, subIndex) => {
                if (text.startsWith('*')) {
                  return <strong key={`bold-${index}-${subIndex}`}>{text}</strong>;
                } else {
                  return <React.Fragment key={`text-${index}-${subIndex}`}>{text}</React.Fragment>;
                }
              })}
            </Typography>
          </React.Fragment>
        ))}
      </Typography>
						{/* <p>{image[0]?.descripcion}</p> */}
						{isSuperAdmin && (
							<div className='edit-icon-aboutText' onClick={handleDescriptionEdit}>
								<i className='bi bi-pencil'></i>
							</div>
						)}
					</>
				)}
				

					<Box onClick={() => navigate(urlCatalog)} 
					style={{
						display:"flex", 
						marginTop:"50px",
						alignItems:"center", 
						backgroundColor:"#e5c4c9", 
						border:"none", 
						justifyContent:"center",
						width:!isMobileScreen?"75%":"100vw",
						height:"60px", 
						borderRadius:"5px",
						cursor:"pointer",
					}} 
					
					>
					<Typography sx={{fontFamily:"Montserrat", color:"#fff", fontWeight:600}}>
						CONOCELAS
					</Typography> 
						</Box>

		
			</div>
		</div>
	);
}
