import React from 'react';
import { useMediaQuery } from 'react-responsive';

import './CarouselInfoItem.Module.css';

const CarouselInfoItem = ({ imageUrl, isSuperAdmin, onChangeImage }) => {
	    
	const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
	const handleClick = (event) => {
		event.stopPropagation(); // Evita que el evento se propague y cause conflictos con el widget
		onChangeImage();
	};

	return (
		<div className='carousel-item'>
			<img style={{height:isMobileScreen?"50vh":"82vh"}} src={imageUrl} alt='Carousel Item' />
			{isSuperAdmin && (
				<div className='edit-icon' onClick={handleClick}>
					<i className='bi bi-pencil'></i>
				</div>
			)}
		</div>
	);
};

export default CarouselInfoItem;
