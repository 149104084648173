import React from 'react';
import { Card, Typography, Button, Box } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import product1 from "../../assets/images/CORAZON-VERDE-TRANS.png";
import product2 from "../../assets/images/CORAZON-VERDE-TRANS-FONDO.png";
import { useSelector } from 'react-redux';
import './DetailItemInfo.Module.css';
import { useMediaQuery } from 'react-responsive';
import toast from 'react-hot-toast';
import ModalEditProduct from '../modals/modalEditProduct/ModalEditProduct';
import axios from 'axios';

function DetailItemInfo({ producto }) {

  const sale=producto
// console.log(sale)
  const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
  const img1 = producto.img ? producto.img : product1;
  const img2 = producto.img2 ? producto.img2 : product2;
  const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
  const [showModal, setShowModal] = React.useState(false);

  const isMobileScreen = useMediaQuery({ maxWidth: 768 });

  const numero = "+543416242464";
	const mensajeCompra = `Hola! Me gustaría obtener más info acerca de ${producto?.nombre}`;
	const encodedMensaje = encodeURIComponent(mensajeCompra);
	const whatsappLink = `https://api.whatsapp.com/send?phone=${numero}&text=${encodedMensaje}`;

	const handleWhatsapp = () => {
		window.open(whatsappLink, "_blank");
	};

  
	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleSaveData = async (formData) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/productos/${producto?._id}`,
				formData,
				config
			);
			const event = new Event('actualizar-producto');
			window.dispatchEvent(event);

			toast.success('Producto actualizado con éxito');
		} catch (error) {
			toast.error('Error al actualizar producto');
		}
	};

  const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
  };

  return (
    <Card style={{marginBottom:"65px",width:isMobileScreen?"100%":"60%"}}>
    
      <Box display="flex" flexDirection={isMobileScreen ? "column" : "row" } >
    
        <Box style={{ width:isMobileScreen?"100%":"50%", border:"none"}} flex={1}>
       
          <Carousel

            className={`detailCarouselCartilla`}
            draggable={true}
            showDots={false}
            swipeable={true}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            keyBoardControl={true}
            autoPlaySpeed={4000}
            containerClass='carousel-container-cartilla'
            removeArrowOnDeviceType={['tablet', 'mobile',"desktop"]}
          >
            
            <img style={{height:isMobileScreen && "50vh"}}src={img1} alt={`Imagen de`} className="detailImage" />
            <img style={{height:isMobileScreen && "50vh"}} src={img2} alt={`Imagen de`} className="detailImage" />
          </Carousel>
        </Box>
        <Box style={{display:"flex", flexDirection:"column", justifyContent:"space-between", border:"none" }}flex={1} p={2}>
   
          <Typography style={{fontFamily:"Montserrat"}} variant="h6" gutterBottom>
          
          {producto.nombre.substring(1)}

            {user && isSuperAdmin &&sale  && (
				<div style={{backgroundColor: "#1f50157d", width:"35px", height:"35px", borderRadius:"50%"}}  onClick={handleOpenModal}>
					<abbr title={`editar ${producto?.nombre}`}>
						<i className='bi bi-pencil'></i>
					</abbr>
				</div>
			)}
  
          </Typography>
  
<Typography variant="body2">
  {producto.descripcion.split('\\n').map((paragraph, index) => (
    <>
    <Typography key={index} style={{ fontFamily: "Montserrat", textAlign: "left" }} gutterBottom>
      {paragraph.split(/(\*.*?:)/).map((text, index) => {
        if (text.startsWith('*')) {
          return <strong key={index}>{text}</strong>;
        } else {
          return text;
        }
      })}
    </Typography>
    <Box style={{height:"5px"}}></Box>
    </>
  ))}
</Typography>


          <Button onClick={handleWhatsapp} variant="contained" style={{backgroundColor:sale?.categoria?.nombre === "MINI BEAUTIQUERAS"?"#70cae2":"#20563f"}}>
           {sale?.categoria?.nombre === "MINI BEAUTIQUERAS"?"Quiero más info":"Quiero mi turno"} 
          </Button>
        </Box>
      </Box>
      {showModal && (
				<ModalEditProduct
					show={showModal}
					handleClose={handleCloseModal}
					sale={producto?._id && sale}
					handleSave={handleSaveData}
				/>
			)}
    </Card>
  );
}

export default DetailItemInfo;
