import React from "react";
import "./Cart.Module.css";
import { Modal, Button } from "react-bootstrap";
import {  Typography, Stack, Box, Backdrop, CircularProgress, BackdropRoot, Paper, Grid, Dialog, DialogContent } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import bolsita from "../../assets/BOLSA_CARRITO.png";
import bolsaVacia from "../../assets/BOLSA_VACIA_OSCURA.png";
import { isAuthenticated } from "../../utils/auth/auth";
import ItemCart2 from "../../components/itemCart2/ItemCart2";
import { obtenerCostoProductos, obtenerPuntosProductos } from "../../utils/products/products";
import { currencyDisplay } from "../../utils/cart/cart";
import { getSales } from "../../utils/products/products";
import CarouselOfertasCart from "../../components/carousel/CarouselOfertasCart";
import { borrarCarrito } from "../../features-slice/cart/carritoSlice";
import ModalConfirmation from "../../components/modals/modalConfirmation/ModalConfirmation";
import toast from "react-hot-toast";
import { sendNewBuy } from "../../utils/cart/cart";
import { useMediaQuery } from 'react-responsive';

function Cart({ handleCloseCart, showCart }) {
	const carrito = useSelector((state) => state.carrito);
	const [metodoPago, setMetodoPago] = React.useState("EFECTIVO");
	const [costoProductos, setCostoProductos] = React.useState(0);
	const politicas = [
		{
			pregunta: "CAMBIOS Y DEVOLUCIONES MARKET LA BEAUTIQUERIA",
			respuesta: "*Toda mercadería será chequeada antes de ser despachada.* \n Ante un cambio y/o devolución de producto, las alternativas son:  \n ♡  *Reemplazar el producto en día y horario a coordinar.*  \nEl cambio debe ser solicitado vía Whatsapp 341-5140700 indicando: nombre y apellido, dirección, horario de retiro (en caso de corresponder), número de pedido, código y descripción del producto, cantidad, motivo del cambio o devolución. \nEl cambio solo se realizará en sucursal previo control de esta información vía whatsapp y aprobación del mismo. \n *IMPORTANTE* \nPara cambiar o devolver un producto éste deberá conservar el envase original y  etiquetas originales y presentar la info anteriormente mencionada (nombre completo y número de pedido). \nEstos requisitos son INDISPENSABLES. \nAdemás se deberá considerar que, para los cambios, los productos  comprados en fechas especiales con ofertas se recibirán por el mismo valor del precio en promoción. \n *En ningún caso se devuelve el dinero.* \n *♡ NO TIENEN CAMBIO:* \nesmaltes, aceites, fragancias y relacionados. Aquellos productos donde no se puede verificar la modificación de su contenido. \n *♡Contemplamos los siguientes casos:* \n -El producto recibido no es el correspondiente al que Ud. seleccionó y el mismo no presentara anomalías (el empaque está cerrado) se procederá a realizar el cambio. \n -El producto solicitado no es del agrado de la persona que lo recibió y el mismo no fue abierto y no presenta anomalías (el empaque está cerrado) se podrá solicitar el cambio. \n -Si ha recibido un producto dañado deberá realizar el reclamo al instante al número de whatsapp  341-5140700 donde se le indicarán los pasos a seguir. \n Para todos los casos deberá comunicarse dentro de los 10 días desde  la recepción del pedido forma que se le indique como proceder. \n *♡El envío está a cargo del consumidor.*"
		},
		{
			pregunta: "POLÍTICA DE TURNOS BEAUTIQUEROS ",
			respuesta: "Cuando reservas un turno para utilizar un servicio beautiquero, estás generando un compromiso con *La Beautiqueria* de asistencia y nosotras de brindarte el servicio elegido. \nComo en nuestro salón no se pide seña, si no se cumple con la asistencia y no se realiza la cancelación con los tiempos correspondientes, el turno disponible no podrá ser utilizado por otra persona. \nPor eso establecemos estas políticas tanto para reservar como para cancelar, así todas podemos disfrutar de esta experiencia Beautiquera. \n♡Los turnos deben ser confirmados, respondiendo el mensaje que se envía desde la institución el día/ turno anterior. \n En caso de no haber recibido dicha respuesta  4 horas antes la cita quedará anulada y el turno liberado. \n La no confirmación de la reserva, implica una cancelación de la misma. ♡En caso de no asistir a tu cita, la misma debe ser cancelada con al menos 12 hs. *SOLAMENTE VIA WHATSAPP* \n ♡Todas tenemos imprevistos y emergencias, por lo cual, luego de no asistir a tu cita más de una vez, y de no haberla cancelado con la anticipación solicitada,*se cobrará una multa del 50%* del valor del servicio solicitado. \n En caso de que no desees abonarla, nuestro equipo podrá reservarse el derecho de brindarte una nueva cita.\n ♡Con el fin de respetar el tiempo de toda nuestra comunidad y de nuestro team , te pedimos que llegues puntual a su cita. \n *Contamos con 10 minutos de tolerancia.* \nLamentablemente no podremos atenderte en el caso de sobrepasar este tiempo.\n BRINDAR UNA EXPERIENCIA ÚNICA ES NUESTRA PRIORIDAD. \nEN EL CASO QUE NO ESTÉS SATISFECHA CON EL SERVICIO OFRECIDO, POR FAVOR SOLICITAMOS QUE NOS LO COMUNIQUES DENTRO DE LAS 24HS SIGUIENTES. \nNUESTRO TEAM SE COMPROMETE A RESOLVERLO Y MEJORAR EN EL CASO QUE SEA NECESARIO, Y SIEMPRE BRINDARTE LA MEJOR RESPUESTA. \nGRACIAS POR ELEGIRNOS♡ * # BEUTIQUEMOSELMUNDO *"
		},
	
	]
	const user = useSelector((state) => state.user.userInfo);
	const isMobile = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
	const [showTerms, setShowTerms] = React.useState(false)
	const handleShowTerms = () => {setShowTerms(true)}
	const handleShowTermsClose = () => {setShowTerms(false)}
	const cantidadPuntos = obtenerPuntosProductos(carrito);
	const [showSales, setShowSales] = React.useState(true);
	const dispatch = useDispatch();
	//const navigate = useNavigate();

	// Lógica modal vaciar carrito
	const [showConfirmation, setShowConfirmation] = React.useState(false);
	const handleShowConfirmation = () => {
		setShowConfirmation(true);
	};
	const handleConfirmClearCart = () => {
		localStorage.removeItem("cc");
		dispatch(borrarCarrito());
		toast.success("Bag vacía!");
		setShowConfirmation(false); // Cierra el modal de confirmación
	};
	const handleCancelClearCart = () => {
		setShowConfirmation(false); // Cierra el modal de confirmación en caso de cancelación
	};

	//Lógica modal realizar pedido
	const [showPedido, setShowPedido] = React.useState(false);
	const handleShowPedido = () => {
		setShowPedido(true);
	};
	const handleConfirmPedido = async () => {
		await sendNewBuy(user._id, carrito, user, metodoPago, dispatch);

		setShowPedido(false); // Cierra el modal de confirmación
	};
	const handleCancelPedido = () => {
		setShowPedido(false); // Cierra el modal de confirmación en caso de cancelación
	};

	const handlerShowSales = () => {
		setShowSales(!showSales);
	};

	const [sales, setSales] = React.useState([]);
	React.useEffect(() => {
		async function getOfertas() {
			setSales(await getSales());
		}
		getOfertas();
	}, [carrito]);

	React.useEffect(() => {
		// Llama a la función obtenerCostoProductos con el carrito y el método de pago seleccionado
		const costo = obtenerCostoProductos(carrito, metodoPago);
		setCostoProductos(costo);
	}, [carrito, metodoPago]);

	// const verDetalle = () => {
	// 	navigate('/carrito');
	// };

	return (
		<div className="containerModalCart">
			<Modal show={showCart} onHide={handleCloseCart} size="md" className="modal-right">
				<Modal.Header closeButton className="headerCart">
					<Modal.Title>
						{!user.nombre ? (
							<div className="divTitulo">
								<h4 className="h4Modal">Debes registrarte o iniciar sesión</h4>
							</div>
						) : (
							<div className="divTitulo">
								<img src={bolsita} alt="bolsita" style={{ width: "60px", padding: "0 5px" }} />
								<h4 className="h4Modal">
									{carrito.length} Items en tu Beauti Bag
								</h4>
							</div>
						)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="containerBodyModal">
					<section className={showSales ? "sectionItemCart" : "sectionItemCartCerrado"}>
						{!carrito?.length ? (
							<div className="divBolsaVacia">
								<h3>La Beauti Bag está vacía!</h3>
								<img src={bolsaVacia} alt="bolsaVacia" />
							</div>
						) : (
							carrito?.map((producto, index) => {
								return <ItemCart2 producto={producto} index={index} key={index} />;
							})
						)}
					</section>

{/* 					
					{!isAuthenticated() ? (
						<div className="iniciarSesion">
							<Link to={"/iniciarSesion"} className="iniciarSesionLink">
								<button className="btn btn-sm btn-outline-secondary carrito">Iniciar Sesión</button>
							</Link>
						</div>
					) : (
						""
					)} */}
					{isAuthenticated() && carrito.length >0 ? (
						<section className="textoCart  ">
							<div className="divTransparencia pt-2 px-3" >

							<Typography style={{fontFamily:"Montserrat", textShadow:"none", fontSize:"12px", marginBottom:"15px"}}>
									Seleccioná tu medio de pago
								</Typography>	

						<Box style={{display:"flex", justifyContent:"space-around", width:"100%",marginBottom:"15px"}}>
							<Box onClick={()=>setMetodoPago("TARJETA")} style={{borderRadius:"10px",backgroundColor:metodoPago === "TARJETA"? "#20563f":"", cursor:"pointer",width:"40%", border:"1px solid #5c5c5c", alignItems:"center", display:"flex"}}>								
								<Typography style={{color:metodoPago === "TARJETA"? "white":"#5c5c5c",fontFamily:"Montserrat", textShadow:"none"}}>
									Tarjetas / Transferencias 
								</Typography>	
							</Box>

							<Box onClick={()=>setMetodoPago("EFECTIVO")} style={{borderRadius:"10px",backgroundColor:metodoPago === "EFECTIVO"? "#20563f":"",cursor:"pointer", width:"40%", border:"1px solid #5c5c5c", alignItems:"center", display:"flex", flexDirection:"column"}}>		
								<Typography style={{color:metodoPago === "EFECTIVO"? "white":"#5c5c5c",fontFamily:"Montserrat", textShadow:"none"}}>
									Efectivo 
								</Typography>
								<Typography style={{color:metodoPago === "EFECTIVO"? "white":"#5c5c5c",fontFamily:"Montserrat", textShadow:"none", fontSize:"12px"}}>
									*Solo en sucursal 
								</Typography>
							</Box>
						</Box>
						<Box style={{paddingTop:"30px",paddingBottom:"30px",borderTop: "0.2px solid #ddd9d9"}}>
						<Typography style={{fontFamily:"Montserrat", textShadow:"none", fontSize:"16px", marginBottom:"15px"}}>
									Subtotal compra ({carrito?.length} items)  <b>{currencyDisplay(costoProductos)}</b>
									</Typography>
						<Typography style={{width:"100%", background:"#e5c4c978",borderRadius:"5px", fontFamily:"Montserrat", textShadow:"none", fontSize:"16px", marginBottom:"15px", color:"#20563f"}}>
									*Con esta compra, sumás{" "}
									<b style={{color:"#20563f"}}>
										{cantidadPuntos} Perlas
									</b>
									</Typography>	
							
									</Box>
							</div>
							{/* <span className='vaciarCarrito px-4 py-2' onClick={() => verDetalle()}>
								<i className='bx bx-trash'></i>
								Ver Detalle del Pedido
							</span> */}
						</section>
					) : (
						""
					)}
					{showSales ? (
						<section className="sectionItemCartSale">
							<button className="buttonCerrarCartSale px-2" onClick={handlerShowSales}>
								x
							</button>
							{sales?.productos && <CarouselOfertasCart sales={sales.productos} />}
						</section>
					) : (
						<section className="sectionItemCartSale pt-2">
							<span className="vaciarCarrito px-4 py-2" onClick={handlerShowSales}>
								Ver Ofertas
							</span>
						</section>
					)}

{carrito.length ? (
						<>
							<span className="vaciarCarrito px-4 py-2" onClick={handleShowConfirmation}>
								{/* <i className='bx bx-trash'></i> */}
								Vaciar Bag
							</span>
							<div className="sectionModalBorrar">
								<ModalConfirmation
									className="modaaaaal"
									show={showConfirmation}
									title="Vaciar BeautiBag"
									message="¿Estás seguro de que deseas vaciar tu BeautiBag?"
									onConfirm={handleConfirmClearCart}
									onCancel={handleCancelClearCart}
								/>
							</div>
						</>
					) : (
						""
					)}


<span className="vaciarCarrito px-4 py-2" onClick={ () => handleShowTerms()} sx={{cursor:"pointer"}}  >
					Ver bases y condiciones
				</span>
				<Typography sx={{fontSize:"12px"}} className='terminos'>Todos los derechos reservados © 2023 - 2024</Typography>
				<Dialog open={showTerms} onClose={handleShowTermsClose}>
					<DialogContent>
						<Typography>
							{politicas[0].pregunta}
						</Typography>

              {politicas[0].respuesta?.split('\n').map((paragraph, idx) => (
                <Typography key={idx} style={{ fontFamily: "Montserrat", textAlign: "left", fontSize:isMobile?"12px":"14px" }} gutterBottom>
                  {paragraph.split(/(\*[^*]*\*)/).map((text, idx) => {
                    if (text.startsWith('*') && text.endsWith('*')) {
                      return <strong key={idx}>{text.slice(1, -1)}</strong>;
                    } else {
                      return text;
                    }
                  })}
                </Typography>
						))}


						<Typography>
							{politicas[1].pregunta}
						</Typography>

{politicas[1].respuesta?.split('\n').map((paragraph, idx) => (
	<Typography key={idx} style={{ fontFamily: "Montserrat", textAlign: "left", fontSize:isMobile?"12px":"14px" }} gutterBottom>
		{paragraph.split(/(\*[^*]*\*)/).map((text, idx) => {
			if (text.startsWith('*') && text.endsWith('*')) {
				return <strong key={idx}>{text.slice(1, -1)}</strong>;
			} else {
				return text;
			}
		})}
	</Typography>
))}

					</DialogContent>
				</Dialog>



				</Modal.Body>

				<Modal.Footer className="footerCart">
					<Button  style={{fontSize:"12px", height:"40px"}} className="buttonSecondary" variant="secondary" onClick={handleCloseCart}>
						Cancelar
					</Button>

					{/* {!isAuthenticated() ? (
						<div className="iniciarSesion">
							<Link to={"/iniciarSesion"} className="iniciarSesionLink">
								<button className="btn btn-sm btn-outline-secondary carrito">Iniciar Sesión</button>
							</Link>
						</div>
					) : (
						""
					)} */}
					{isAuthenticated() && carrito?.length ? (
						<Button
							variant="primary botonFinalizarCompra"
							onClick={handleShowPedido}
							disabled={!isAuthenticated()}
							style={{fontSize:"12px", height:"40px"}}
						>
							REALIZAR PEDIDO
						</Button>
					) : (
						<div className="iniciarSesion">
						<Link to={"/iniciarSesion"} className="iniciarSesionLink">
							<button style={{fontSize:"12px"}} className="btn btn-sm btn-outline-secondary carrito">Iniciar Sesión</button>
						</Link>
					</div>
					)}
					<div className="sectionModalBorrar">
						<ModalConfirmation
							className="modaaaaal"
							show={showPedido}
							title="Ya casi!"
							message="Estás a un paso de confirmar tu BeautiBag. 
							Nos contactaremos lo antes posible para confirmar el stock y coordinar la entrega de tu pedido."
							onConfirm={handleConfirmPedido}
							onCancel={handleCancelPedido}
						/>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Cart;
