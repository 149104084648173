import React from 'react';
import './GloboContactoLanding.Module.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import botonContacto from '../../assets/svg-redes/BOTON_CONTACTO.png';

function GloboContactoLanding({ posicion }) {
	const [respira, setRespira] = React.useState(false);

	React.useEffect(() => {
		const interval = setInterval(() => {
			setRespira((prevState) => !prevState);
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div className={` ${posicion === 'derechaButtonHome' ? 'derechaButtonHome' : 'fixedGlobo'}`}>
			<DropdownButton
				className={`grupoGloboHome botonGlobo shadow custom-dropdown-btn`}
				title={
					<div className={`imgBotonGlobo ${respira ? 'respira' : ''}`}>
						<img src={botonContacto} alt='Boton Contacto' />
					</div>
				}
			>

				<Dropdown.Item
					href='https://api.whatsapp.com/message/RUJEODHSOVNFK1?autoload=1&app_absent=0'
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bxl-whatsapp'></i>
				</Dropdown.Item>
				<Dropdown.Item
					href='https://www.instagram.com/labeautiqueria/'
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bxl-instagram'></i>
				</Dropdown.Item>
				<Dropdown.Item
					href='https://www.tiktok.com/@labeautiqueria'
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bxl-tiktok'></i>
				</Dropdown.Item>
				<Dropdown.Item
					href='mailto:labeautiqueriadb@gmail.com'
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bx-mail-send'></i>
				</Dropdown.Item>
		
			</DropdownButton>
		</div>
	);
}

export default GloboContactoLanding;

