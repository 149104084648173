import React from 'react';
import About from '../../components/about/About';
import Footer from '../../components/footer/Footer';
import { Card, Typography, Button, Box } from '@mui/material';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import NavBar from '../../components/navBar/NavBar';
import Promotion from '../../components/promotion/Promotion';
import SocialNetwork from '../../components/socialNetwork/SocialNetwork';
import StampTop from '../../components/stamp/StampTop';
import StampBottom from '../../components/stamp/StampBottom';
import VideoHome from '../../components/videoHome/VideoHome';
import { useSelector } from 'react-redux';
import { getSales } from '../../utils/products/products';
import './Home.Module.css';
import CarouselImg from '../../components/carousel/Carousel.jsx';
import CarouselOfertas from '../../components/carousel/CarouselOfertas';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { isEditMode } from '../../utils/users/users';
import { useMediaQuery } from 'react-responsive';
import Promotion2 from '../../components/promotionShop/Promotion2.jsx';
import CarouselGoInfo from '../../components/carouselGoInfo/CarouseGolInfo.jsx';
import CarouselMarcas from '../../components/carouselMarcas/CarouselMarcas.jsx';


function Home() {
	const bannerText = ["4, 5, y 6 DE NOVIEMBRE #CYBER BEAUTI","APROVECHA NUESTRAS BEAUTIGANGAS","4, 5, y 6 DE NOVIEMBRE #CYBER BEAUTI", "CONOCE NUESTRAS PROMOS BANCARIAS", "4, 5, y 6 DE NOVIEMBRE #CYBER BEAUTI","DESCUBRI NUESTRO BEST SELLER DEL MES", "HASTA 3 CUOTAS SIN INTERES"]
	const isMobile = useMediaQuery({ maxWidth: 700 });
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const [sales, setSales] = React.useState([]);
	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);
	const [currentBanner, setCurrentBanner] = React.useState(0)

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};

	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_HOME`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagenes();
	}, [renderKey]);

// 	React.useEffect(() => {
//     const interval = setInterval(() => {
  
//             setCurrentBanner((prevBanner) => (prevBanner + 1) % bannerText.length);
    
//     }, 3000); // Tiempo antes de cambiar al siguiente banner

//     return () => clearInterval(interval);
// }, []);




	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}



	React.useEffect(() => {
		toast.remove();
		async function getOfertas() {
			setSales(await getSales());
			setLoader(false);
		}
		getOfertas();
		window.addEventListener('actualizar-producto', getOfertas);
		return () => {
			window.removeEventListener('actualizar-producto', getOfertas);
		};
	}, []);

	// React.useEffect(() => {
	// 	// Ejecuta la función para comprobar el modo de edición
	// 	isEditMode(user && user);
	// }, []);

	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<div className='fondoHome' style={{ backgroundImage: `url(${image?.url})` }}>
					<header>
						<NavBar />
					</header>
					<main>
						<>
							<Toaster
								position='bottom-left'
								reverseOrder={false}
								toastOptions={{ className: 'fondoToast' }}
							/>

							<Box style={{ height:"25px",width:"100vw", marginTop:!isMobile?"25px":"85px"}}> 
								{/* <Typography  key={currentBanner} className="text-enter" style={{ color: "#20563f", fontSize:!isMobile? "25px":"16px", fontFamily:"Nexa" }}>
										{bannerText[currentBanner]}
    						</Typography> */}
									<Carousel
									draggable={true}
									showDots={false}
									swipeable={true}
									responsive={responsive}
									infinite={true}
									autoPlay={true}
									keyBoardControl={true}
									autoPlaySpeed={3000}
									shouldResetAutoplay={false}
									pauseOnHover={false}
									removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
								>
									{bannerText?.map((texto, index) => (
										<Box
											key={index}
											sx={{
												backgroundColor:"white",
												zIndex:2,
												color: "#20563f",
												fontSize: !isMobile ? "25px" : "16px",
												fontFamily: "MONTSERRAT",
												textAlign: "center"
											}}
										>
											{texto}
										</Box>
									))}
								</Carousel>
							</Box>

							<section className='sectionCarousel'>
								<CarouselImg />
							</section>
							{isSuperAdmin && (
								<div className='fondohome' onClick={() => handleOpenWidget()}>
									<i className='bi bi-pencil'></i>
									cambiar fondo
								</div>
							)}
						
							{sales && sales.productos?.length > 0 && (
								<section>
									<CarouselOfertas sales={sales} />
								</section>
							)}

							<section className='sectionPromotion2'>
								<Promotion2 />
							</section>
							<section className='sectionStampMarket'>
								<StampTop />
							</section>
							<section  className='sectionAboutMarket'>
								<About />
							</section>
							<section  style={{margin:"0px"}} className='sectionStampMarket'> 
								<StampBottom />
							</section>
							<section className='sectionPromotion'>
								<Promotion />
							</section>
							{/* <section  style={{margin:"0px"}}>  */}
								<CarouselMarcas/>
							{/* </section> */}
							<section style={{marginTop:"10vh"}} className='socialNetworks'>
								<SocialNetwork />
							</section>

							<section style={{marginTop:"10vh"}}>
								<CarouselGoInfo />
							</section>
						
							<GloboContacto />
							<VideoHome />
						</>
					</main>
					<footer>
						<Footer />
					</footer>
				</div>
			)}
		</>
	);
}

export default Home;
