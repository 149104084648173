import React from "react";
import Category from "../../components/category/Category";
import Footer from "../../components/footer/Footer";
import GloboContacto from "../../components/globoContacto/GloboContacto";
import Loader from "../../components/loader/Loader";
import NavBar from "../../components/navBar/NavBar";
import VideoHome from "../../components/videoHome/VideoHome";
import { useSelector } from "react-redux";
import { getCategories, chunkArray } from "../../utils/products/products";
import toast from "react-hot-toast";

import "./PrevCatalogue.Module.css";
import axios from "axios";

function PrevCatalogue() {
	const token = localStorage.getItem("Token");
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);

	const [categorias, setCategorias] = React.useState([]);

	const chunkedCategories = chunkArray(categorias, 3); // Dividir categorías en grupos de 3

	const [image, setImage] = React.useState("");

	const [loader, setLoader] = React.useState(true);
	const [images, setImages] = React.useState([]);
	const isSuperAdmin = user.role === "SUPERADMIN_ROLE";
	const [renderKey, setRenderKey] = React.useState(0);

	React.useEffect(() => {
		const cargarCategorias = async () => {
			setCategorias(await getCategories());

			setLoader(false);
		};
		cargarCategorias();
	}, []);

	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=PREV_CATALOGUE`
			);
			setImages(data.imagenes);

			setLoader(false);
		};
		cargarImagenes();
	}, []);

	React.useEffect(() => {
		const cargarImagen = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_PREV`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagen();
	}, [renderKey]);

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: "dlblszxyo",
					uploadPreset: "laBeautiqueria",
				},
				async (err, result) => {
					if (!err && result && result.event === "success") {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							"",
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error("Problema con la carga de la imagen", error);
		}
	}

	return (
		<>
			{!categorias.length ? (
				<Loader />
			) : (
				<div className="fondoPrevCatalogue" style={{ backgroundImage: `url(${image?.url})` }}>
					<header>
						<NavBar />
					</header>
					<main className="containerCategory2 ">
						{isSuperAdmin && (
							<div className="fondoPrevButton" onClick={() => handleOpenWidget()}>
								<i className="bi bi-pencil"></i>
								cambiar fondo
							</div>
						)}
						{images.length <= 0 ? (
							<Loader />
						) : (
							<div className="py-5  ">
								<h2 className="container  tituloCategory"> Elegí una categoría</h2>
								<section className="container py-3 sectionCategory">
									{chunkedCategories?.map((row, rowIndex) => (
										<div key={rowIndex} className="d-flex justify-content-around sectionCategory ">
											{row.map((categoria) => (
												<Category key={categoria._id} categoria={categoria} />
											))}
										</div>
									))}
									<GloboContacto />
									{/* <VideoHome /> */}
								</section>
							</div>
						)}
					</main>
					<footer>
						<Footer />
					</footer>
				</div>
			)}
		</>
	);
}

export default PrevCatalogue;
