import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import LoginNavBar from '../../components/loginNavBar/LoginNavBar';
import { loginFunction } from '../../features-slice/user/functions';
import { isAuthenticated } from '../../utils/auth/auth';
import { validateLogin } from '../../utils/validate/validate';
import { isEditMode } from '../../utils/users/users';
import GoogleLoginButton from './GoogleLogin';
import ModalResetPassword from '../../components/modals/modalResetPassword/ModalResetPassword';
import axios from 'axios';
import Loader from '../../components/loader/Loader';
import './Login.Module.css';
import { fetchUserInfo } from '../../features-slice/user/userSlice';
import { Button } from 'react-bootstrap';
import {
  Avatar,
  Box,
  Badge,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog
} from "@mui/material";


function Login() {
	const idUsuario = localStorage.getItem('Usuario');
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [image, setImage] = React.useState('');
	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [loader, setLoader] = React.useState(true);
	const [renderKey, setRenderKey] = React.useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errors, setErrors] = React.useState({});

	const [showConfirmation, setShowConfirmation] = React.useState(false);

	const handleShowConfirmation = () => {
		setShowConfirmation(true);
	};
	const handleCancelResetPassword = () => {
		setShowConfirmation(false); // Cierra el modal de confirmación en caso de cancelación
	};

	const [userInfo, setUserInfo] = React.useState({
		dni: '',
		password: '',
	});

	const [openEye, setOpenEye] = React.useState(true);
	const toggleEye = () => {
		setOpenEye(!openEye);
	};

	const handleChange = (e) => {
		setUserInfo({
			...userInfo,
			[e.target.name]: e.target.value,
		});
		setErrors(
			validateLogin({
				...userInfo,
				[e.target.name]: e.target.value,
			})
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (errors.password || !userInfo.dni) {
			toast.error('Revisa tus credenciales');
		} else {
			dispatch(loginFunction(userInfo, navigate));
		}
	};

	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_LOGIN`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagenes();
	}, [renderKey]);

	React.useEffect(() => {
		if (isAuthenticated() && !user.role) {
			// Realiza la carga de datos del usuario utilizando la acción fetchUserInfo
			dispatch(fetchUserInfo(idUsuario));
		}
	}, [user, dispatch, idUsuario]);

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}
	// React.useEffect(() => {
	// 	// Ejecuta la función para comprobar el modo de edición
	// 	isEditMode();
	// }, []);

	return (
		<div className='fondoLogin' style={{ backgroundImage: `url(${image?.url})` }}>
			<header>
				<LoginNavBar />
			</header>
			{loader ? (
				<Loader />
			) : (
				<main className='mainLogin'>
					{isSuperAdmin && (
						<div className='superadminButtonsss'>
							<div className='fondoLoginButton' onClick={() => handleOpenWidget()}>
								<i className='bi bi-pencil'></i>
								cambiar fondo
							</div>
							<Button className='toHome' onClick={() => navigate('/')}>
								volver a home
							</Button>
						</div>
					)}

					<Toaster
						position='bottom-left'
						reverseOrder={false}
						toastOptions={{ className: 'fondoToast' }}
					/>
					<div className='container containerForm p-5'>
						<h2>Iniciar Sesión</h2>
						<h4 className='pb-3'>Por favor, ingresá DNI y contraseña</h4>
						<form action='' onSubmit={handleSubmit}>
							<div className=' px-3 py-1'>
								{/* <label className='form-label' for='first_name'>
								Correo electrónico:
							</label> */}
								<input
									onChange={handleChange}
									className='form-control shadow py-3 inputLogin'
									name='dni'
									type='number'
									placeholder='Ingresa tu dni'
								></input>
								{/* {errors.correo ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.correo}
										</p>
									) : (
										<br></br>
									)} */}
							</div>
							<div className=' px-3 py-1 contraseña  '>
								<div className='input-group'>
									<input
										autoComplete='off'
										onChange={handleChange}
										className='form-control shadow py-3'
										name='password'
										type={openEye ? 'password' : 'text'}
										placeholder='Contraseña'
									/>
									<div className='input-group-apend'>
										<span className='input-group-text shadow py-3'>
											{openEye ? (
												<abbr title='Mostrar contraseña'>
													<i onClick={toggleEye} className='bi bi-eye-slash'></i>
												</abbr>
											) : (
												<abbr title='Ocultar contraseña'>
													<i onClick={toggleEye} className='bi bi-eye'></i>
												</abbr>
											)}
										</span>
									</div>
								</div>
								{errors.password ? (
									<p
										className='pt-1'
										style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
									>
										{errors.password}
									</p>
								) : (
									<br></br>
								)}
							</div>
							<div className='botonIngresar   mt-5'>
								<button type='submit' className='btn btn-secondary py-3'>
									INGRESAR
								</button>
							</div>
						</form>
						<div className='mb-3'>
							<abbr title='Olvidaste tu contraseña? Clickea y te ayudamos a recuperarla.'>
								<p onClick={handleShowConfirmation} className='link forgot'>
									Olvidaste tu contraseña?
								</p>
							</abbr>
						</div>
						<div className='sectionModalBorrar'>
							<ModalResetPassword
								className='modaaaaal'
								show={showConfirmation}
								title='Recupero de contraseña'
								message='¿Estás seguro que quieres recuperar tu contraseña? Recibirás un correo con los siguientes pasos. Si no tienes un correo asociado a tu cuenta, comunicate con nosotros y te ayudaremos.'
								onCancel={handleCancelResetPassword}
								stateUser={(userInfo, setUserInfo)}
								handleChange={handleChange}
							/>
						</div>
						<Typography sx={{fontSize:"14px"}}>
							No estas registrado?{' '}
							<Typography style={{color:"#5c5c5c"}} className='linkLogin' onClick ={()=>navigate('/crearCuenta')}>
								Crea tu cuenta
							</Typography>
						</Typography>
						
						<GoogleLoginButton />
					</div>
				</main>
			)}
			;
		</div>
	);
}

export default Login;
