import * as Jose from 'jose';
import axios from 'axios';
import toast from 'react-hot-toast';
import { DateTime } from 'luxon';

import { borrarCarrito } from '../../features-slice/cart/carritoSlice';

const uri = process.env.REACT_APP_BACK_URL;
const token = localStorage.getItem('Token');
const config = {
	headers: {
		xtoken: `${token}`,
	},
};
//esto es el token que guarda todos los productos en el carrito en el local storage
export async function crearTokenCarrito(carrito) {
	// Remueve el token anterior
	localStorage.removeItem('cc');

	// Key
	const key = process.env.REACT_APP_TOKEN_CARRITO;
	const privateKey = new TextEncoder().encode(
		key || 'hd78u3has8dfahsdf78yzxvajhgug6712g376g1fgasdgf67gada'
	);
	// Crea un nuevo token
	const token = await new Jose.SignJWT({ carrito })
		.setProtectedHeader({ alg: 'HS256' })
		.setIssuedAt()
		.setIssuer('')
		.setAudience('')
		.setExpirationTime('90h')
		.sign(privateKey);

	// Guardar el nuevo token en el localStorage
	localStorage.setItem('cc', token);

	// Retorna el token en caso de que sea necesario
	return token;
}

//esto lee el token que se creo anteriormente
export function decodificarToken() {
	const token = localStorage.getItem('cc');
	if (!token) return [];
	try {
		const { carrito } = Jose.decodeJwt(token);
		return carrito;
	} catch (error) {
		return [];
	}
}

export const currencyDisplay = (amount) => {
	// Creamos un objeto Intl.NumberFormat para formatear el número como moneda local en pesos argentinos (ARS)
	const formatter = new Intl.NumberFormat('es-AR', {
		style: 'currency',
		currency: 'ARS',
	});

	return formatter.format(amount);
};

const formatDetalleToText = (detalle) => {
	let formattedText = '';
	detalle.forEach((item) => {
		formattedText += `\n-${item.nombre} - Cantidad: *${item.cantidad}*, Precio: *${item.precioElegido}*, Perlas: *${item.puntos || 0}*\n\n`;
	});
	return formattedText;
};

function existingVariante(producto) {
	const variantColor = producto.variantes?.find(
		(variante) => variante._id === producto.varianteId
	)?.color;
	return variantColor ? `(${variantColor.toUpperCase()})` : '';
}

export const sendNewBuy = async (customer, buy, user, metodoPago, dispatch) => {
	if (!customer || !buy) {
		toast.error('Ups! Hubo un error al realizar el pedido');
	} else {
		let total = 0;
		let totalPuntos = 0;

		const handlePrecioElegido = (item, method) => {
			if (method === 'tarjeta') {
				return item.precioTarjeta;
			} else {
				return item.precioOferta > 0 ? item.precioOferta : item.precioEfectivo;
			}
		};

		const detalle = buy.map((item) => {
			const color = existingVariante(item);
			const { cantidad, varianteId } = item;
			const precioElegido = handlePrecioElegido(item, metodoPago);
			const precioEfectivo = item.precioEfectivo;
			const categoria = item.categoria;
			const precioTarjeta = item.precioTarjeta;
			const precioOferta = item.precioOferta;
			const puntos = item.puntos;
			const marca = item.marca;
			const tags = item.tags;
			const tags2 = item.tags2;

			total += precioElegido * cantidad;
			totalPuntos += puntos * cantidad;

			return {
				nombre: item.nombre + color,
				id: item._id,
				marca,
				precioEfectivo,
				precioOferta,
				precioTarjeta,
				precioElegido,
				varianteId,
				categoria,
				cantidad,
				puntos,
				tags,
				tags2,
			};
		});
		const fechaArgentina = DateTime.now().setZone('America/Argentina/Buenos_Aires');

		const compra = {
			cliente: customer,
			atendidoPor:"668003036088d4bb1108d5e5",
			detalle,
			total,
			totalPuntos,
			estadoCompra: 'PENDIENTE',
			metodoPago,
			fecha: fechaArgentina,
		};

		try {
			const { data, status } = await axios.post(`${uri}/api/compras`, compra, config);
			if (status === 201) {
				toast.success(
					`El pedido fue realizado con éxito, el numero de orden es ${data.detalle._id}`
				);
				const detalleTexto = formatDetalleToText(data.detalle.detalle);
				const numero = '+543415140700';
				const mensajeCompra = `Hola Marektina! soy ${user.nombre} ${user.apellido} y quiero hacer un pedido:\n${detalleTexto}\n idCompra: *${data.detalle._id.slice(0,5)}* \n DNI: ${user.dni.toString()} \n método de pago elegido: ${data.detalle.metodoPago}`;
				const encodedMensaje = encodeURIComponent(mensajeCompra);
				const whatsappLink = `https://api.whatsapp.com/send?phone=${numero}&text=${encodedMensaje}`;

				localStorage.removeItem('cc');
				dispatch(borrarCarrito());
				setTimeout(() => {
					window.open(whatsappLink, '_blank');
				}, 1500);

				const event = new Event('actualizar-compra');
				window.dispatchEvent(event);
			} else {
				toast.error(
					`Ocurrió un error en la compra, intenta nuevamente y si el error persiste comunicate con nosotros.`
				);
			}
		} catch (error) {
			console.log(error);
			toast.error('Ha ocurrido un error');
		}
	}
};
