import React from "react";
import { Link } from "react-router-dom";
import "./Category.Module.css";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";

function Category({ categoria }) {
	const { _id, img, nombre } = categoria;
	const token = localStorage.getItem("Token");
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const [imageUrl, setImageUrl] = React.useState(img || "");

	const isSuperAdmin = user.role === "SUPERADMIN_ROLE";

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: "dlblszxyo",
					uploadPreset: "laBeautiqueria",
				},
				async (err, result) => {
					if (!err && result && result.event === "success") {
						const url = result.info.secure_url;
						const data = { img: url };

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/categorias/${categoria._id}`,
							data,
							config
						);

						setImageUrl(url);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error("Error al cargar la imagen", error);
		}
	}

	const handleIconClick = (e) => {
		e.preventDefault(); // Evitar que el enlace se active al hacer clic en el ícono
		handleOpenWidget(); // Abrir el widget de Cloudinary
	};

	return (
		<Link
			to={`/market/categoria/${_id}?nombre=${encodeURIComponent(nombre)}`}
			className="card shadow  containerCategory"
		>
			<img src={imageUrl && imageUrl} alt={`img de ${nombre}`} />
			<h3 className="tituloCard titulito">{nombre}</h3>
			{isSuperAdmin && ( // Renderiza el ícono de lápiz solo si es un superadministrador
				<div className="edit-icon-prevCatalogue" onClick={(e) => handleIconClick(e)}>
					<i className="bi bi-pencil"></i>
				</div>
			)}
		</Link>
	);
}

export default Category;
