import React from 'react';
import './GloboContacto.Module.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import botonContacto from '../../assets/svg-redes/BOTON_CONTACTO.png';

function GloboContacto({ posicion }) {
	const [respira, setRespira] = React.useState(false);

	React.useEffect(() => {
		const interval = setInterval(() => {
			setRespira((prevState) => !prevState);
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div className={` ${posicion === 'derechaButton' ? 'derechaButton' : 'fixedGlobo'}`}>
			<DropdownButton
				className={`grupoGlobo botonGlobo shadow custom-dropdown-btn`}
				title={
					<div className={`imgBotonGlobo ${respira ? 'respira' : ''}`}>
						<img src={botonContacto} alt='Boton Contacto' />
					</div>
				}
			>

				<Dropdown.Item
					href={`https://api.whatsapp.com/send?phone=+54341514700&text=Hola Marketina,%20te escribo desde la página web,%20necesito asesoramiento.`}
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bxl-whatsapp'></i>
				</Dropdown.Item>
				<Dropdown.Item
					href='https://www.instagram.com/marketbylabeautiqueria?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bxl-instagram'></i>
				</Dropdown.Item>
				<Dropdown.Item
					href='https://www.tiktok.com/@labeautiqueria'
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bxl-tiktok'></i>
				</Dropdown.Item>
				<Dropdown.Item
					href='mailto:labeautiqueriadb@gmail.com'
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bx-mail-send'></i>
				</Dropdown.Item>
		
			</DropdownButton>
		</div>
	);
}

export default GloboContacto;

