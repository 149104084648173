import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog
} from "@mui/material";

import './CarouselGoInfoItem.Module.css';


const CarouselGoInfoItem = ({ imageUrl, isSuperAdmin, onChangeImage }) => {
	    
	const navigate= useNavigate()
	const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
	const handleClick = (event) => {
		event.stopPropagation(); // Evita que el evento se propague y cause conflictos con el widget
		onChangeImage();
	};

	return (
		<div className='carousel-item' style={{display:"flex", justifyContent:"center", alignContent:"center"}}>
			<img style={{height:isMobileScreen?"50vh":"82vh"}} src={imageUrl} alt='Carousel Item' />

			{/* <Box onClick={() => navigate("/")} style={{position:"absolute", cursor:"pointer"}} sx={{width:"200px", height:"60px", backgroundColor:"#e5c4c9", borderRadius:"5px", display:"flex", alignItems:"center", justifyContent:"center" , transition: "background-color 0.3s, transform 0.3s",
              '&:hover': {
                backgroundColor: "#d4a1a5",
                transform: "scale(1.05)",
              }}}>
								<Typography sx={{color:"#fff"}}>

									#BEAUTISERVICIOS
								</Typography>
								</Box> */}
			{isSuperAdmin && (
				<div className='edit-icon' onClick={handleClick}>
					<i className='bi bi-pencil'></i>
				</div>
			)}
		</div>
	);
};

export default CarouselGoInfoItem;
