// // Importar las librerías necesarias
// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import './ModalNewGiftcard.Module.css';
// import toast from 'react-hot-toast';
// import { getUsersLimit, searchAllUsers } from '../../../utils/users/users';
// import { getProductsByCategories, getProducts, searchAllProducts } from '../../../utils/products/products';
// import axios from 'axios';

// import ProductsTable from '../../../routes/dashboard/productsTable/ProductsTable';
// import UsersTable from '../../../routes/dashboard/usersTable/UsersTable';
// import Pagination from '../../pagination/Pagination';
// import { currencyDisplay } from '../../../utils/cart/cart';
// import { formatProductosSeleccionados } from '../../../utils/puchases/purchases';

// // Definir el componente EditModal
// const ModalNewGiftcard = ({ show, handleClose }) => {
// 	const token = localStorage.getItem('Token');
// 	const config = {
// 		headers: {
// 			xtoken: `${token}`,
// 		},
// 	};
// 	// Manejar el estado del formulario en el modal
// 	const [formData, setFormData] = React.useState({
// 		precio: 0,
// 		cliente: '',
// 		precioManual:0,
// 		de:"",
// 		para:""
// 	});

// 	// console.log(formData)
// 	const [paginaActualUsuarios, setPaginaActualUsuarios] = React.useState(1);
// 	const productosPorPagina = 5;
// 	const [terminoUsuario, setTerminoUsuario] = React.useState('');
// 	const [usuariosDesde, setUsuariosDesde] = React.useState(0);
// 	const [users, setUsers] = React.useState([]);
// 	const [giftcards, setGiftcards] = React.useState([]);
// 	const [productosSeleccionados, setProductosSeleccionados] = React.useState([]);


// 	const [terminoProducto, setTerminoProducto] = React.useState('');
// 	const [productosDesde, setProductosDesde] = React.useState(0);
// 	const [products, setProducts] = React.useState([]);
// 	const [paginaActualProductos, setPaginaActualProductos] = React.useState(1);

// 	const paginadoUsers = (numeroPagina) => {
// 		setPaginaActualUsuarios(numeroPagina);
// 		setUsuariosDesde(numeroPagina * 5 - 5);
// 	};


// 	const paginadoProducts = (numeroPagina) => {
// 		setPaginaActualProductos(numeroPagina);
// 		setProductosDesde(numeroPagina * 8 - 8);
// 	};

// 	// Manejar click de usuario
// 	const handleUserClick = (userId) => {
// 		setFormData((prevData) => ({
// 			...prevData,
// 			cliente: userId, // Actualiza el campo 'cliente' con el ID del usuario clickeado
// 		}));
// 	};

// 	const handleProductClick = (product) => {
// 		// Inicializar el campo precioSeleccionado al valor por defecto
// 		product.precioSeleccionado = 'seleccionaPrecio';
// 		setProductosSeleccionados((prevProductos) => [...prevProductos, product]);
// 	};
	

// 	const handleSearchUser = (e) => {
// 		e.preventDefault();
// 		setUsuariosDesde(0);
// 		setPaginaActualUsuarios(1);
// 		setTerminoUsuario(e.target.value);
// 	};

// 	const handleSearchProduct = (e) => {
// 		e.preventDefault();
// 		setProductosDesde(0);
// 		setPaginaActualProductos(1);
// 		setTerminoProducto(e.target.value);
// 	};


// 	// Manejar cambios en los campos del formulario
// 	const handleChange = (e) => {
// 		const { name, value } = e.target;
// 		setFormData((prevData) => ({
// 			...prevData,
// 			[name]: value,
// 		}));
// 	};
// 	const handleRemoveProduct = (index) => {
// 		const removedProduct = productosSeleccionados[index];
// 		const precioARestar = removedProduct.precioSeleccionado === 'precioTarjeta' ? removedProduct.precioTarjeta : removedProduct.precioEfectivo;
	
// 		// Restar el precio del producto eliminado del total
// 		setFormData((prevData) => ({ ...prevData, precio: Math.max(0, prevData.precio - precioARestar) }));
	
// 		// Eliminar el producto del array de productos seleccionados
// 		setProductosSeleccionados((prevProductos) => prevProductos.filter((_, i) => i !== index));
// 	};
	
	

// 	// Manejar el evento de guardar los cambios en el modal
// 	const handleNewGiftcard = async (formData) => {
// 		// Obtener los nombres de los productos seleccionados o establecer "saldo para consumir"
// 		const detalle = productosSeleccionados.length > 0
// 			? productosSeleccionados.map(producto => producto.nombre)
// 			: ['saldo para consumir'];
	
// 		const data = {
// 			...formData,
// 			cliente: formData.cliente.uid,
// 			precio: (formData?.precio + formData?.precioManual),
// 			detalle: detalle,
// 		};
	
// 		try {
// 			await axios.post(`${process.env.REACT_APP_BACK_URL}/api/giftcards`, data, config);
// 			const event = new Event('actualizar-giftcard');
// 			window.dispatchEvent(event);
// 			toast.success('Giftcard creada con éxito');
// 			setFormData({
// 				precio: 0,
// 				cliente: '',
// 				precioManual: 0
// 			});
// 			handleClose();
// 		} catch (error) {
// 			toast.error('Ocurrió un error al crear la giftcard');
// 		}
// 	};


// 	React.useEffect(() => {
// 		setFormData({
// 			precio: 0,
// 		cliente: '',
// 		precioManual:0
// 		});
// 		async function obtenerGiftcards() {
// 			setGiftcards(await getProductsByCategories('64a44cd2b1d4ca7f2d6085f8', 0));
// 		}
// 		obtenerGiftcards();
// 	}, []);

// 	React.useEffect(() => {
// 		async function obtenerUsuarios() {
// 			setUsers(await getUsersLimit(usuariosDesde, 5));
// 		}
// 		async function buscarUsuarios() {
// 			setUsers(await searchAllUsers(terminoUsuario, usuariosDesde));
// 		}

// 		if (terminoUsuario === '') {
// 			obtenerUsuarios();
// 		} else {
// 			buscarUsuarios();
// 		}
// 	}, [usuariosDesde, terminoUsuario]);

// 	React.useEffect(() => {
// 		toast.remove();
// 		async function obtenerProductos() {
// 			setProducts(await getProducts(productosDesde));
// 		}
// 		async function buscarProductos() {
// 			setProducts(await searchAllProducts(terminoProducto, productosDesde));
// 		}

// 		if (terminoProducto === '') {
// 			obtenerProductos();
// 		} else {
// 			buscarProductos();
// 		}
// 		window.addEventListener('actualizar-producto', obtenerProductos);
// 		return () => {
// 			window.removeEventListener('actualizar-producto', obtenerProductos);
// 		};
// 	}, [productosDesde, terminoProducto]);

// 	// console.log(productosSeleccionados)

// 	return (
// 		<Modal show={show} onHide={handleClose}>
// 			<Modal.Header className=' headerGift' closeButton>
// 				<Modal.Title>Crear nueva giftcard</Modal.Title>
// 			</Modal.Header>
// 			<Modal.Body className='modalConfirmationContainer bodyGift'>
// 				{/* Agregar elementos de formulario para cada campo que deseas editar */}
// 				<div>
// 					<label htmlFor='nombre'>Selecciona cliente:</label>
// 					<div className='containerTablaProductos'>
// 						<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
// 							<input
// 								className='form-control inputSearch'
// 								type='search'
// 								placeholder='Buscar'
// 								value={terminoUsuario}
// 								onChange={(e) => handleSearchUser(e)}
// 							/>
// 							<i className='bx bx-search form-control-feedback'></i>
// 						</form>
// 					</div>
// 					<UsersTable users={users.usuarios} onUserClick={(userId) => handleUserClick(userId)} />
// 					<Pagination
// 						key={paginaActualUsuarios}
// 						productosPorPagina={productosPorPagina}
// 						productos={users?.total}
// 						paginado={paginadoUsers}
// 						paginaActual={paginaActualUsuarios}
// 					/>
// 				</div>

// 				<div>
// 					<label htmlFor='nombre'>Selecciona uno o mas productos:</label>
// 					<div className='containerTablaProductos'>
// 						<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
// 							<input
// 								className='form-control inputSearch'
// 								type='search'
// 								placeholder='Buscar'
// 								value={terminoProducto}
// 								onChange={(e) => handleSearchProduct(e)}
// 							/>
// 							<i className='bx bx-search form-control-feedback'></i>
// 						</form>
// 					</div>
// 				  <ProductsTable products={products?.productos} onProductClick={(product) => handleProductClick(product)} />
// 					<Pagination
// 						key={paginaActualProductos}
// 						productosPorPagina={productosPorPagina}
// 						productos={products?.total}
// 						paginado={paginadoProducts}
// 						paginaActual={paginaActualProductos}
// 					/>
// 				</div>
// 				{productosSeleccionados.length > 0 && (
//       	<div>
//         <h4>Productos Seleccionados:</h4>
//         {productosSeleccionados?.map((selectedProduct, index) => (
//             <div key={index}>
//               <p>{selectedProduct?.nombre} 	<abbr title='Eliminar del carrito'>
// 												<button
// 													className='btn btn-sm  carrito'
// 													onClick={() => handleRemoveProduct(index)}
// 												>
// 													<i className='bx bx-trash'></i>
// 												</button>
// 											</abbr></p>
// 							<select
//   className={`form-control ${selectedProduct?.precioSeleccionado === 'seleccionaPrecio' ? 'select-red-background' : ''}`}
//   value={selectedProduct?.precioSeleccionado || 'seleccionaPrecio'}
//   onChange={(e) => {
//     const selectedOption = e.target.value;
//     const nuevoPrecio = selectedOption === 'precioTarjeta' ? selectedProduct.precioTarjeta : selectedProduct.precioEfectivo;

//     // Actualizar el precio seleccionado en el producto
//     setProductosSeleccionados((prevProductos) => {
//       const nuevosProductos = [...prevProductos];
//       nuevosProductos[index] = { ...selectedProduct, precioSeleccionado: selectedOption };
//       return nuevosProductos;
//     });

//     if (selectedOption !== 'seleccionaPrecio') {
//       setFormData((prevData) => ({ ...prevData, precio: prevData.precio + nuevoPrecio }));
//     }
//   }}
// >
//   <option value='seleccionaPrecio' disabled hidden>
//     Selecciona una opción
//   </option>
//   <option value="precioTarjeta">
//     Precio Lista {currencyDisplay(selectedProduct.precioTarjeta)}
//   </option>
//   <option value="precioEfectivo">
//     Precio Promo {currencyDisplay(selectedProduct.precioEfectivo)}
//   </option>
// </select>
				
//             </div>
//         ))}
					
//       </div>

			
//   )}
// 				{/* <div>
//   			<label htmlFor='precio'>O ingresa un precio manual:</label>
//   		<input
//     type='number'
//     className='form-control'
//     placeholder='Ingrese el precio'
//     value={formData.precioManual === 0 ? '' : formData.precioManual}
//     onChange={(e) => {
//       const nuevoPrecioManual = e.target.value ? parseInt(e.target.value) : 0;
//       setFormData((prevData) => ({ ...prevData, precioManual: nuevoPrecioManual }));
//     }}
//   />
// </div> */}

// <div>
// 					<label htmlFor='nombre'>De:</label>

// 					<input
// 						className='form-control'
// 						type='text'
// 						name='de'
// 						value={formData?.de}
// 						onChange={handleChange}
// 						placeholder='Ej: Nombre cliente comprador'
// 					/>
// 				</div>
// 				<div>
// 					<label htmlFor='nombre'>Para:</label>

// 					<input
// 						className='form-control'
// 						type='text'
// 						name='para'
// 						value={formData?.para}
// 						onChange={handleChange}
// 						placeholder='Ej: Nombre persona que recibe'
// 					/>
// 				</div>

// 				<br></br>
// 				<div>
// 					<label htmlFor='Cliente'>Cliente seleccionado</label>
// 					<h5>
// 						{formData.cliente
// 							? `${formData.cliente?.nombre} ${formData.cliente?.apellido}`
// 							: 'Debes seleccionar un cliente'}
// 					</h5>
// 					<label htmlFor='Cliente'>Precio seleccionado</label>
// 					<h5>{currencyDisplay(formData?.precio + formData?.precioManual) || 0}</h5>
// 				</div>
// 				<div className='divMagicoReset py-2 mt-4'>
// 					<Button variant='secondary' onClick={handleClose}>
// 						Cerrar
// 					</Button>
// 					<Button
// 						className='botonModalConfirmacion'
// 						variant='primary'
// 						onClick={() => handleNewGiftcard(formData)}
// 					>
// 						Crear nueva giftcard
// 					</Button>
// 				</div>
// 			</Modal.Body>
// 		</Modal>
// 	);
// };

// export default ModalNewGiftcard;
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalNewGiftcard.Module.css';
import toast from 'react-hot-toast';
import { getUsersLimit, searchAllUsers } from '../../../utils/users/users';
import { getProductsByCategories, getProducts, searchAllProducts } from '../../../utils/products/products';
import axios from 'axios';

import ProductsTable from '../../../routes/dashboard/productsTable/ProductsTable';
import UsersTable from '../../../routes/dashboard/usersTable/UsersTable';
import Pagination from '../../pagination/Pagination';
import { currencyDisplay } from '../../../utils/cart/cart';
import { formatProductosSeleccionados } from '../../../utils/puchases/purchases';

const ModalNewGiftcard = ({ show, handleClose }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	
	const [formData, setFormData] = React.useState({
		precio: 0,
		cliente: '',
		precioManual: 0,
		de: "",
		para: ""
	});
	
	const [paginaActualUsuarios, setPaginaActualUsuarios] = React.useState(1);
	const productosPorPagina = 5;
	const [terminoUsuario, setTerminoUsuario] = React.useState('');
	const [usuariosDesde, setUsuariosDesde] = React.useState(0);
	const [users, setUsers] = React.useState([]);
	const [giftcards, setGiftcards] = React.useState([]);
	const [productosSeleccionados, setProductosSeleccionados] = React.useState([]);
	// console.log(productosSeleccionados)
    // console.log(formData)
    const [terminoProducto, setTerminoProducto] = React.useState('');
    const [productosDesde, setProductosDesde] = React.useState(0);
    const [products, setProducts] = React.useState([]);
    const [paginaActualProductos, setPaginaActualProductos] = React.useState(1);

    const paginadoUsers = (numeroPagina) => {
        setPaginaActualUsuarios(numeroPagina);
        setUsuariosDesde(numeroPagina * 5 - 5);
    };

    const paginadoProducts = (numeroPagina) => {
        setPaginaActualProductos(numeroPagina);
        setProductosDesde(numeroPagina * 8 - 8);
    };

    const handleUserClick = (userId) => {
        setFormData((prevData) => ({
            ...prevData,
            cliente: userId,
        }));
    };

    // const handleProductClick = (product) => {
    //     product.precioSeleccionado = 'seleccionaPrecio';
    //     setProductosSeleccionados((prevProductos) => [...prevProductos, { ...product, cantidad: 1 }]);
    // };

		const handleProductClick = (product) => {
			setProductosSeleccionados((prevProductos) => [...prevProductos, { ...product, cantidad: 1, precioSeleccionado: 0 }]);
	};
    const handleSearchUser = (e) => {
        e.preventDefault();
        setUsuariosDesde(0);
        setPaginaActualUsuarios(1);
        setTerminoUsuario(e.target.value);
    };

    const handleSearchProduct = (e) => {
        e.preventDefault();
        setProductosDesde(0);
        setPaginaActualProductos(1);
        setTerminoProducto(e.target.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const handleRemoveProduct = (index) => {
    //     const removedProduct = productosSeleccionados[index];
    //     const precioARestar = removedProduct.precioSeleccionado === 'precioTarjeta' ? removedProduct.precioTarjeta : removedProduct.precioEfectivo;

    //     setFormData((prevData) => ({ ...prevData, precio: Math.max(0, prevData.precio - precioARestar * removedProduct.cantidad) }));
    //     setProductosSeleccionados((prevProductos) => prevProductos.filter((_, i) => i !== index));
    // };
		const handleRemoveProduct = (index) => {
			const removedProduct = productosSeleccionados[index];
			const precioARestar = removedProduct.cantidad * removedProduct.precioSeleccionado;
	
			// Restar el precio del producto eliminado del total
			setFormData((prevData) => ({ ...prevData, precio: Math.max(0, prevData.precio - precioARestar) }));
	
			// Eliminar el producto del array de productos seleccionados
			setProductosSeleccionados((prevProductos) => prevProductos.filter((_, i) => i !== index));
	};
		const handleQuantityChange = (index, newQuantity) => {
			if (newQuantity < 1) return;
	
			const selectedProduct = productosSeleccionados[index];
			const precioProducto = selectedProduct.precioSeleccionado;
			const precioAnterior = selectedProduct.cantidad * precioProducto;
			const precioNuevo = newQuantity * precioProducto;
			const precioDiferencial = precioNuevo - precioAnterior;
	
			setFormData((prevData) => ({ ...prevData, precio: prevData.precio + precioDiferencial }));
	
			setProductosSeleccionados((prevProductos) => {
					const updatedProducts = [...prevProductos];
					updatedProducts[index].cantidad = newQuantity;
					return updatedProducts;
			});
	};

		const handlePriceChange = (index, selectedOption) => {
			const selectedProduct = productosSeleccionados[index];
			const nuevoPrecio = selectedOption === 'precioTarjeta' ? selectedProduct.precioTarjeta : selectedProduct.precioEfectivo;
			const precioAnterior = selectedProduct.precioSeleccionado;
	
			const precioDiferencial = (nuevoPrecio - precioAnterior) * selectedProduct.cantidad;
	
			setFormData((prevData) => ({ ...prevData, precio: prevData.precio + precioDiferencial }));
	
			setProductosSeleccionados((prevProductos) => {
					const updatedProducts = [...prevProductos];
					updatedProducts[index].precioSeleccionado = nuevoPrecio;
					return updatedProducts;
			});
	};
	
	

    const handleNewGiftcard = async (formData) => {
        const detalle = productosSeleccionados.length > 0
            ? productosSeleccionados.map(producto => `${producto.nombre} x${producto.cantidad}`)
            : ['saldo para consumir'];

        const data = {
            ...formData,
            cliente: formData.cliente._id,
            precio: (formData?.precio + formData?.precioManual),
            detalle: detalle,
        };

        try {
            await axios.post(`${process.env.REACT_APP_BACK_URL}/api/giftcards`, data, config);
            const event = new Event('actualizar-giftcard');
            window.dispatchEvent(event);
            toast.success('Giftcard creada con éxito');
            setFormData({
                precio: 0,
                cliente: '',
                precioManual: 0
            });
            handleClose();
        } catch (error) {
            toast.error('Ocurrió un error al crear la giftcard');
        }
    };

    React.useEffect(() => {
        setFormData({
            precio: 0,
            cliente: '',
            precioManual: 0
        });
        async function obtenerGiftcards() {
            setGiftcards(await getProductsByCategories('64a44cd2b1d4ca7f2d6085f8', 0));
        }
        obtenerGiftcards();
    }, []);

    React.useEffect(() => {
        async function obtenerUsuarios() {
            setUsers(await getUsersLimit(usuariosDesde, 5));
        }
        async function buscarUsuarios() {
            setUsers(await searchAllUsers(terminoUsuario, usuariosDesde));
        }

        if (terminoUsuario === '') {
            obtenerUsuarios();
        } else {
            buscarUsuarios();
        }
    }, [usuariosDesde, terminoUsuario]);

    React.useEffect(() => {
        toast.remove();
        async function obtenerProductos() {
            setProducts(await getProducts(productosDesde));
        }
        async function buscarProductos() {
            setProducts(await searchAllProducts(terminoProducto, productosDesde));
        }

        if (terminoProducto === '') {
            obtenerProductos();
        } else {
            buscarProductos();
        }
        window.addEventListener('actualizar-producto', obtenerProductos);
        return () => {
            window.removeEventListener('actualizar-producto', obtenerProductos);
        };
    }, [productosDesde, terminoProducto]);

		React.useEffect(() => {
			// Actualizar formData.precio cuando productosSeleccionados cambia
			const totalPrecio = productosSeleccionados.reduce((total, producto) => {
					return total + (producto.cantidad * producto.precioSeleccionado);
			}, 0);
	
			setFormData((prevData) => ({ ...prevData, precio: totalPrecio }));
	}, [productosSeleccionados]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className=' headerGift' closeButton>
                <Modal.Title>Crear nueva giftcard</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modalConfirmationContainer bodyGift'>
                <div>
                    <label htmlFor='nombre'>Selecciona cliente:</label>
                    <div className='containerTablaProductos'>
                        <form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
                            <input
                                className='form-control inputSearch'
                                type='search'
                                placeholder='Buscar'
                                value={terminoUsuario}
                                onChange={(e) => handleSearchUser(e)}
                            />
                            <i className='bx bx-search form-control-feedback'></i>
                        </form>
                    </div>
                    <UsersTable users={users.usuarios} onUserClick={(userId) => handleUserClick(userId)} />
                    <Pagination
                        key={paginaActualUsuarios}
                        productosPorPagina={productosPorPagina}
                        productos={users?.total}
                        paginado={paginadoUsers}
                        paginaActual={paginaActualUsuarios}
                    />
                </div>

                <div>
                    <label htmlFor='nombre'>Selecciona uno o mas productos:</label>
                    <div className='containerTablaProductos'>
                        <form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
                            <input
                                className='form-control inputSearch'
                                type='search'
                                placeholder='Buscar'
                                value={terminoProducto}
                                onChange={(e) => handleSearchProduct(e)}
                            />
                            <i className='bx bx-search form-control-feedback'></i>
                        </form>
                    </div>
                    <ProductsTable products={products?.productos} onProductClick={(product) => handleProductClick(product)} />
                    <Pagination
                        key={paginaActualProductos}
                        productosPorPagina={productosPorPagina}
                        productos={products?.total}
                        paginado={paginadoProducts}
                        paginaActual={paginaActualProductos}
                    />
                </div>

                {productosSeleccionados.length > 0 && (
                    <div>
                        <h4>Productos Seleccionados:</h4>
                        {productosSeleccionados.map((selectedProduct, index) => (
                            <div style={{borderBottom:"1px solid #5c5c5c", paddingBottom:"15px"}} key={index}>
                                <p style={{backgroundColor:"#e5c4c978"}}>{selectedProduct?.nombre} <abbr title='Eliminar del carrito'>
                                    <button className='btn btn-sm  carrito' onClick={() => handleRemoveProduct(index)}>
                                        <i className='bx bx-trash'></i>
                                    </button>
                                </abbr></p>
                                <div>
                                    <button className='btn btn-sm' onClick={() => handleQuantityChange(index, selectedProduct.cantidad - 1)}>-</button>
                                    <input style={{width:"65px"}} type='text' value={selectedProduct.cantidad} readOnly />
                                    <button className='btn btn-sm' onClick={() => handleQuantityChange(index, selectedProduct.cantidad + 1)}>+</button>
                                </div>
                                <select
                                    className='form-control'
                                    value={selectedProduct.precioSeleccionado || 'seleccionaPrecio'}
                                    onChange={(e) => handlePriceChange(index, e.target.value)}
                                >
                                    <option value='seleccionaPrecio' disabled hidden>Selecciona una opción</option>
                                    <option value="precioTarjeta">Precio Lista {currencyDisplay(selectedProduct.precioTarjeta)}</option>
                                    <option value="precioEfectivo">Precio Promo {currencyDisplay(selectedProduct.precioEfectivo)}</option>
                                </select>
                            </div>
														
                        ))}
                    </div>
                )}

<br></br>
                <div>
                    <label htmlFor='nombre'>De:</label>
                    <input
                        className='form-control'
                        type='text'
                        name='de'
                        value={formData?.de}
                        onChange={handleChange}
                        placeholder='Ej: Nombre cliente comprador'
                    />
                </div>
                <div>
                    <label htmlFor='nombre'>Para:</label>
                    <input
                        className='form-control'
                        type='text'
                        name='para'
                        value={formData?.para}
                        onChange={handleChange}
                        placeholder='Ej: Nombre persona que recibe'
                    />
                </div>

                <br></br>
                <div>
                    <label htmlFor='Cliente'>Cliente seleccionado</label>
                    <h5>
                        {formData.cliente
                            ? `${formData.cliente?.nombre} ${formData.cliente?.apellido}`
                            : 'Debes seleccionar un cliente'}
                    </h5>
                    <label htmlFor='Cliente'>Precio seleccionado</label>
                    <h5>{currencyDisplay(formData?.precio + formData?.precioManual) || 0}</h5>
                </div>
                <div className='divMagicoReset py-2 mt-4'>
                    <Button variant='secondary' onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button
                        className='botonModalConfirmacion'
                        variant='primary'
                        onClick={() => handleNewGiftcard(formData)}
                    >
                        Crear nueva giftcard
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalNewGiftcard;

