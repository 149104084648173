import React from "react";
import NavBar from "../../components/navBar/NavBar";
import Footer from "../../components/footer/Footer";
import GloboContacto from "../../components/globoContacto/GloboContacto";
import Loader from "../../components/loader/Loader";
// import { setUserInfo } from '../../features-slice/user/userSlice';
import { useSelector } from "react-redux/es/hooks/useSelector";
import "./Profile.Module.css";
import { formatDate } from "../../utils/validate/validate";
import { currencyDisplay } from "../../utils/cart/cart";

import ModalHistorial from "../../components/modals/modalHistorial/ModalHistorial";
import { isAuthenticated } from "../../utils/auth/auth";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
function Profile() {
	const user = useSelector((state) => state.user.userInfo);
	if (user.mensajeCambioCategoria) {
		toast.success(`Felicitaciones, cambiaste de categoria a ${user?.categoria.nombre}`);
	}
	const [categorias, setCategorias] = React.useState([]);

	const [puntosNecesariosSiguienteCategoria, setPuntosNecesariosSiguienteCategoria] =
		React.useState(null);

	const [puntosFaltantesParaSiguienteCategoria, setPuntosFaltantesParaSiguienteCategoria] =
		React.useState(null);

	// const sortedHistorial =
	// 	user && user.historial
	// 		? [...user.historial].sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
	// 		: [];

	const [loader, setLoader] = React.useState(true);

	// //const [showBuyDetail, setShowBuyDetail] = React.useState(false);
	// const [selectedCompraId, setSelectedCompraId] = React.useState(null);

	// const handleShowBuyDetail = (compraId) => {
	// 	setSelectedCompraId(compraId);
	// };

	// const handleCancelShowBuyDetail = () => {
	// 	setSelectedCompraId(null); // Cierra el modal de confirmación en caso de cancelación
	// };

	React.useEffect(() => {
		// Calcular la próxima categoría en función de los puntos acumulados del usuario
		if (categorias.length > 0 && user?.puntosAcumulados >= 0) {
			let siguienteCategoria = null;
			for (const categoria of categorias) {
				if (user.puntosAcumulados < categoria.puntosNecesarios) {
					siguienteCategoria = categoria;
					break;
				}
			}

			if (siguienteCategoria) {
				setPuntosNecesariosSiguienteCategoria(siguienteCategoria.puntosNecesarios);
				const puntosFaltantes = siguienteCategoria.puntosNecesarios - user.puntosAcumulados;
				setPuntosFaltantesParaSiguienteCategoria(puntosFaltantes);
			} else {
				// El usuario ha alcanzado la categoría más alta
				setPuntosNecesariosSiguienteCategoria(null);
				setPuntosFaltantesParaSiguienteCategoria(0);
			}
		}
	}, [categorias, user]);

	React.useEffect(() => {
		if (!isAuthenticated()) {
			window.location.replace("/iniciarsesion");
		}
		setTimeout(() => {
			setLoader(false);
		}, 500);
	}, []);

	React.useEffect(() => {
		// Obtener las categorías de usuario desde la API
		axios
			.get(`${process.env.REACT_APP_BACK_URL}/api/categoriasusuario`)
			.then((response) => {
				const categoriasData = response.data.categorias;

				// Aquí puedes establecer el estado de las categorías obtenidas
				setCategorias(categoriasData);
			})
			.catch((error) => {
				console.error("Error al obtener las categorías:", error);
			});
	}, []); // Se ejecuta una vez al cargar el componente

	return (
		<div className="fondoProfile">
			<header>
				<NavBar />
			</header>
			<main className="containerProfile py-4">
				{loader ? (
					<Loader />
				) : (
					<div className="container containerForm p-4 shadow ">
						<Toaster
							position="bottom-left"
							reverseOrder={false}
							toastOptions={{ className: "fondoToast" }}
						/>
						{user?.nombre && (
							<h2>Bienvenido {user && user.nombre[0].toUpperCase() + user.nombre.slice(1)}</h2>
						)}
						<div className="containerInfoProfile">
							<section className="infoProfile col-md">
								{user?.nombre && (
									<h4 className="py-3 pe-5 textProfile">
										Nombre: {user && user.nombre[0].toUpperCase() + user.nombre.slice(1)}
									</h4>
								)}

								{user?.apellido && (
									<h4 className="py-3 pe-5 textProfile">
										Apellido: {user && user.apellido[0].toUpperCase() + user.apellido.slice(1)}
									</h4>
								)}
								<h4 className="py-3 pe-5 textProfile">Correo: {user?.correo}</h4>
								<h4 className="py-3 pe-5 textProfile">Tus perlas: {user?.puntosAcumulados}</h4>
								{puntosFaltantesParaSiguienteCategoria === 0 ||
								user?.categoria?.nombre === "platino" ? (
									<h4>Ya alcanzaste la máxima categoría, gracias!!</h4>
								) : (
									<>
										<h4 className="py-3 pe-5 textProfile">
											Perlas necesarias para subir de nivel: {puntosNecesariosSiguienteCategoria}
										</h4>
										<h4 className="py-3 pe-5 textProfile">
											Te faltan: {puntosFaltantesParaSiguienteCategoria} perlas.
										</h4>
									</>
								)}
							</section>
							<section className="categorySection">
								<h4>{`Tu categoría actual es ${user?.categoria?.nombre}`}</h4>
								<ul>
									Tenés estos beneficios disponibles:
									{user.beneficios
										?.filter((beneficio) => beneficio.disponible === true) // Filtrar los beneficios disponibles
										.map((beneficio) => (
											<li className="liProfile" key={beneficio._id}>
												{beneficio.nombre}
											</li>
										))}
								</ul>
							</section>
							{/* <section className={'infoHistorial col-md'}>
								<div className='tituloHistorialContainer'>
									<h4 className='tituloHistorial'>Historial de compras</h4>
								</div>
								{user && user.historial?.length ? (
									sortedHistorial.map((compra, index) => {
										return (
											<div
												className='containerHistorial px-3 py-3'
												key={index}
												onClick={() => handleShowBuyDetail(compra.id)}
											>
												<h4 className='textProfileHistorial'> {formatDate(compra.fecha)}</h4>
												<h4 className='textProfileHistorial'>{currencyDisplay(compra.total)}</h4>
												<h4 className='textProfileHistorial'>
													<i className='bi bi-gem diamanteHistorial'></i>
													{compra.totalPuntos}
												</h4>
											</div>
										);
									})
								) : (
									<div className='containerHistorial px-3 py-3'>
										<h4 className='textProfileHistorial'>Todavía no realizaste una compra</h4>
									</div>
								)}

								{selectedCompraId && (
									<ModalHistorial
										className='modaaaaal'
										show={true}
										title='Detalle de la compra'
										message='¿Estás seguro de que deseas eliminar el carrito?'
										//onConfirm={handleConfirmClearCart}
										onCancel={handleCancelShowBuyDetail}
										idCompra={selectedCompraId}
									/>
								)}
							</section> */}
						</div>

						{/* <div className='botonEliminarCuenta px-5 py-3 mt-5'>
							<button type='submit' className='btn btn-secondary py-3'>
								Eliminar Cuenta
							</button>
						</div> */}
					</div>
				)}
			</main>
			<GloboContacto />
			<footer>
				<Footer />
			</footer>
		</div>
	);
}

export default Profile;
