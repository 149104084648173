import React from 'react';
import { Link } from 'react-router-dom';
import LoginNavBar from '../../components/loginNavBar/LoginNavBar';
import './RecuperoContraseña.Module.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './RecuperoContraseña.Module.css';

export default function RecuperoContraseña() {
	const [resultado, setResultado] = React.useState('');
	const { token } = useParams();

	React.useEffect(() => {
		const resetPass = async () => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BACK_URL}/api/password/reset/${token}`
				);
				setResultado(response);
				console.log('200', response);
			} catch (error) {
				setResultado(error.response);
				console.log('400', resultado);
			}
		};
		resetPass();
		//return () => {};
	}, []);

	return (
		<div className='fondoRecuperoContraseña'>
			<header>
				<LoginNavBar />
			</header>
			<main className='container containerRecuperoContraseña'>
				<h2>Actualización de Contraseña</h2>
				<h4 className='pt-4'>
					{/* Tu contraseña ha sido actualizada con éxito, ya podés iniciar sesión. */}
					{resultado.data?.msg}
				</h4>
				{resultado.status === 200 ? (
					<Link to='/iniciarsesion' className='buttonRecuperoContraseña pt-4'>
						<button>Iniciar Sesión</button>
					</Link>
				) : (
					<Link to='/iniciarsesion' className='buttonRecuperoContraseña pt-4'>
						<button>Reintentar</button>
					</Link>
				)}
			</main>
		</div>
	);
}
