import React from 'react';
import './Pagination.Module.css';

function Pagination({ productosPorPagina, productos, paginado, paginaActual }) {
// console.log("productos por pagina", productosPorPagina)
	// console.log("productos", productos)

	const numerosPaginas = [];

	// console.log(numerosPaginas)

	for (let i = 1; i <= Math.ceil(productos / productosPorPagina); i++) {
		numerosPaginas.push(i);
	}

	const mostrarNumerosPagina = () => {
		if (numerosPaginas.length <= 10) {
			// Si hay 10 o menos páginas, mostrar todas
			return numerosPaginas.map((numero, index) => (
				<li className='page-item' key={index}>
					<a href='#catalogoRef' className='nav-link'>
						<button
							className={`page-link botonPaginado${paginaActual === numero ? ' active' : ''}`}
							href='#'
							onClick={() => paginado(numero)}
						>
							{numero}
						</button>
					</a>
				</li>
			));
		}

		// Si hay más de 10 páginas, mostrar solo 3 números antes y después de la página actual
		let inicio = Math.max(1, paginaActual - 3);
		let fin = Math.min(numerosPaginas.length, paginaActual + 3);

		if (paginaActual <= 4) {
			// Si estamos cerca del principio, siempre mostramos los primeros 7 números
			inicio = 1;
			fin = 7;
		} else if (paginaActual >= numerosPaginas.length - 3) {
			// Si estamos cerca del final, siempre mostramos los últimos 7 números
			inicio = numerosPaginas.length - 6;
			fin = numerosPaginas.length;
		}

		const numeros = [];

		for (let i = inicio; i <= fin; i++) {
			numeros.push(i);
		}

		return (
			<>
				{inicio > 1 && (
					<li className='page-item disabled'>
						<a href='#catalogoRef' className='nav-link'>
							<button className='page-link botonPaginado' href='#'>
								...
							</button>
						</a>
					</li>
				)}
				{numeros.map((numero, index) => (
					<li className='page-item' key={index}>
						<a href='#catalogoRef' className='nav-link'>
							<button
								className={`page-link botonPaginado${paginaActual === numero ? ' active' : ''}`}
								href='#'
								onClick={() => paginado(numero)}
							>
								{numero}
							</button>
						</a>
					</li>
				))}
				{fin < numerosPaginas.length && (
					<li className='page-item disabled'>
						<a href='#catalogoRef' className='nav-link'>
							<button className='page-link botonPaginado' href='#'>
								...
							</button>
						</a>
					</li>
				)}
			</>
		);
	};

	return (
		<nav aria-label='Page navigation example containerPaginado '>
			<ul className='pagination mb-5'>
				<li className='page-item'>
					<a href='#catalogoRef' className='nav-link'>
						<button
							className='page-link botonPaginado'
							href='#'
							aria-label='Previous'
							onClick={() => paginado(paginaActual === 1 ? paginaActual : paginaActual - 1)}
						>
							<span className='sr-only'>
								<i className='bx bx-chevron-left'></i>
							</span>
						</button>
					</a>
				</li>
				{mostrarNumerosPagina()}
				<li className='page-item'>
					<a href='#catalogoRef' className='nav-link'>
						<button
							className='page-link botonPaginado'
							aria-label='Next'
							onClick={() =>
								paginado(paginaActual === numerosPaginas.length ? paginaActual : paginaActual + 1)
							}
						>
							<span className='sr-only'>
								<i className='bx bx-chevron-right'></i>
							</span>
						</button>
					</a>
				</li>
			</ul>
		</nav>
	);
}

export default Pagination;
