/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { setUserInfo, login } from '../../features-slice/user/userSlice';
import { isAuthenticated } from '../../utils/auth/auth';
import { useNavigate } from 'react-router-dom';

export const GoogleLoginButton = () => {
	const uri = process.env.REACT_APP_BACK_URL;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	async function handleCallbackResponse(response) {
		try {
			const body = { id_token: response.credential };
			const resp = await axios.post(`${uri}/api/auth/google`, body);
			const token = resp.data.token;
			const usuario = resp.data.usuario;
			const userQuery = await axios.get(`${uri}/api/usuarios/${usuario._id}`);

			localStorage.setItem('Token', token);
			localStorage.setItem('Usuario', usuario._id);
			dispatch(login(usuario._id));
			dispatch(setUserInfo(userQuery.data));
			resp.data.mensajeCambioCategoria &&
				localStorage.setItem('cambioCategoria', resp.data.mensajeCambioCategoria);
			toast.success(`Bienvenido ${usuario.nombre}`, { duration: 3000 });

			if (isAuthenticated()) {
				setTimeout(() => {
					navigate('/');
				}, 2 * 1000);
			}
		} catch (error) {
			toast.error(`${error}`, { duration: 3000 });
		}
	}

	useEffect(() => {
		try {
			google.accounts.id.initialize({
				client_id: '168448428977-3nim45lfmqj8krdvfkpqeacgjdmh3oh0.apps.googleusercontent.com',
				callback: handleCallbackResponse,
			});
			google.accounts.id.renderButton(document.getElementById('signInDiv'), {
				theme: 'outline',
				size: 'large',

				logo_alignment: 'left',
				//width: '15vw',
			});
		} catch (error) {
			console.log('espera un momento');
		}
	}, []);

	return (
		<div>
			<div id='signInDiv'></div>
		</div>
	);
};

export default GoogleLoginButton;
