import React from 'react';
import {Box} from "@mui/material"
import { Table, Form, Button } from 'react-bootstrap';
import { currencyDisplay } from '../../../utils/cart/cart';
import bolsa from "../../../assets/images/CORAZON-VERDE.png"
// import "./StockTable.Module.css"
import { useMediaQuery } from 'react-responsive';
import * as XLSX from "xlsx";


const ServiciosTable = ({ products, onProductClick }) => {
	const [selected, setSelected] = React.useState(null);
	const [showTestProducts, setShowTestProducts] = React.useState(false);
	const isMobile = useMediaQuery({ maxWidth: 700 });

	const handleProductClick = (user) => {
		setSelected(user);
		onProductClick(user);
	};

	const getColor = (number) => {
		
		if(number <= 0){
			return "#9c000095"
		}else if ( number === 1){
			return "#a19f04c6"
		}else return "#05c205bb"
	}
// 	const exportToExcel = () => {
//     // Crear una nueva estructura de datos para exportar
//     const dataToExport =filteredProducts.flatMap(product => {
//         if (product.disponibles > 0) {
//             return [{
//                 "Nombre": product.nombre,
//                 "Marca": product.marca,
//                 "Depósito": "GENERAL",
//                 "Cantidad": product.disponibles,
//                 "Tester": product.tester ? "SI" : "NO"
//             }];
//         } else {
//             const variantesData = product.variantes.map((variante, index) => ({
//                 "Nombre": index === 0 ? product.nombre : "", // Solo agregar el nombre en la primera fila del grupo
//                 "Marca": index === 0 ? product.marca : "", // Solo agregar la marca en la primera fila del grupo
//                 "Depósito": variante.color,
//                 "Cantidad": variante.disponibles,
//                 "Tester": product.tester ? "SI" : "NO"
//             }));
//             return variantesData;
//         }
//     });

//     // Crear el libro de trabajo y la hoja de cálculo
//     const ws = XLSX.utils.json_to_sheet(dataToExport);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "Productos");

//     // Descargar el archivo Excel
//     XLSX.writeFile(wb, "productos.xlsx");
// };


const procesarCadena = (cadena) => {
	if (cadena && cadena[0].match(/\d/)) {
		return cadena.slice(1);
	} else {
		return cadena;
	}
};



	const filteredProducts = showTestProducts ? products.filter(product => product.tester) : products;

	return (
		<div className="stock-table-container">
			{/* <div style={{paddingBottom:"15px",display:"flex", flexDirection:isMobile?"column":"row", backgroundColor:"white",gap:"50px", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
				<span style={{fontFamily:"Montserrat"}}>Total de productos: <strong>{products && products?.length}</strong></span>
				<Form.Check
									type="checkbox"
									label="Productos con tester"
									checked={showTestProducts}
									onChange={(e) => setShowTestProducts(e.target.checked)}
									style={{  fontFamily:"Montserrat" }}
							/>
					
			</div> */}
		<Table responsive="sm" striped bordered hover className='stock-table'>
			<thead>
				<tr>
					<th >Imagen</th>
					<th>Nombre</th>
					<th>Marca</th>
					<th>Precio Lista</th>
					<th>Precio Promo</th>
					<th>Stock</th>
					{/* <th>Tester</th> */}
					<th>Categoria</th>
				</tr>
			</thead>
			<tbody className="tablaConsumoInterno" >
				{filteredProducts?.map((product) => (
					<tr key={product._id} onClick={() => handleProductClick(product)}>
					<td className={selected?._id === product._id ? 'selected' : ''}>
        <img
          src={product?.img || bolsa}
          alt={product?.nombre || "Product Image"}
          style={{ maxWidth: "50px", maxHeight: "50px" }}
        />
      </td>
						<td className={selected?._id === product._id ? 'selected' : ''}>{procesarCadena(product?.nombre)}</td>
						<td className={selected?._id === product._id ? 'selected' : ''}>{product?.marca}</td>
						<td className={selected?._id === product._id ? 'selected' : ''}>{currencyDisplay(product?.precioTarjeta)}</td>
						<td className={selected?._id === product._id ? 'selected' : ''}>{currencyDisplay(product?.precioEfectivo)}</td>
					
						<td style={{paddingLeft:0, paddingRight:0, backgroundColor: product?.disponibles>0 && getColor(product.disponibles),color:"white"}}>
							{product?.disponibles > 0 ? (
								<span style={{fontWeight:600, color:"white"}}>{product.disponibles}</span>
							) : (					
							product?.variantes?.length > 0 && (
								<ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
									{product?.variantes?.map((variante, index) => (
										<li
											key={index}
											style={{
												backgroundColor: getColor(variante.disponibles),
												color: 'white',
												width: '100%', // Ocupa todo el ancho del contenedor
												boxSizing: 'border-box', // Incluye padding y border en el ancho
												marginBottom: '5px', // Puedes ajustar el margen inferior según tus necesidades
											}}
										>
											<span style={{fontWeight:600, color: 'white' }}>{variante?.color}</span> -{' '}
											<span style={{fontWeight:600,  color: 'white' }}>{variante?.disponibles}</span>
										</li>
									))}
								</ul>
							)
							)}
						</td>
						{/* <td className={selected?._id === product._id ? 'selected' : ''}>
							{product?.tester ? "SI" : "NO"}
						</td> */}
						<td className={selected?._id === product._id ? 'selected' : ''}>
							{product?.categoria?.nombre}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
		</div>
	);
};

export default ServiciosTable;