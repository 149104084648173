import React from 'react';
import { useDispatch } from 'react-redux';
import { eliminarDelCarrito, cambiarCantidad as cm } from '../../features-slice/cart/carritoSlice';
import product1 from '../../assets/PRODUCTO_SIN_FONDO.png';
import './ItemCart2.Module.css';
import { currencyDisplay } from '../../utils/cart/cart';
import { useMediaQuery } from 'react-responsive';
function ItemCart2({ producto, index }) {
	const dispatch = useDispatch();
	const [cantidad, setCantidad] = React.useState(producto.cantidad || 1);
	const dispatchCambiarCantidad = () => dispatch(cm({ index, cantidad }));

	const existingVariante = producto.variantes?.find(
		(variante) => variante._id === producto.varianteId
	);

	const disponibles = existingVariante ? existingVariante.disponibles : producto.disponibles;
	const isMobileScreen = useMediaQuery({ maxWidth: 768 });
	React.useEffect(() => {
		dispatchCambiarCantidad();
	}, [cantidad]);

	function aumentarCantidad() {
		if (cantidad + 1 <= disponibles) return setCantidad(cantidad + 1);
	}
	function disminuirCantidad() {
		if (cantidad - 1 !== 0) return setCantidad(cantidad - 1);
	}
	function cambiarCantidad(e) {
		const nuevaCantidad = Number(e.target.value);
		if (nuevaCantidad === 0 || nuevaCantidad < 0) return setCantidad(1);
		if (nuevaCantidad > disponibles) return setCantidad(disponibles || 1);
		setCantidad(nuevaCantidad);
	}
	const procesarCadena = (cadena) => {
		if (cadena && cadena[0].match(/\d/)) {
			return cadena.slice(1);
		} else {
			return cadena;
		}
	};
		
	const variantColor = existingVariante ? existingVariante.color.toUpperCase() : '';

	return (
		<div className='containerItemCart p-2'>
			<img
				className='imgCartItem'
				src={producto.img ? producto.img : product1}
				alt={producto.nombre}
			/>
			<div className='containerDatosCart'>
				<div className='tituloYPrecio'>
					<h3  style={{fontFamily:"Montserrat",fontSize:isMobileScreen?"10px":"12px"}}>
						{procesarCadena(producto.nombre)} {variantColor}
					</h3>
					{producto.precioOferta > 0 ? (
							<div style={{display:"flex", flexDirection:"column",width:"100px"}}>
							<small style={{display:"flex", flexDirection:"column",fontSize:"12px"}} className='text-muted '>Precio Lista <s>{currencyDisplay(producto.precioTarjeta)}</s></small>
							<small className='text-muted oferta' style={{display:"flex", flexDirection:"column",fontSize:"12px"}}>Beauti ganga <strong>{currencyDisplay(producto.precioOferta)}</strong></small>
							</div>
						// <small style={{display:"flex", flexDirection:"column"}} className='text-muted oferta'>
						// 	Beauti ganga
						// 	<s>{currencyDisplay(producto.precioEfectivo)}</s>{' '}
						// 	{currencyDisplay(producto.precioOferta)}
						// </small>
					) : (
						<div style={{display:"flex", flexDirection:"column",width:"100px"}}>
						<small style={{display:"flex", flexDirection:"column",fontSize:"12px"}} className='text-muted '>Precio Lista <strong>{currencyDisplay(producto.precioTarjeta)}</strong></small>
						<small style={{display:"flex", flexDirection:"column",fontSize:"12px"}}className='text-muted '>Precio Promo <strong>{currencyDisplay(producto.precioEfectivo)}</strong></small>
						</div>
						
					)}
				</div>
				{producto.cantidad && (
					<div className='contenedorBotones'>
						<button
						style={{height:isMobileScreen && "20px", width:isMobileScreen&&"20px"}}
							className='btn btn-sm btn-outline-secondary menos'
							onClick={() => disminuirCantidad()}
						>
							-
						</button>
						<input
							className='imputCantidad'
							type='number'
							name=''
							value={cantidad}
							id=''
							max={disponibles || 100}
							min={1}
							onChange={(e) => cambiarCantidad(e)}
						/>
						<button
						style={{height:isMobileScreen && "20px", width:isMobileScreen&&"20px"}}
							className='btn btn-sm btn-outline-secondary mas'
							onClick={() => aumentarCantidad()}
						>
							+
						</button>
						<abbr title='Eliminar del carrito'>
							<button
								className='btn btn-sm  carrito'
								onClick={() => dispatch(eliminarDelCarrito(index))}
							>
								<i className='bx bx-trash'></i>
							</button>
						</abbr>
					</div>
				)}
				{producto.cantidad > 1 ? (
					<>
						<small style={{fontSize:"14px"}}className='text-muted subtotal '>
							{`sub Precio Lista ${
									currencyDisplay(producto.precioTarjeta * cantidad)
							}`}
						</small>
						<small style={{fontSize:"14px"}} className='text-muted subtotal '>
							{`sub Precio Promo ${
								producto.precioOferta
									? currencyDisplay(producto.precioOferta * cantidad)
									: currencyDisplay(producto.precioEfectivo * cantidad)
							}`}
						</small>
						
					</>
				) : (
					''
				)}
				{/* <small>Disponibles {disponibles - cantidad}</small> */}
			</div>
		</div>
	);
}

export default ItemCart2;
