import React from 'react';
import { Table } from 'react-bootstrap';

const UserCategoriesTable = ({ userCategories, onUserCategoryClick }) => {
	const [selected, setSelected] = React.useState(null);

	const handleCategoryClick = (userCategory) => {
		setSelected(userCategory);
		onUserCategoryClick(userCategory);
	};

	return (
		<Table striped bordered hover>
			<thead>
				<tr>
					<th>Nombre</th>
					<th>Puntos necesarios</th>
					<th>Beneficios</th>
				</tr>
			</thead>
			<tbody>
				{userCategories?.map((category) => (
					<tr key={category._id} onClick={() => handleCategoryClick(category)}>
						<td className={selected?._id === category._id ? 'selected' : ''}>{category.nombre}</td>
						<td className={selected?._id === category._id ? 'selected' : ''}>
							{category.puntosNecesarios}
						</td>
						<td className={selected?._id === category._id ? 'selected' : ''}>
							<ul>
								{category.beneficios.map((beneficio) => (
									<li key={beneficio._id}>
										<span>{beneficio.nombre}</span> - <span>{beneficio.tipo}</span>
									</li>
								))}
							</ul>
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default UserCategoriesTable;
