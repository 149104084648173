import React from 'react';
import Stamp from './Stamp';
import beautiComunidad from '../../assets/Sellos/BEAUTY_COMUNIDAD.png';
import beautiBox from '../../assets/Sellos/BEAUTY_BOX.png';
import giftCards from '../../assets/Sellos/GIFTCARDS.png';
import alianzas from '../../assets/Sellos/ALIANZAS.png';
import petFriendly from '../../assets/Sellos/PET_FRIENDLY.png';
import beautimix from "../../assets/Sellos/BEAUTIMIX.png"
import './Stamp.Module.css';

const imagenesBottom = [
	{ image: petFriendly, name: 'Pet Friendly', link: '' },
	{ image: giftCards, name: 'Giftcards', link: '' },
	{ image: beautiBox, name: 'Beautiboxes', link: '' },
	{ image: beautimix, name: 'Beautimix', link: '' },
];

function StampLanding() {
	return (
		<div className='container'>
			<div className='row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 py-5 px-5'>
				{imagenesBottom?.map((imgStamp, index) => {
					return (
						<div key={index} className=' py-2'>
							<Stamp imgStamp={imgStamp} />
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default StampLanding;
