import { createBrowserRouter } from 'react-router-dom';
import Home from './routes/home/Home';
import Login from './routes/login/Login';
import CartDetail from './routes/cart/CartDetail';
import Catalogue from './routes/catalogue/Catalogue';
import Contact from './routes/contact/Contact';
import CreateAccount from './routes/login/CreateAccount';
import Detail from './routes/detail/Detail';
import PrevCatalogue from './routes/prevCatalogue/PrevCatalogue';
import Profile from './routes/profile/Profile';
import Sale from './routes/sale/Sale';
import Loader from './components/loader/Loader';
import Alianzas from './routes/alianzas/Alianzas';
import RecuperoContraseña from './routes/recuperoContraseña/RecuperoContraseña';
import Dashboard from './routes/dashboard/Dashboard';
import ModalEditPurchase from './components/modals/modalEditPurchase/ModalEditPurchase';
import Estadisticas from './routes/estadisticas/Estadisticas';
import CajaDiaria from './routes/cajaDiaria/CajaDiaria';
import Error from './routes/error/Error';
import {LandingInfo} from './routes/landingInfo/LandingInfo';
import Mantenimiento from './routes/mantenimiento/Mantenimiento';
import Cartilla from './routes/cartilla/Cartilla';

const router = createBrowserRouter([
	{
		path: '/',
		element: <LandingInfo />,
		errorElement: <Error />,
	},
	{
		path: '/markethome',
		element: <Home />,
		errorElement: <Error />,
	},
	{
		path: '/landing',
		element: <LandingInfo />,
		errorElement: <Error />,
	},
	{
		path: '/cartilla/:categoriainfo',
		element: <Cartilla />,
		errorElement: <Error />,
	},
	{
		path: '/iniciarSesion',
		element: <Login />,
		errorElement: <Error />,
	},
	{
		path: '/crearCuenta',
		element: <CreateAccount />,
		errorElement: <Error />,
	},
	{
		path: '/market',
		element: <PrevCatalogue />,
		errorElement: <Error />,
	},
	{
		path: '/market/categoria/:id',
		element: <Catalogue />,
		errorElement: <Error />,
	},
	{
		path: '/producto/:id',
		element: <Detail />,
		errorElement: <Error />,
	},
	{
		path: '/contacto',
		element: <Contact />,
		errorElement: <Error />,
	},
	{
		path: '/ofertas',
		element: <Sale />,
		errorElement: <Error />,
	},
	{
		path: '/loader',
		element: <Loader />,
		errorElement: <Error />,
	},
	{
		path: '/perfil',
		element: <Profile />,
		errorElement: <Error />,
	},
	{
		path: '/carrito',
		element: <CartDetail />,
		errorElement: <Error />,
	},
	{
		path: '/alianzas',
		element: <Alianzas />,
		errorElement: <Error />,
	},
	{
		path: '/dashboard',
		element: <Dashboard />,
		errorElement: <Error />,
	},
	{
		path: '/recupero-password/:token',
		element: <RecuperoContraseña />,
		errorElement: <Error />,
	},
	{
		path: '/editarcompra',
		element: <ModalEditPurchase />,
		errorElement: <Error />,
	},
	{
		path: '/stats',
		element: <Estadisticas />,
		errorElement: <Error />,
	},
	{
		path: '/cajadiaria',
		element: <CajaDiaria />,
		errorElement: <Error />,
	},
	{
		path: '/sitioenmantenimiento',
		element: <Mantenimiento />,
		errorElement: <Error />,
	},
]);

export default router;
