import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { isAuthenticated } from '../../utils/usuario/usuario';
import { isAuthenticated } from '../../utils/auth/auth';
import './ItemLandingInfo2.Module.css';
// import imagen from "../../assets/imgCarousel/img1.jpg"
import axios from 'axios';
import { Button, Typography, Stack, Box, Backdrop, CircularProgress, BackdropRoot, Paper, Grid } from "@mui/material";
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

export default function ItemLandingInfo2({imagen, reloadData, setReloadData}) {
  // console.log("imagen en item", imagen)

	const isSmallScreen = useMediaQuery({ maxWidth: 500 }); // Cambio de imagen en pantallas pequeñas
	const [isInView2, setIsInView2] = React.useState(false);
  const navigate = useNavigate()
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [loader, setLoader] = React.useState(true);
	 const [image, setImage] = React.useState(imagen);
	 const [backgroundImage, setBackgroundImage] = React.useState(imagen);
  const user = useSelector((state) => state.user.userInfo);
  const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);
	const [isEditing, setIsEditing] = React.useState(false);
	const [editedDescription, setEditedDescription] = React.useState('');

// console.log("este es el estado",image)
	function handleOpenWidget() {
		const widgetCloudinary = window.cloudinary.createUploadWidget(
			{
        cloudName: 'dlblszxyo',
        uploadPreset: 'laBeautiqueria',
			},
			async (err, result) => {
				if (!err && result && result.event === 'success') {
					const url = result.info.secure_url;

					await axios.put(
						`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
						'',
						config
					);

					setImage({ ...image, url });
					setRenderKey((prevKey) => prevKey + 1);
				}
			}
		);

		widgetCloudinary.open();
	}

	function handleDescriptionEdit() {
		setIsEditing(true);
		setEditedDescription(image?.descripcion);
	}

	async function handleSaveDescription() {
		const data = {
			descripcion: editedDescription,
		};
		// Puedes guardar la descripción en el estado local y/o enviarla al backend si lo deseas
		await axios.put(`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}`, data, config);
		setIsEditing(false);
		setImage([{ ...image, descripcion: editedDescription }]);
		setRenderKey((prevKey) => prevKey + 1);
		const event = new Event('actualizar-producto');
		window.dispatchEvent(event);
		setReloadData(!reloadData)
		// Tu código para guardar la descripción en el backend si es necesario
	}

	function handleCancelEdit() {
		setIsEditing(false);
	}

	React.useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY || document.documentElement.scrollTop;
			const windowHeight = window.innerHeight;
			const component = document.querySelector('.containAbout2'); // Ajusta el selector según tu estructura

			if (component) {
				const componentRect = component.getBoundingClientRect();
				const componentTop = componentRect.top + scrollY;
				const componentBottom = componentRect.bottom + scrollY;
				const centerY = scrollY + windowHeight / 2;

				// Verifica si el centro vertical del componente está en la vista
				if (centerY >= componentTop && centerY <= componentBottom) {
					setIsInView2(true);
				} else {
					setIsInView2(false);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		// Limpia el oyente del evento al desmontar el componente
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [renderKey]);
	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_LANDING`
			);
			setBackgroundImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagenes();
		window.addEventListener('actualizar-producto', cargarImagenes);
	}, [reloadData]);


	return (
		<div style={{backgroundColor:"white"}}className={`containAbout2 ${isInView2 ? 'in-view2' : ''}`}>
			{image && (
				<div className={`img2 heart-sape ${isInView2 ? 'animate-image2' : ''}`}>
					<img className='imgAbout' src={image?.url} alt='imgJH' />
					{isSuperAdmin && (
						<>
							<div className='edit-icon-about' onClick={() => handleOpenWidget()}>
								<i className='bi bi-pencil'></i>
							</div>
						</>
					)}
				</div>
			)}
			<div className={`text2 ${isInView2 ? 'animate-text2' : ''}`}>
				{isEditing ? (
					<>
						<textarea
							value={editedDescription}
							onChange={(e) => setEditedDescription(e.target.value)}
							rows='4'
							cols='50'
						/>
						<button className='btn btn-sm guardarDesc' onClick={handleSaveDescription}>
							Guardar descripción
						</button>
						{isEditing && (
							<div className='cancel-icon-about edit-icon-aboutText' onClick={handleCancelEdit}>
								<i className='bi bi-x'></i>
							</div>
						)}
					</>
				) : (
					<Box>
						<h5 style={{fontFamily:"Montserrat", fontSize:isSmallScreen?"18px":"24px"}}>{image?.nombre}</h5>
            {/* <h5 style={{color:"#5c5c5c"}}>Nails</h5> */}
						{/* <p style={{fontFamily:"Montserrat",fontSize:isSmallScreen?"14px":"18px",lineHeight: "1.8"}}>{image?.descripcion}</p> */}
						<Typography variant="body2">
        {image?.descripcion?.split('\\n').map((paragraph, index) => (
          <React.Fragment key={`paragraph-${index}`}>
            <Typography 
              style={{ fontFamily: "Montserrat", textAlign: "center", color: "#5c5c5c" }} 
              gutterBottom
            >
              {paragraph.split(/(\*.*?:)/).map((text, subIndex) => {
                if (text.startsWith('*')) {
                  return <strong key={`bold-${index}-${subIndex}`}>{text}</strong>;
                } else {
                  return <React.Fragment key={`text-${index}-${subIndex}`}>{text}</React.Fragment>;
                }
              })}
            </Typography>
          </React.Fragment>
        ))}
      </Typography>
						{isSuperAdmin && (
							<div className='edit-icon-aboutText' onClick={handleDescriptionEdit}>
								<i className='bi bi-pencil'></i>
							</div>
						)}
					</Box>
				)}
			
					<button onClick={()=>navigate("/cartilla/beautibox")} className='btn btn-sm btn-outline-secondary conoceMas'>CONOCÉ MÁS</button>
			
			</div>
		</div>
	);
}

